import { http } from 'services';
import { getHeader } from 'lib/helpers';
import { todoUrl, userSearchUrl } from './endpoints';

export const Todo = {
  getTodoList: (
    assigned_person,
    project_id,
    offset,
    limit,
    status,
    shortings,
  ) =>
    http.get(
      `${todoUrl}?${
        assigned_person ? `assigned_person=${assigned_person}&` : ''
      }${
        project_id ? `project=${Number(project_id)}&` : ''
      }limit=${limit}&offset=${offset}${status ? `&status=${status}` : ''}${
        shortings ? `&ordering=${shortings}` : ''
      }
      `,
      {
        headers: getHeader(),
      },
    ),
  createTodoList: data =>
    http.post(todoUrl, data, {
      headers: getHeader(),
    }),
  userSearch: (text, project_id) =>
    http.get(
      `${userSearchUrl}?search_text=${text}${
        project_id ? `&project=${project_id}` : ``
      }`,
      {
        headers: getHeader(),
      },
    ),
  updateTodoList: (data, id) =>
    http.put(`${todoUrl}${id}/`, data, {
      headers: getHeader(),
    }),
  statusUpdateTodoList: (data, id) =>
    http.patch(`${todoUrl}${id}/update-status/`, data, {
      headers: getHeader(),
    }),
  deleteTodoList: id =>
    http.delete(`${todoUrl}${id}/`, {
      headers: getHeader(),
    }),
  getTodo: id =>
    http.get(`${todoUrl}${id}/`, {
      headers: getHeader(),
    }),
};
