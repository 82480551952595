import { createSlice, createAction } from '@reduxjs/toolkit';

const initState = {
  todoList: [],
  users: [],
  todo: {},
};

const TodoSLice = createSlice({
  name: 'todo',
  initialState: initState,
  reducers: {
    getTodoList(state, { payload }) {
      state.todoList = payload;
    },
    getUsers(state, { payload }) {
      state.users = payload;
    },
    getTodo(state, { payload }) {
      state.todo = payload;
    },
  },
});

export const getTodoListReq = createAction('todo/get_todo_list_req');
export const createTodoListReq = createAction('todo/create_todo_req');
export const userSearchReq = createAction('todo/user_search_req');
export const updateTodoListReq = createAction('todo/todo_update_req');
export const deleteTodoListReq = createAction('todo/todo_delete_req');
export const getSingleTodoReq = createAction('todo/get_single_todo_req');

export const { getTodoList, getUsers, getTodo } = TodoSLice.actions;

export default TodoSLice;
