import { http } from 'services';
import { getHeader } from 'lib/helpers';
import { fieldUrl } from './endpoints';

export const Reports = {
  getObjectTracking: (locationId, from_date, to_date) =>
    http.get(
      `${fieldUrl}${locationId}/object-tracking-summary/?${
        from_date ? `from_date=${from_date}&` : ''
      }${to_date ? `to_date=${to_date}` : ''}
    `,
      {
        headers: getHeader(),
      },
    ),
  getFieldList: pagination_off =>
    http.get(`${fieldUrl}?pagination_off=${pagination_off}`, {
      headers: getHeader(),
    }),
};
