import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { UserListWrapper } from '../Users/Users.styles';
import { getAllFieldsRequest } from './reducer';
import FieldTable from './FieldTable';

function FieldList({ offset, setOffset }) {
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const { results, count } = useSelector(state => state.Fields.list);

  const changePagination = (current, pageSize) => {
    setCurrentPage(current);
    current = pageSize * (current - 1);
    setOffset(current);
    dispatch(
      getAllFieldsRequest({
        limit: pageSize,
        offset: current,
        name: '',
      }),
    );
  };
  useEffect(() => {
    dispatch(getAllFieldsRequest({ limit: 10, offset, name: '' }));
  }, [dispatch]);

  return (
    <UserListWrapper>
      <FieldTable
        data={results}
        count={count}
        changePagination={changePagination}
        offset={offset}
        currentPage={currentPage}
      />
    </UserListWrapper>
  );
}

FieldList.propTypes = {
  offset: PropTypes.number,
  setOffset: PropTypes.func,
};

export default FieldList;
