import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { Link as GaLink } from 'react-router-dom';
import styled from 'styled-components';

const Link = styled(GaLink)`
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  min-height: 144px;
  height: 100%;
  width: 100%;
  background-color: #fff;
  border-radius: 10px;
  font-weight: Bold;
  font-size: 18px;
  line-height: 28px;
  span {
    display: block;
    width: 100%;
    font-size: 30px;
    margin-bottom: 12px;
  }
`;

function AddOrgCard({ title = 'Add new', url = '#', icon = '' }) {
  return (
    <Link to={url}>
      {icon}
      <FormattedMessage id={title} />
    </Link>
  );
}

AddOrgCard.propTypes = {
  title: PropTypes.string,
  url: PropTypes.string,
  icon: PropTypes.node,
};

export default AddOrgCard;
