import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Form, Input, Checkbox, Button, Row, Col, Upload } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { FormattedMessage, useIntl } from 'react-intl';
import gmGroupIcon from 'assets/icons/gm.svg';
import customerGroupIcon from 'assets/icons/customer.svg';
import supplierGroupIcon from 'assets/icons/supplier.svg';
import InfillMeasureFields from 'features/Esign/InfillMeasure/InfillMeasureFields';
import { getEsignViewRequest } from '../reducer';
import EsignViewFormWrapper from './EsignView.styles';

const { Dragger } = Upload;
const { TextArea } = Input;

function EsignView() {
  const { id } = useParams();
  const intl = useIntl();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const history = useHistory();
  const [formInfo, setFormInfo] = useState(null);
  const [currentRole, setCurrentRole] = useState('');
  const [userGroupIcon, setUserGroupIcon] = useState(gmGroupIcon);

  const cancelTask = () => {
    history.push('/dashboard/e-sign-form');
  };

  useEffect(() => {
    dispatch(getEsignViewRequest({ id, setFormInfo, setCurrentRole }));
  }, [dispatch]);

  useEffect(() => {
    if (currentRole === 'customer-admin') {
      setUserGroupIcon(customerGroupIcon);
    } else if (currentRole === 'supplier-admin') {
      setUserGroupIcon(supplierGroupIcon);
    }
  }, [currentRole, userGroupIcon]);

  return (
    <EsignViewFormWrapper>
      <div className="form-header">
        <h2>
          <div className="title_text">
            {formInfo && formInfo.name}
            {formInfo && (
              <span>
                {`(${intl.messages['Added by']} ${formInfo?.created_by?.first_name} ${formInfo?.created_by?.last_name} )`}
              </span>
            )}
          </div>
          <img src={userGroupIcon} alt="user role" />
        </h2>
      </div>
      <div className="esign_form_wrapper">
        <Form
          name="preview_e-sign_tepmplate"
          labelCol={{ span: 24 }}
          form={form}
        >
          <div className="esign_form_inner">
            <h3>
              <FormattedMessage id="Form Elements" />
            </h3>

            <div className="esign_form_inner_fields">
              {formInfo?.configuration?.map((field, i) => {
                if (field.type === 'text') {
                  return (
                    <Row
                      // eslint-disable-next-line
                      key={i} 
                      type="flex"
                      align="center"
                      gutter={[16]}
                    >
                      <Col span={24}>
                        <Form.Item
                          label={field.label}
                          name={`field_${i}`}
                          hasFeedback
                        >
                          <TextArea
                            placeholder={field.placeholder}
                            size="large"
                            rows={3}
                            disabled
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  );
                }
                if (field.type === 'checkbox') {
                  return (
                    <Row
                      // eslint-disable-next-line
                      key={i} 
                      type="flex"
                      align="center"
                      gutter={[16]}
                    >
                      <Col span={24}>
                        <Form.Item name={`field_${i}`} valuePropName="checked">
                          <Checkbox disabled>{field.label}</Checkbox>
                        </Form.Item>
                      </Col>
                    </Row>
                  );
                }
                if (field.type === 'file') {
                  return (
                    <Row
                      // eslint-disable-next-line
                      key={i} 
                      type="flex"
                      align="center"
                      gutter={[16]}
                    >
                      <Col span={24}>
                        <Form.Item label={field.label} name={`field_${i}`}>
                          <Dragger disabled>
                            <p className="ant-upload-drag-icon">
                              <UploadOutlined />
                            </p>
                            <p className="ant-upload-text">
                              <FormattedMessage id="Drag files here to upload" />
                            </p>
                          </Dragger>
                        </Form.Item>
                      </Col>
                    </Row>
                  );
                }
                if (field?.measure) {
                  return <InfillMeasureFields fields={field} key={i} />;
                }
                return null;
              })}
            </div>
          </div>

          <Row className="mt-2" type="flex" justify="center" gutter="70">
            <Col>
              <Form.Item>
                <Button onClick={cancelTask} size="large" type="primary">
                  <FormattedMessage id="Cancel" />
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
    </EsignViewFormWrapper>
  );
}

export default EsignView;
