import { Pagination as AntPagination } from 'antd';
import styled from 'styled-components';

const Pagination = styled(AntPagination)`
  margin-top: 0.5rem;
  .ant-pagination {
    .ant-pagination-item {
      padding: 4px;
    }
  }
  .ant-pagination-item a {
    padding: 4px 6px;
  }
  .ant-pagination-prev a {
    padding: 6px 6px;
  }
  .ant-pagination-next a {
    padding: 6px 6px;
  }
  .ant-pagination-options {
    display: none;
  }
`;

export default Pagination;
