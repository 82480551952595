/* eslint-disable react/prop-types */
import React from 'react';
import {
  GoogleMap,
  withScriptjs,
  withGoogleMap,
  Marker,
} from 'react-google-maps';

const options = {
  disableDefaultUI: true,
};
function Map({ point }) {
  // console.log(point);
  return (
    <GoogleMap
      defaultZoom={16}
      defaultCenter={{ lat: point.latitude, lng: point.longitude }}
      options={options}
    >
      <Marker position={{ lat: point.latitude, lng: point.longitude }} />
    </GoogleMap>
  );
}
const GoogleMapComponent = withScriptjs(withGoogleMap(Map));
export default GoogleMapComponent;
