import React from 'react';
import { FormattedMessage } from 'react-intl';
import { UserModal } from 'features/Users';
import PropTypes from 'prop-types';
import AddUserForm from './AddUserForm';

function AddUser({ isModalVisible, extraFormData, setIsModalVisible, query }) {
  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      <UserModal
        centered
        title={<FormattedMessage id="Add New User" />}
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={null}
        width="91rem"
      >
        <AddUserForm
          handleModalClose={handleCancel}
          extraFormData={extraFormData}
          query={query}
        />
      </UserModal>
    </>
  );
}
AddUser.propTypes = {
  isModalVisible: PropTypes.bool,
  extraFormData: PropTypes.shape({
    id: PropTypes.number,
  }),
  setIsModalVisible: PropTypes.func,
  query: PropTypes.string,
};

export default AddUser;
