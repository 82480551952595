import { createGlobalStyle } from 'styled-components';
import { tint } from 'polished';

const appStyles = createGlobalStyle`

  /* Resets  */
  *,
  *::after,
  *::before {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    text-rendering: optimizeLegibility;
  }

  /* Typgraphy */

  html {
    font-size: 62.5%;

    body {
      font-family: 'Roboto', sans-serif;
      line-height: 155%;

      input, button, select, optgroup, textarea, span, li, a, button {
        font-family: 'Roboto', sans-serif;
        line-height: 155%;

      }

      h1, h2, h3, h4, h5, h6 {
        font-family: 'Roboto', sans-serif;
        font-weight: normal;
      }


      h1 {
        font-size: 3.2rem;
        
      }

      h2 {
        font-size: 2.4rem;
      }

      h3 {
        font-size: 1.8rem;
      }

      h4 {
        font-size: 1.6rem;
      }

      h5 {
        font-size: 1.4rem;
      }

      h6 {
        font-size: 1.2rem;
      }

      /* Components */

      /* PopOver*/
     

      /* Buttons  */
      .ant-btn:not(.ant-btn-dashed) {
        font-weight: normal;
        border-radius: 5px;
        box-shadow: ${props => props.theme.shadow};
        font-size: 1.6rem;
        height: 4rem;

        &:not(.ant-btn-primary):not(.ant-btn-link):not(.ant-btn-ghost):not(.ant-btn-text) {
        background-color: ${props => tint(0.8, props.theme.secondary)};
        border: none;
        color: ${props => tint(0.2, props.theme.secondary)};

        }


        &.ant-btn-lg {
          padding-left: 7rem;
          padding-right: 7rem;
          height: 4.8rem;
        }

        &.ant-btn-link  {
          box-shadow: none;
        }

      }


      /* Modal */

      .ant-modal-content {
        border-radius: 1rem;
      }

      /*Inputs */

      .ant-input,
      .ant-select,
      .ant-select-selector,
      .ant-input-affix-wrapper {
        border-radius: .4rem !important;
      }

      .ant-btn-background-ghost {
        box-shadow: none;
      }

      .ant-form-item-label > label {
        font-weight: 500;
        font-size: 1.6rem;
        line-height: 155%;
      }

      .ant-form-item-label > label.ant-form-item-required::before {
        position: absolute;
        right: -1.5rem;
        color: ${props => props.theme.primary};
        font-size: 1.6rem;
      }

      .ant-picker-time-panel .ant-picker-header-view:after {
        content: 'Time';
      }
    }

  }


  /* NProgerss */

  #nprogress .bar {
      background: ${props => props.theme.primary};
    }

    #nprogress .peg {
        box-shadow: 0 0 10px ${props => props.theme.primary}, 0 0 5px ${props =>
  props.theme.primary};
    }

    #nprogress .spinner-icon {
      display: none;
    }

  /* Utilities */


    /* Shadow */

    .shadow {
      box-shadow: ${props => props.theme.shadow};

      &-light {
        box-shadow: ${props => props.theme.shadowLight};
      }

      &-long {
        box-shadow: ${props => props.theme.shadowLong};
      }

      &-right {
        box-shadow: ${props => props.theme.shadowRight};
      }

    }

    /* Float */

  .float {
    &-right {
      float: right;
    }

    &-left {
      float: left;
    }
  }


   /* Typography Utls */

   .text {
    &-center {
      text-align: center;
    }

    &-left {
      text-align: left;
    }

    &-right {
      text-align: right;
    }
  }

  .font {

    &-light {
      font-weight: 300;
    }
    
    &-normal {
      font-weight: 400;
    }

    &-bold {
      font-weight: 700;
    }

    &-medium {
      font-weight: 500;
    }

  }


  /* Margin Padding */

  .m {
    &-1{
      margin: 2rem;
    }

    &-2{
      margin: 4rem;
    }

    &-3{
      margin: 6rem;
    }

    &-4{
      margin: 8rem;
    }

  &t {
    &-1{
      margin-top: 2rem;
    }
    &-2{
      margin-top: 4rem;
    }
    &-3{
      margin-top: 6rem;
    }
    &-4{
      margin-top: 8rem;
    }
  }

  &b {
    
    &-1 {
      margin-bottom: 2rem;
    }

    &-2 {
      margin-bottom: 4rem;
    }
    &-3 {
      margin-bottom: 6rem;
    }
    &-4 {
      margin-bottom: 8rem;
    }
  }

  &l {
    &-1 {
      margin-left: 2rem;
    }
    &-2 {
      margin-left: 4rem;
    }
    &-3 {
      margin-left: 6rem;
    }
    &-4 {
      margin-left: 8rem;
    }
  }
  &r {
    &-1 {
      margin-right: 2rem;
    }
    &-2 {
      margin-right: 4rem;
    }
    &-3 {
      margin-right: 6rem;
    }
    &-4 {
      margin-right: 8rem;
    }
  }
  &x {
    &-1 {
      margin-left: 2rem;
      margin-right: 2rem;
    }
    &-2 {
      margin-left: 4rem;
      margin-right: 4rem;
    }
    &-3 {
      margin-left: 6rem;
      margin-right: 6rem;
    }
    &-4 {
      margin-left: 8rem;
      margin-right: 8rem;
    }
  }

  &y {
    &-1 {
      margin-top: 2rem;
      margin-bottom: 2rem;
    }
    &-2 {
      margin-top: 4rem;
      margin-bottom: 4rem;
    }
    &-3 {
      margin-top: 6rem;
      margin-bottom: 6rem;
    }
    &-4 {
      margin-top: 8rem;
      margin-bottom: 8rem;
    }
  }
}

.p {

  &-1 {
    padding: 2rem;
  }
  &-2 {
    padding: 4rem;
  }
  &-3 {
    padding: 6rem;
  }
  &-4 {
    padding: 8rem;
  }

  &t {
    &-1 {
      padding-top: 2rem;
    }
    &-2 {
      padding-top: 4rem;
    }
    &-3 {
      padding-top: 6rem;
    }
    &-4 {
      padding-top: 8rem;
    }
  }

  &b {
    &-1 {
      padding-bottom: 2rem;
    }
    &-2 {
      padding-bottom: 4rem;
    }
    &-3 {
      padding-bottom: 6rem;
    }
    &-4 {
      padding-bottom: 8rem;
    }
  }

  &l {
    &-1 {
      padding-left: 2rem;
    }
    &-2 {
      padding-left: 4rem;
    }
    &-3 {
      padding-left: 6rem;
    }
    &-4 {
      padding-left: 8rem;
    }
  }

  &r {
    &-1 {
      padding-right: 2rem;
    }
    &-2 {
      padding-right: 4rem;
    }
    &-3 {
      padding-right: 6rem;
    }
    &-4 {
      padding-right: 8rem;
    }
  }

  &x{
    &-1 {
      padding-left: 2rem;
      padding-right: 2rem;
    }
    &-2 {
      padding-left: 4rem;
      padding-right: 4rem;
    }
    &-3 {
      padding-left: 6rem;
      padding-right: 6rem;
    }
    &-4 {
      padding-left: 8rem;
      padding-right: 8rem;
    }
  }

  &y{
    &-1 {
      padding-top: 2rem;
      padding-bottom: 2rem;
    }
    &-2 {
      padding-top: 4rem;
      padding-bottom: 4rem;
    }
    &-3 {
      padding-top: 6rem;
      padding-bottom: 6rem;
    }
    &-4 {
      padding-top: 6rem;
      padding-bottom: 6rem;
    }
  }
}

  .dark-theme {
    --color: #43FCD5 !important;
    --color-meta: #f3efef !important;
    --pitch-line-color: #E0FFFF !important;
    --pitch-shade-color: #cccccc !important;
  }
  .ant-table-content{
    overflow: auto;
  }
`;

export default appStyles;
