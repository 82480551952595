import React from 'react';
import { useHistory } from 'react-router';
import PropTypes from 'prop-types';

import { Row, Col } from 'antd';
import { Link } from 'react-router-dom';
import { PRIVATE_ROUTE } from 'router';
import { PlusOutlined } from '@ant-design/icons';
import { ShowForPermission } from 'components/utility';
import { getItemFromUrl, OrgTypes } from 'components/utility/CustomeFunc';
import { useIntl } from 'react-intl';
import { OrderSectionWrapper } from '../OrgDetails.styles';
import orderIcon from '../assets/order-icon.svg';
import maintainIcon from '../assets/maintain-icon.svg';
import ListView from './ListView';

const OrderMaintanenceList = ({
  orderList,
  maintenancelist,
  maintenanceProjectlist,
  orderProjectlist,
  changePaginationOrder,
  changePaginationMaintenance,
}) => {
  const history = useHistory();
  const intl = useIntl();
  const orderPage = PRIVATE_ROUTE.ORDER;
  const maintenancePage = PRIVATE_ROUTE.MAINTENANCE;
  const userType = getItemFromUrl(history, OrgTypes);
  return (
    <OrderSectionWrapper>
      <Row gutter={[24, 24]}>
        <Col span={12}>
          <div className="order_card">
            <div className="order_card_header">
              <div className="order_card_header_left">
                <img src={orderIcon} alt="Order icon" />
                {intl.messages['Order']}
              </div>
              {userType === PRIVATE_ROUTE.CUSTOMERS && (
                <ShowForPermission permission={['can_add_new_order_project']}>
                  <Link
                    to={{
                      pathname: `${PRIVATE_ROUTE.PROJECTS}/${PRIVATE_ROUTE.ORDER}/${PRIVATE_ROUTE.NEW}`,
                    }}
                  >
                    <div className="order_card_header_right">
                      <PlusOutlined />
                      {intl.messages['Add New']}
                    </div>
                  </Link>
                </ShowForPermission>
              )}
            </div>
            <ListView
              list={orderList}
              count={orderProjectlist && orderProjectlist.count}
              changePagination={changePaginationOrder}
              page={orderPage}
            />
          </div>
        </Col>

        <Col span={12}>
          <div className="order_card">
            <div className="order_card_header">
              <div className="order_card_header_left">
                <img src={maintainIcon} alt="Order icon" />

                {intl.messages['Maintenance']}
              </div>
              {userType === 'customers' && (
                <ShowForPermission
                  permission={['can_add_new_maintenance_project']}
                >
                  <Link
                    to={{
                      pathname: `${PRIVATE_ROUTE.PROJECTS}/${PRIVATE_ROUTE.MAINTENANCE}/${PRIVATE_ROUTE.NEW}`,
                    }}
                  >
                    <div className="order_card_header_right">
                      <PlusOutlined />

                      {intl.messages['Add New']}
                    </div>
                  </Link>
                </ShowForPermission>
              )}
            </div>
            <ListView
              list={maintenancelist}
              count={maintenanceProjectlist && maintenanceProjectlist.count}
              changePagination={changePaginationMaintenance}
              page={maintenancePage}
            />
          </div>
        </Col>
      </Row>
    </OrderSectionWrapper>
  );
};
OrderMaintanenceList.propTypes = {
  orderList: PropTypes.arrayOf(PropTypes.any),
  maintenancelist: PropTypes.arrayOf(PropTypes.any),
  maintenanceProjectlist: PropTypes.objectOf(PropTypes.any),
  orderProjectlist: PropTypes.objectOf(PropTypes.any),
  changePaginationOrder: PropTypes.func,
  changePaginationMaintenance: PropTypes.func,
};
export default OrderMaintanenceList;
