import moment from 'moment';
import { PRIVATE_ROUTE } from 'router';

export const FormType = [PRIVATE_ROUTE.NEW, PRIVATE_ROUTE.EDIT];
export const ProjectType = [PRIVATE_ROUTE.ORDER, PRIVATE_ROUTE.MAINTENANCE];
export const OrgTypes = [PRIVATE_ROUTE.SUPPLIERS, PRIVATE_ROUTE.CUSTOMERS];
export function getItemFromUrl(history, searchWith) {
  const urlArr = history.location.pathname.split('/');
  const intersection = urlArr.filter(x => searchWith.includes(x));
  return intersection[0];
}
export function getValue({ info, field, type, innerObj, format }) {
  if (type === 'value' && !innerObj) {
    if (info && info[field]) return info[field];
  }
  if (type === 'date' && !innerObj) {
    if (info && info[field]) return moment(info[field]).format(format);
  }
  if (innerObj && type === 'value') {
    if (info && info[innerObj] && info[innerObj][field])
      return info[innerObj][field];
  }
  if (innerObj && type === 'date') {
    if (info && info[innerObj] && info[innerObj][field])
      return moment(info[innerObj][field].format(format));
  }
  return 'N/A';
}
