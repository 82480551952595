import { http } from 'services';
import { getHeader } from 'lib/helpers';
import { userRolesUrl } from './endpoints';

export const UserRoles = {
  index: query =>
    http.get(query ? `${userRolesUrl}?type=${query}` : userRolesUrl, {
      headers: getHeader(),
    }),
  create: data =>
    http.post(userRolesUrl, data, {
      headers: getHeader(),
    }),
};
