import React from 'react';
import { Provider } from 'react-redux';
import AppStyles from 'styles/AppStyles';
import Routes from 'router';
import { loadProgressBar } from 'axios-progress-bar';
import { store } from 'store';
import { http } from 'services';
import AppProvider from './AppProvider';
import 'nprogress/nprogress.css';
import 'styles/app.less';

const App = () => {
  // load progress bar on every http request by custom axios instance
  loadProgressBar({}, http);

  return (
    <Provider store={store}>
      <AppProvider>
        <>
          <AppStyles />
          <Routes />
        </>
      </AppProvider>
    </Provider>
  );
};

export default App;
