import React, { useEffect, useState } from 'react';
import { Card } from 'antd';
import h337 from 'heatmap.js';
import * as d3 from 'd3';
import * as soccer from 'd3-soccer';
import { useSelector } from 'react-redux';
import CountCards from './CountCards';

function HeatMap() {
  const { x_coord_avrg, y_coord_avrg, heat_map_data = [] } = useSelector(
    state => state.Reports.objectTracking,
  );

  const [heatmapInstance, setHeatmapInstance] = useState('');
  const timestamp = new Date().getTime(); // timestamp for heatmap rendering. because, possible to same data length come from backend

  useEffect(() => {
    // customized heatmap configuration
    setHeatmapInstance(
      h337.create({
        // required container
        container: document.querySelector('.heatmap'),
        // backgroundColor to cover transparent areas
        // backgroundColor: 'rgba(0,0,0,.95)',
        // custom gradient colors
        // gradient: {
        //   // enter n keys between 0 and 1 here
        //   // for gradient color customization
        //   '.5': 'blue',
        //   '.8': 'red',
        //   '.95': 'white',
        // },
        // the maximum opacity (the value with the highest intensity will have it)
        maxOpacity: 0.9,
        // minimum opacity. any value > 0 will produce
        // no transparent gradient transition
        minOpacity: 0.02,
      }),
    );

    const h = 450;
    const pitch = soccer
      .pitch()
      .height(h)
      .clip([
        [2.2, 1.7],
        [105, 68],
      ])
      .goals('line')
      .rotate(false)
      .showDirOfPlay(false)
      .shadeMiddleThird(false)
      .pitchStrokeWidth(0.5) // Strokewidth of the pitch lines
      .goals('line');

    d3.select('.heatmap')
      // .attr('width', pitch.width() + 1000)
      .attr('height', h + 30)
      .attr('class', 'dark-theme')
      .call(pitch);
  }, []);

  useEffect(() => {
    if (heatmapInstance) {
      // empty previous heatmap data
      heatmapInstance.setData({
        max: 0,
        data: [],
      });
      // now generate some random data
      const points = [];
      // let max = 0;
      const width = 697;
      const height = 450;
      // let len = 300;
      // eslint-disable-next-line
      for (let item of heat_map_data) {
        // item.x_coord *= 66.67;
        // item.y_coord *= 50;
        // max = Math.max(max, item.value);
        points.push({
          x: item?.x_coord * (width / x_coord_avrg),
          y: item?.y_coord * (height / y_coord_avrg),
          value: item?.value === 0 ? -1000000 : item?.value,
          radius: 60,
        });
      }
      // heatmap data format
      const data = {
        max: heat_map_data?.[0]?.value,
        data: points,
      };
      console.log({ data });
      // if you have a set of datapoints always use setData instead of addData
      // for data initialization
      heatmapInstance.setData(data);
    }
  }, [timestamp]);

  return (
    <Card
      style={{ marginTop: '24px', borderRadius: '10px' }}
      bodyStyle={{ padding: '24px' }}
    >
      <CountCards />
      <div
        style={{
          height: '600px',
          width: '100%',
          backgroundColor: '#599339',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <div
          className="heatmap"
          style={{
            height: '450px',
            width: '697px',
            marginTop: '40px',
          }}
        />
      </div>
    </Card>
  );
}

export default HeatMap;
