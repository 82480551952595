import { Table as AntTable } from 'antd';
import styled from 'styled-components';

const Table = styled(AntTable)`
  &&& {
    overflow: hidden;
    margin-top: 0.5rem;
    .ant-table {
      border-radius: 10px;
      overflow: hidden;
      button: {
        height: 3rem;
      }
      td,
      th {
      }
      tbody tr:hover td {
        background: #ebf7ef;
      }
      thead {
        background-color: #fff;
        box-shadow: 0px 8px 16px rgba(109, 109, 109, 0.08);
        th {
          background-color: #fff;
          color: #3fb65f;
          font-size: 1.5rem;
          padding: 2.2rem 4rem;
          border: none;
          box-shadow: 0px 4px 15px rgba(35, 123, 176, 0.03);
        }
      }
      .hide {
        display: none;
      }

      tbody {
        td {
          padding: 3.5rem 4rem;
          font-size: 1.5rem;
        }
      }
    }
    &.peopleTable {
      tbody {
        td {
          padding: 22px 40px;
        }
      }
    }
  }
`;

export default Table;
