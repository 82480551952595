import React, { useState, useEffect } from 'react';
import { Row, Col, Button } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import MessageWrapper from './Message.styles';
import MessageSidebar from './MessageSidebar';
import MessageView from './MessageView';
import NewMessageModal from './NewMessageModal';
import {
  getChatWithUserRequest,
  getChannelListRequest,
  channelReadAllRequest,
  getUnreadMsgCountRequest,
} from './reducer';

const Message = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedUserId, setUserID] = useState(null);
  const [channels, setAllChannels] = useState([]);
  const [allChats, setAllChats] = useState([]);
  const [page, setPage] = useState(1);

  const scrollRef = React.useRef();
  const dispatch = useDispatch();
  const currentUser = useSelector(state => state.CurrentUser.info);
  function chatUser(item) {
    if (currentUser.id !== item.user_1) return 'user_1';
    if (currentUser.id !== item.user_2) return 'user_2';
    return '';
  }
  function getchannelList(channel) {
    const list = [];
    if (channel && channel.length > 0) {
      channel.map(item => {
        if (item) {
          list.push({
            id: item.id,
            userId: item[`${chatUser(item)}_profile`].id,
            channel: item.title,
            name: `${`${item[`${chatUser(item)}_profile`].first_name} `}${
              item[`${chatUser(item)}_profile`].last_name
            }`,
            role: item[`${chatUser(item)}_profile`].group,
            org_name:
              (item[`${chatUser(item)}_profile`].organization &&
                item[`${chatUser(item)}_profile`].organization.name) ||
              'N/A',
            createdAt: item.created_at,
            unread_count: item.unread_count,
            image:
              item.image ||
              'https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png',
          });
        }
        return list;
      });
    }
    return list;
  }

  const channelList = getchannelList(channels);
  console.log(channelList);
  useEffect(() => {
    dispatch(getChannelListRequest({ setAllChannels }));
  }, [dispatch]);

  let uId = '';
  let unreadCount = 0;
  let firstChannel = '';
  if (!selectedUserId && channelList && channelList.length > 0) {
    uId = channelList[0].userId;
    unreadCount = channelList[0].unread_count;
    firstChannel = channelList[0].channel;
  } else {
    uId = selectedUserId;
  }

  const chatList = useSelector(state => state.Message.chatList);

  useEffect(() => {
    if (uId) {
      dispatch(
        getChatWithUserRequest({
          id: uId,
          page,
          setAllChats,
        }),
      );
    }
  }, [dispatch, uId, page]);

  const handleScroll = () => {
    const { scrollTop } = scrollRef.current;
    if (uId) {
      if (scrollTop === 0) {
        let pageNo = 1;
        if (allChats && allChats.length > 0) {
          pageNo = Math.floor(allChats.length / 10) + 1;
        }
        setPage(pageNo);
        if (chatList && !chatList.empty) {
          scrollRef.current.scrollTop = 100;
          dispatch(
            getChatWithUserRequest({
              id: uId,
              page: pageNo,
              setAllChats,
            }),
          );
        }
      }
    }
  };

  // useEffect(() => {
  //   if (channelList && channelList.length > 0) {
  //     setUserID(channelList[0].userId);
  //   }
  // }, [setUserID]);

  const changeChannel = (id, channel, unread_count) => {
    setAllChats([]);
    if (unread_count > 0) {
      dispatch(getUnreadMsgCountRequest());
      const data = {
        channel,
      };
      const formData = new FormData();
      Object.keys(data).forEach(key => formData.append(key, data[key]));
      dispatch(channelReadAllRequest({ formData, setAllChannels }));
    }
    dispatch(getChatWithUserRequest({ id, page, setAllChats }));
    setUserID(id);
  };
  return (
    <MessageWrapper>
      <div className="message_top">
        <Row>
          <Col span={6}>
            <h2 className="message_title">
              <FormattedMessage id="Messages" />
            </h2>
            <Button
              onClick={() => setIsModalVisible(true)}
              size="small"
              type="primary"
              icon={<PlusOutlined />}
            >
              <FormattedMessage id="New Message" />
            </Button>
            <NewMessageModal
              isModalVisible={isModalVisible}
              setIsModalVisible={setIsModalVisible}
              setAllChannels={setAllChannels}
              setUserID={setUserID}
              setAllChats={setAllChats}
            />
          </Col>
        </Row>
      </div>
      <div className="message_body">
        <Row>
          <Col span={6}>
            <MessageSidebar
              changeChannel={changeChannel}
              selectedUserId={uId}
              channelList={channelList}
              // loadChannelList={loadChannelList}
              setAllChannels={setAllChannels}
              //  page={page}
              setAllChats={setAllChats}
              setUserID={setUserID}
            />
          </Col>
          <Col span={18}>
            <MessageView
              chatList={allChats}
              selectedUserId={uId}
              channelList={channelList}
              unreadCount={unreadCount}
              firstChannel={firstChannel}
              setAllChannels={setAllChannels}
              handleScroll={handleScroll}
              scrollRef={scrollRef}
              setAllChats={setAllChats}
            />
          </Col>
        </Row>
      </div>
    </MessageWrapper>
  );
};

export default Message;
