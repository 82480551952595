import { createSlice, createAction } from '@reduxjs/toolkit';

const initState = {
  channels: [],
  count: 0,
};

const MessageSlice = createSlice({
  name: 'message',
  initialState: initState,
  reducers: {
    getChatListWithUser(state, { payload }) {
      state.chatList = payload;
    },
    getUnreadMessageCount(state, { payload }) {
      state.count = payload;
    },
  },
});

export const getChannelListRequest = createAction(
  'msg/get_channel_list_request',
);

export const getChatUserListRequest = createAction(
  'msg/get_chat_user_list_request',
);
export const createChannelRequest = createAction('msg/create_channel_request');

export const getChatWithUserRequest = createAction(
  'msg/get_chat_with_user_request',
);

export const sendChatRequest = createAction('msg/send_chat_request');

export const channelReadAllRequest = createAction(
  'msg/channel_read_all_request',
);
export const getUnreadMsgCountRequest = createAction(
  'msg/unread_msg_count_request',
);

export const {
  getChatListWithUser,
  getUnreadMessageCount,
} = MessageSlice.actions;

export default MessageSlice;
