import React from 'react';
import { Tabs as AntTabs } from 'antd';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import { tint } from 'polished';
import UserSettingsBasicForm from './UserSettingsBasicForm';
import UserSettingsChangePassForm from './UserSettingsChangePassForm';

const { TabPane } = AntTabs;

const Tabs = styled(AntTabs)`
  border: 1px solid ${props => tint(0.1, props.theme.primary)};
  border-radius: 4px;
  /* width:  */

  .ant-tabs-tab {
    padding: 2.4rem 4rem;
  }

  .ant-tabs-ink-bar {
    background-color: ${props => tint(0.4, props.theme.primary)};
  }
`;

const SettingsTab = () => {
  const intl = useIntl();

  return (
    <Tabs defaultActiveKey="1">
      <TabPane tab={intl.messages['Basics']} key="1">
        <UserSettingsBasicForm />
      </TabPane>
      <TabPane tab={intl.messages['Change Password']} key="2">
        <UserSettingsChangePassForm />
      </TabPane>
    </Tabs>
  );
};

export default SettingsTab;
