import React, { useState } from 'react';
import { Row, Col, Button } from 'antd';
import { FormattedMessage } from 'react-intl';
import { PlusOutlined } from '@ant-design/icons';
import { ShowForPermission } from 'components/utility';
import FieldList from './FieldList';
import NewField from './CreateNewField/NewField';

function Users() {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [offset, setOffset] = useState(0);
  return (
    <>
      <Row type="flex" justify="space-between" align="middle">
        <Col>
          <h2 className="font-bold">
            <FormattedMessage id="Fields" />
          </h2>
        </Col>
        <Col>
          <ShowForPermission permission={['can_add_new_location']}>
            <Button
              icon={<PlusOutlined />}
              type="link"
              onClick={() => setIsModalVisible(true)}
              className="font-bold"
            >
              <span>
                <FormattedMessage id="Add New Field" />
              </span>
            </Button>
          </ShowForPermission>
          <NewField
            isModalVisible={isModalVisible}
            setIsModalVisible={setIsModalVisible}
            action="create"
            fieldInfo={null}
            offset={offset}
            pagination_off={false}
          />
        </Col>
      </Row>
      <FieldList setOffset={setOffset} offset={offset} />
    </>
  );
}

export default Users;
