import axios from 'axios';
import { message } from 'antd';
import { store } from 'store';
import { logout } from 'features/UserAuth';
import { removeCurrentUser } from 'features/UserSettings';

const http = axios.create({
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

http.interceptors.response.use(null, error => {
  const state = store.getState();
  if (!state?.Auth?.idToken && error?.response?.status === 401) {
    return { error };
  }
  if (error?.response?.status === 401) {
    store.dispatch(logout());
    store.dispatch(removeCurrentUser());
    message.error(error.response.data.detail);
  } else if (error?.response?.status === 400 && error?.response?.data?.detail) {
    const formErrors = error.response.data.detail;

    if (typeof formErrors !== 'object') {
      return Promise.reject(error);
    }

    Object.keys(formErrors).forEach(fieldName => {
      Object.keys(formErrors[fieldName]).forEach(errorIndex => {
        const errorString = formErrors[fieldName][errorIndex];
        message.error(`${fieldName}: ${errorString.replace('This', '')}`);
      });
    });
    return { error };
  } else if (error?.response?.data?.detail) {
    message.error(error.response.data.detail);
    return { error };
  } else if (error?.response?.message) {
    message.error(error.response.message);
    return { error };
  }

  const formErrors = error.response.data;
  Object.keys(formErrors).forEach(fieldName => {
    if (!Array.isArray(formErrors[fieldName])) return;
    formErrors[fieldName].forEach(text => {
      message.error(`${text.replace('This', '')}`);
    });
  });

  return { error };
});

export default http;
