import { http } from 'services';
import { getHeader } from 'lib/helpers';
import {
  stepsUrl,
  eSignFormUrl,
  stepFormUrl,
  fileUploadUrl,
  stepsCommentUrl,
} from './endpoints';

export const ProjectSteps = {
  index: () =>
    http.get(stepsUrl, {
      headers: getHeader(),
    }),
  create: data =>
    http.post(stepsUrl, data, {
      headers: getHeader(),
    }),
  update: (id, data) =>
    http.put(`${stepsUrl}${id}/`, data, {
      headers: getHeader(),
    }),
  delete: step_id =>
    http.delete(`${stepsUrl}${step_id}/`, {
      headers: getHeader(),
    }),
  getSingleById: id =>
    http.get(`${stepsUrl}${id}/`, {
      headers: getHeader(),
    }),
  getOrgByProjectId: projectId =>
    http.get(`${stepsUrl}organizations/${projectId}/`, {
      headers: getHeader(),
    }),
  getResponsibleById: (orgId, projectId) =>
    http.get(`${stepsUrl}responsible/${projectId}/${orgId}/`, {
      headers: getHeader(),
    }),
  eSignList: orgId =>
    http.get(`${eSignFormUrl}?responsible_org=${orgId}`, {
      headers: getHeader(),
    }),
  stepESignList: slug => http.get(`${stepsUrl}slug/?slug=${slug}`),
  sendOTP: slug => http.get(`${stepsUrl}otp/${slug}/`),
  verifyOTP: otp => http.get(`${stepsUrl}verify-otp/${otp}/`),
  updateForm: (data, slug) => http.post(`${stepFormUrl}update/${slug}/`, data),
  uploadFileFormTemplate: formData => http.post(`${fileUploadUrl}`, formData),
  startStep: step_id =>
    http.get(`${stepsUrl}${step_id}/start/`, {
      headers: getHeader(),
    }),
  sendComment: data =>
    http.post(stepsCommentUrl, data, {
      headers: getHeader(),
    }),
  getComments: stepId =>
    http.get(`${stepsUrl}${stepId}/comments/`, {
      headers: getHeader(),
    }),
  reviewComment: (commentId, formData) =>
    http.post(`${stepsCommentUrl}${commentId}/review/`, formData, {
      headers: getHeader(),
    }),
};
