import { Avatar } from 'antd';
import styled from 'styled-components';
import editBadge from 'assets/icons/edit-green-circle.svg';

export const UserAvatar = styled(Avatar)`
  position: relative;
  overflow: initial;

  &::after {
    background-image: url(${editBadge});
    /* border-radius: 50%; */
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    height: 3.2rem;
    width: 3.2rem;
    right: 0;
    top: 0;
    content: '';
  }

  img {
    border-radius: 50%;
  }
`;

export const FormWrapper = styled.div`
  .dp-upload {
    .ant-form-item-control {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
`;
