import React from 'react';
import PropTypes from 'prop-types';
import football_pitch from './football_pitch.png';

function FootballField({ children }) {
  return (
    <>
      <img
        src={football_pitch}
        className="football_pitch"
        style={{
          height: '1100px',
          width: '600px',
          marginTop: '0',
          position: 'absolute',
        }}
        alt="football pitch"
      />
      {children}
    </>
  );
}

FootballField.propTypes = {
  children: PropTypes.node,
};

export default FootballField;
