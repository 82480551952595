import { createSlice, createAction } from '@reduxjs/toolkit';

const initState = {
  info: {
    activated_at: null,
    date_joined: null,
    deleted_at: null,
    email: null,
    first_name: null,
    group: null,
    id: null,
    image: null,
    is_active: false,
    is_deleted: false,
    is_staff: false,
    last_active_at: null,
    last_name: null,
    last_password_change: null,
    organization: null,
    permissions: [],
    phone: null,
    updated_at: null,
  },
};

const CurrentUser = createSlice({
  name: 'currentUser',
  initialState: initState,
  reducers: {
    getCurrentUser(state, { payload }) {
      state.info = payload;
    },
    removeCurrentUser(state) {
      state.info = null;
    },
    updateCurrentUser(state, { payload }) {
      state.info = {
        ...state.info,
        ...payload,
      };
    },
  },
});

export const getCurrentUserRequest = createAction(
  'currentUser/get_current_user_request',
);

export const removeCurrentUserRequest = createAction(
  'currentUser/remove_current_user_request',
);

export const updateCurrentUserRequest = createAction(
  'currentUser/update_current_user_request',
);

export const updatePasswordRequest = createAction(
  'currentUser/update_password_request',
);

export const {
  getCurrentUser,
  updateCurrentUser,
  removeCurrentUser,
} = CurrentUser.actions;

export default CurrentUser;
