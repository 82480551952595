import React, { memo } from 'react';
import { Card as AntCard } from 'antd';
import { RightOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { FormattedMessage, useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { tint, shade } from 'polished';
import { Link } from 'react-router-dom';
import { PRIVATE_ROUTE } from 'router';
import { ShowForPermission } from 'components/utility';
import PopOverCardTitle from './PopOverCardTitle';

const Card = styled(AntCard)`
  box-shadow: ${props => props.theme.shadowLight};
  border-radius: 5px;
  overflow: hidden;
  &.draft {
    .ant-card-head {
      background: ${props => shade(0.2, props.theme.grey)};
    }
  }
  &.completed {
    .ant-card-head {
      background: ${props => shade(0.2, props.theme.green)};
    }
  }
  &.delayed {
    .ant-card-head {
      background: ${props => shade(0.2, props.theme.orange)};
    }
  }
  &.in-progress {
    .ant-card-head {
      background: ${props => shade(0.2, props.theme.yellow)};
    }
  }
  .ant-card-head {
    background: ${props => shade(0.2, props.theme.primary)};
    color: #fff;
    font-size: 14px;
    padding: 12px 16px;
    font-weight: 400;
    small {
      display: block;
      font-size: 12px;
    }
  }
  .ant-card-head-title {
    padding: 0;
  }

  .ant-card-body {
    padding: 12px 16px;
    color: ${props => tint(0.2, props.theme.secondary)};
    font-size: 16px;
    .customer_name {
      margin-bottom: 5px;
      font-size: 12px;
    }
    a {
      font-size: 14px;
      display: inline-block;
      color: ${props => tint(0.2, props.theme.secondary)};
      .anticon {
        margin-left: 5px;
      }
    }
  }
`;

function PopOverCard({
  item: { name = '', status = '', customer = '', stepsId },
}) {
  const intl = useIntl();
  const stepsUrl = `/dashboard/${PRIVATE_ROUTE.PROJECTS}/${PRIVATE_ROUTE.STEPS}/${stepsId}`;

  return (
    <Card
      title={<PopOverCardTitle name={name} status={status} />}
      className={status}
    >
      <div className="customer_name">
        {`${intl.messages['Customer']}: ${customer}`}
      </div>
      <ShowForPermission permission={['can_see_all_step_detail']}>
        <Link to={stepsUrl}>
          <FormattedMessage id="More details" />
          <RightOutlined />
        </Link>
      </ShowForPermission>
    </Card>
  );
}

PopOverCard.propTypes = {
  item: PropTypes.shape({
    name: PropTypes.string,
    status: PropTypes.string,
    stepsId: PropTypes.number,
    projectId: PropTypes.number,
    customer: PropTypes.string,
  }),
};

export default memo(PopOverCard);
