import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';

function PopOverCardTitle({ name = '', status = '' }) {
  return (
    <>
      {name && name}
      <small>
        <FormattedMessage id="Status" />
        {`: ${status}`}
      </small>
    </>
  );
}

PopOverCardTitle.propTypes = {
  name: PropTypes.string,
  status: PropTypes.string,
};

export default PopOverCardTitle;
