import { takeLatest, put, call } from 'redux-saga/effects';
import { message } from 'antd';
import { Todo } from 'api';
import {
  getTodoList,
  getTodoListReq,
  createTodoListReq,
  userSearchReq,
  getUsers,
  updateTodoListReq,
  deleteTodoListReq,
  getSingleTodoReq,
  getTodo,
} from './reducer';

export function* getTodoListSaga({
  payload: { assigned_person, project_id, offset, limit, status, shortings },
}) {
  const res = yield call(
    Todo.getTodoList,
    assigned_person,
    project_id,
    offset,
    limit,
    status,
    shortings,
  );
  if (res?.data) {
    yield put(getTodoList(res.data));
  }
}

export function* createTodoListSaga({
  payload: {
    data,
    succMessage,
    handleModalClose,
    resetForm,
    assigned_person,
    project_id,
  },
}) {
  const res = yield call(Todo.createTodoList, data);
  if (res?.data) {
    resetForm();
    handleModalClose();
    message.success(succMessage);
    yield call(getTodoListSaga, {
      payload: { assigned_person, project_id },
    });
  }
}

export function* userSearchSaga({ payload: { text, project_id } }) {
  const res = yield call(Todo.userSearch, text, project_id);
  if (res?.data) {
    yield put(getUsers(res.data));
  }
}

export function* updateTodoListSaga({
  payload: {
    data,
    succMessage,
    handleModalClose,
    resetForm,
    assigned_person,
    project_id,
    id,
    offset,
    limit,
  },
}) {
  const res = yield call(Todo.updateTodoList, data, id);
  if (res?.data) {
    let res2;
    if (data?.status) {
      res2 = yield call(
        Todo.statusUpdateTodoList,
        {
          status: data?.status,
        },
        id,
      );
    }
    if (res2) {
      resetForm();
      handleModalClose();
      message.success(succMessage);
      yield call(getTodoListSaga, {
        payload: { assigned_person, project_id, offset, limit },
      });
    }
  }
}

export function* deleteTodoListSaga({
  payload: { succMessage, assigned_person, project_id, id, offset, limit },
}) {
  const res = yield call(Todo.deleteTodoList, id);
  if (res.data) {
    message.success(succMessage);
    yield call(getTodoListSaga, {
      payload: { assigned_person, project_id, offset, limit },
    });
  }
}

export function* getSingleTodoSaga({ payload: { id } }) {
  const res = yield call(Todo.getTodo, id);
  if (res?.data) {
    yield put(getTodo(res.data));
  }
}

export default function* watchTodoActions() {
  yield takeLatest(getTodoListReq.toString(), getTodoListSaga);
  yield takeLatest(createTodoListReq.toString(), createTodoListSaga);
  yield takeLatest(userSearchReq.toString(), userSearchSaga);
  yield takeLatest(updateTodoListReq.toString(), updateTodoListSaga);
  yield takeLatest(deleteTodoListReq.toString(), deleteTodoListSaga);
  yield takeLatest(getSingleTodoReq.toString(), getSingleTodoSaga);
}
