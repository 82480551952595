import styled from 'styled-components';

export const UploadFormWrapper = styled.div`
  .ant-form-item-control-input-content {
    .ant-upload.ant-upload-drag {
      border-radius: 5px;
      .ant-upload {
        padding: 46px 0;
      }
      .ant-upload-drag-icon {
        margin-bottom: 0;
      }
      .anticon-upload {
        font-size: 18px;
        color: #c7cad1;
      }
      .ant-upload-text {
        color: #c7cad1;
        font-size: 14px;
        line-height: 155%;
      }
    }
  }
`;
