import React, { useState } from 'react';
import { Popover } from 'antd';
import PropTypes from 'prop-types';
import { EllipsisOutlined } from '@ant-design/icons';
import EsignCardActionContent from './EsignCardActionContent';

function EsignCardAction({ id }) {
  const [visible, setVisible] = useState(false);

  const handleVisibleChange = v => {
    setVisible(v);
  };

  return (
    <div className="bottom_action">
      <Popover
        placement="left"
        content={<EsignCardActionContent id={id} />}
        trigger="click"
        visible={visible}
        onVisibleChange={v => handleVisibleChange(v)}
      >
        <EllipsisOutlined key="ellipsis" />
      </Popover>
    </div>
  );
}

EsignCardAction.propTypes = {
  id: PropTypes.number,
};

export default EsignCardAction;
