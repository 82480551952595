import styled from 'styled-components';

const AddOrgFormWrapper = styled.div`
  .ant-form-item-control-input-content {
    .ant-input:not(textarea) {
      height: 48px;
      border-radius: 5px !important;
    }
    .ant-upload.ant-upload-drag {
      border-radius: 5px;
      .ant-upload {
        padding: 46px 0;
      }
      .ant-upload-drag-icon {
        margin-bottom: 0;
      }
      .anticon-upload {
        font-size: 18px;
        color: #c7cad1;
      }
      .ant-upload-text {
        color: #c7cad1;
        font-size: 14px;
        line-height: 155%;
      }
    }
  }

  .ant-form-item-label {
    label {
      font-weight: 500;
      font-size: 16px;
      line-height: 155%;
      color: #3e455c;
    }
  }

  .form-header {
    padding: 24px 40px;
    background: #fff;
    box-shadow: 0px 8px 16px rgba(109, 109, 109, 0.08);
    border-radius: 14px 14px 0px 0px;
    position: relative;
    z-index: 9;
    h2 {
      margin: 0;
      color: #3fb65f;
      font-weight: bold;
      font-size: 16px;
      line-height: 1.55;
      span {
        text-transform: capitalize;
      }
    }
  }
  .customer_section {
    background: #fff;
    padding: 40px;
    border-radius: 0 0 14px 14px;
  }
  .admin-header {
    h2 {
      background: #ecf8ef;
      color: #3fb65f;
      font-weight: bold;
      font-size: 16px;
      line-height: 155%;
      padding: 12px 40px;
      margin: 0;
      span {
        text-transform: capitalize;
      }
    }
  }
  .admin_section {
    background: #fff;
    padding: 40px;
    border-radius: 0 0 14px 14px;
  }
`;

export default AddOrgFormWrapper;
