import React, { memo } from 'react';
import { Card, Avatar } from 'antd';
import { Link as EsignLink } from 'react-router-dom';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { tint, shade } from 'polished';

const { Meta } = Card;

const Link = styled(EsignLink)`
  display: block;
  height: 100%;

  .ant-card {
    box-shadow: ${props => props.theme.shadowLight};
    border: 1px solid ${props => tint(0.9, props.theme.secondary)};
    border-radius: 10px;
    min-height: 144px;
    background: #fff;
    height: 100%;
  }

  .ant-avatar {
    width: 80px;
    height: 80px;
    margin: 40px auto;
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${props => tint(0.9, props.theme.orange)};
    border-radius: 10px;
    .anticon {
      color: ${props => tint(0.1, props.theme.orange)};
      font-size: 40px;
    }
  }
  .ant-card-body {
    padding: 24px;
    border-top: 1px solid ${props => tint(0.9, props.theme.secondary)};
  }
  .ant-card-meta {
    display: flex;

    &-title {
      color: ${props => shade(0.1, props.theme.orange)};
      font-size: 16px;
    }
  }
`;

function EsignCard({ title = '', url = '#', icon = '' }) {
  return (
    <Link to={url}>
      <Card cover={<Avatar shape="square" icon={icon} />}>
        <Meta title={title} />
      </Card>
    </Link>
  );
}

EsignCard.propTypes = {
  title: PropTypes.string,
  url: PropTypes.string,
  icon: PropTypes.node,
};

export default memo(EsignCard);
