const textBoxS = {
  type: 'text',
  label: 'S:',
  placeholder: 'S:',
  value: '',
};
const textBoxSR = {
  type: 'text',
  label: 'S+R:',
  placeholder: 'S+R:',
  value: '',
};
const infillMeasureFormFields = {
  keeper_1: [textBoxS, textBoxSR],
  defensive_1: [
    [textBoxS, textBoxSR],
    [textBoxS, textBoxSR],
    [textBoxS, textBoxSR],
  ],
  midfield_1: [
    [textBoxS, textBoxSR],
    [textBoxS, textBoxSR],
    [textBoxS, textBoxSR],
  ],
  midfield_2: [
    [textBoxS, textBoxSR],
    [textBoxS, textBoxSR],
    [textBoxS, textBoxSR],
  ],
  defensive_2: [
    [textBoxS, textBoxSR],
    [textBoxS, textBoxSR],
    [textBoxS, textBoxSR],
  ],
  keeper_2: [textBoxS, textBoxSR],
};
export default infillMeasureFormFields;
