import React from 'react';
import { useHistory, Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Row, Col, Form, Input, Button } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import { PUBLIC_ROUTE } from 'router/routePaths';
import { forgotPasswordRequest } from 'features/UserAuth/reducer';

import grasLogo from 'assets/images/gras-logo.svg';
import { Wrapper } from 'components/uielements';
import getValidateMessages from 'lib/helpers/validators';
import UserAuthWrapper from './UserAuth.styles';

export default function ForgotPassword() {
  const history = useHistory();
  const dispatch = useDispatch();
  const intl = useIntl();

  const onFinish = ({ email }) => {
    dispatch(forgotPasswordRequest({ email }));

    history.push(PUBLIC_ROUTE.SIGN_IN);
  };

  return (
    <UserAuthWrapper>
      <Wrapper width="74rem">
        <Row className="mt-2" type="flex" justify="center" align="middle">
          <Col span={24}>
            <Row type="flex" justify="center" align="middle">
              <img src={grasLogo} alt="grasmiljio" />
            </Row>
          </Col>
          <Col span={24}>
            <h3 className="text-center font-bold mt-1 mb-1">
              <FormattedMessage id="Retrive your account!" />
            </h3>
          </Col>
          <Col span={20} className="mt-1 mb-1">
            <Form
              name="loginForm"
              onFinish={onFinish}
              validateMessages={getValidateMessages(intl.messages, 'name')}
            >
              <Form.Item
                name="email"
                rules={[
                  {
                    required: true,
                    type: 'email',
                  },
                ]}
                hasFeedback
              >
                <Input
                  size="large"
                  placeholder={intl.messages['Username/Email']}
                />
              </Form.Item>

              <Row
                className="mt-2 mb-1"
                type="flex"
                justify="space-between"
                align="middle"
              >
                <Col>
                  <Link to={PUBLIC_ROUTE.SIGN_IN}>
                    <FormattedMessage id="Cancel" />
                  </Link>
                </Col>
                <Col>
                  <Button size="large" type="primary" htmlType="submit">
                    <FormattedMessage id="Confirm" />
                  </Button>
                </Col>
              </Row>
              <Form.Item />
            </Form>
          </Col>
        </Row>
      </Wrapper>
    </UserAuthWrapper>
  );
}
