/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import {
  Form,
  Input,
  Upload,
  Button,
  Row,
  Col,
  Divider,
  Select,
  Spin,
  DatePicker,
} from 'antd';

import { PlusOutlined, UploadOutlined } from '@ant-design/icons';
import { useIntl, FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import getValidateMessages from 'lib/helpers/validators';
import { MapForView } from 'features/GoogleMap';
import {
  addNewProjectRequest,
  getAllGMProjectLeaderRequest,
  getAllGMSalijRequest,
  getAllGMCustomerProjectLeaderRequest,
  updateProjectRequest,
} from 'features/Projects/reducer';

import { useParams, useHistory } from 'react-router';
import { ShowForPermission } from 'components/utility';
import {
  getItemFromUrl,
  ProjectType,
  FormType,
} from 'components/utility/CustomeFunc';
import { PRIVATE_ROUTE } from 'router';
import moment from 'moment';
import { getAllFieldsRequest } from 'features/Fields/reducer';
import NewField from '../../../Fields/CreateNewField/NewField';

const { TextArea } = Input;

const { Dragger } = Upload;

const { Option } = Select;
function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}
const ProjectForm = ({ projectInfo }) => {
  const { id } = useParams();
  const intl = useIntl();
  const [loadMap, setLoadMap] = useState(false);
  const [closeMarker, setCloseMarker] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [prevFiles, setFiles] = useState([]);
  const [point, setMapPoint] = useState({
    latitude: 59.32932349999999,
    longitude: 18.0685808,
  });

  const [optionData, setOptionData] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const history = useHistory();

  const [form] = Form.useForm();
  const projectType = getItemFromUrl(history, ProjectType);
  const formType = getItemFromUrl(history, FormType);
  const gmProjectLeader = useSelector(state => state.Projects.gmProjectLeader);
  const gmSalij = useSelector(state => state.Projects.gmSalj);
  const gmCustomerProjectLeader = useSelector(
    state => state.Projects.gmCustomerProjectLeader,
  );
  const info = projectInfo;
  const currentUser = useSelector(state => state.CurrentUser.info);
  const dispatch = useDispatch();
  const customerId =
    id || (info && info.organization && info.organization.id) || undefined;

  const fieldData = useSelector(state => state.Fields.list);

  useEffect(() => {
    dispatch(
      getAllFieldsRequest({
        name: '',
        organization: customerId,
        pagination_off: true,
      }),
    );
  }, [dispatch]);

  useEffect(() => {
    if (Array.isArray(fieldData)) {
      // field data will be set when is an array
      setOptionData(fieldData);
    }
  }, [fieldData]);

  useEffect(() => {
    dispatch(getAllGMProjectLeaderRequest());
    dispatch(getAllGMSalijRequest());
    if (customerId) {
      dispatch(getAllGMCustomerProjectLeaderRequest({ id: customerId }));
    }
  }, [dispatch, customerId]);

  useEffect(() => {
    if (formType === PRIVATE_ROUTE.NEW) {
      setLoadMap(true);
      form.setFieldsValue({
        is_active: false,
        is_sms: 1,
      });
    }
    setShowForm(true);
    if (info && formType === PRIVATE_ROUTE.EDIT) {
      const {
        name,
        description,
        location,
        gm_pl,
        gm_salj,
        customer_pl,
        attachments,
        deadline,
        is_active,
        start_date,
        is_sms,
      } = info;

      form.setFieldsValue({
        name,
        location: { label: location?.name, value: location?.id },
        description,
        gm_salj: gm_salj ? gm_salj.id : undefined,
        gm_pl: gm_pl ? gm_pl.id : undefined,
        customer_pl: customer_pl ? customer_pl.id : undefined,
        deadline: deadline ? moment(deadline) : undefined,
        start_date: start_date ? moment(start_date) : undefined,
        is_active: is_active || false,
        is_sms: is_sms ? 1 : 0,
      });
      setMapPoint(location.point);
      setFiles(attachments);
      setLoadMap(true);
    }
  }, [form, info]);

  const normFile = async ({ fileList }) => {
    const files = await Promise.all(
      fileList.map(async element => {
        let imageFile = '';
        imageFile = await getBase64(element.originFileObj);
        return { name: element.name, file: imageFile };
      }),
    );
    return files;
  };

  const onFinish = async ({
    name,
    description,
    files,
    gm_pl,
    gm_salj,
    customer_pl,
    deadline,
    is_active,
    start_date,
    location,
    is_sms,
  }) => {
    let attachments = [];
    if (files) {
      await Promise.resolve(
        files.then(value => {
          attachments = value;
        }),
      );
    }
    const data = {
      name,
      description,
      gm_pl,
      gm_salj,
      customer_pl,
      organization: customerId,
      location: location?.value,
      project_type: projectType,
      attachments: [...attachments, ...prevFiles],
      deadline: deadline && moment(deadline).format('YYYY-MM-DD'),
      start_date: start_date && moment(start_date).format('YYYY-MM-DD'),
      is_active: is_active || false,
      is_sms,
    };

    if (formType === PRIVATE_ROUTE.NEW) {
      let path = '';
      if (
        currentUser.group === 'customer-projektledere' ||
        currentUser.group === 'customer-admin'
      ) {
        path = `/dashboard/${PRIVATE_ROUTE.PROJECTS}`;
      } else {
        path = `/dashboard/${PRIVATE_ROUTE.CUSTOMERS}/${data.organization}/`;
      }
      dispatch(
        addNewProjectRequest({
          data,
          resetForm: form.resetFields,
          succMessage: intl.messages['Project Successfully Created!'],
          history,
          path,
        }),
      );
    }
    if (formType === PRIVATE_ROUTE.EDIT) {
      dispatch(
        updateProjectRequest({
          formData: data,
          id: info.id,
          resetForm: form.resetFields,
          succMessage: intl.messages['Updated successfully!'],
          history,
          // path:''
        }),
      );
    }
  };

  const handleChange = data => {
    setLoadMap(false);
    setCloseMarker(false);
    setTimeout(() => {
      const singleData = optionData.find(o => o.id === Number(data?.value));
      // const singleData = optionData.find(o => o.id === parseInt(value, 10));
      if (singleData) {
        setMapPoint(singleData.point);
        setLoadMap(true);
        setCloseMarker(true);
      }
    }, 100);
  };

  const handleSearch = value => {
    if (value) {
      dispatch(
        getAllFieldsRequest({
          limit: 10,
          offset: 0,
          name: value,
          organization: customerId,
          pagination_off: true,
        }),
      );
    } else {
      setOptionData(optionData);
    }
  };
  if (!showForm)
    return (
      <div className="example">
        <Spin />
      </div>
    );
  const options = optionData?.map(d => <Option key={d.id}>{d.name}</Option>);

  return (
    <>
      <NewField
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
        action="create"
        fieldInfo={null}
      />
      <Form
        name="add_new_project"
        onFinish={onFinish}
        labelCol={{ span: 24 }}
        form={form}
        validateMessages={getValidateMessages(intl.messages)}
      >
        <div className="customer_section">
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label={intl.messages['Name']}
                name="name"
                rules={[{ required: true }]}
                hasFeedback
              >
                <Input placeholder={intl.messages['Name']} size="large" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={intl.messages['Fields']}
                name="location"
                rules={[{ required: true }]}
                hasFeedback
              >
                <Select
                  showSearch
                  defaultActiveFirstOption={false}
                  showArrow={false}
                  filterOption={false}
                  onSearch={handleSearch}
                  onChange={handleChange}
                  notFoundContent={null}
                  placeholder={intl.messages['Fields']}
                  labelInValue
                  size="large"
                  dropdownRender={menu => (
                    <div>
                      {menu}
                      <Divider style={{ margin: '4px 0' }} />
                      <div
                        style={{
                          display: 'flex',
                          flexWrap: 'nowrap',
                        }}
                      >
                        <Button
                          type="link"
                          size="small"
                          onClick={() => setIsModalVisible(true)}
                        >
                          <PlusOutlined />
                          Add Fields
                        </Button>
                      </div>
                    </div>
                  )}
                >
                  {options}
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Form.Item>
            <div style={{ width: '100%', height: '35vh' }}>
              {loadMap && (
                <MapForView
                  point={point}
                  googleMapURL="https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=AIzaSyBYfmYnzkWO8iuc0jCrSJh1Gg7miz7EFh8"
                  loadingElement={<div style={{ height: '100%' }} />}
                  containerElement={<div style={{ height: '100%' }} />}
                  mapElement={<div style={{ height: '100%' }} />}
                  closeMarker={closeMarker}
                />
              )}
            </div>
          </Form.Item>

          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label={intl.messages['Description']}
                name="description"
                rules={[
                  {
                    required: true,
                  },
                ]}
                hasFeedback
              >
                <TextArea
                  placeholder={intl.messages['Description']}
                  size="large"
                  rows={4}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                getValueFromEvent={normFile}
                valuePropName="filelist"
                label={intl.messages['Attachments']}
                name="files"
                rules={[
                  {
                    required: false,
                  },
                ]}
                hasFeedback
              >
                <Dragger
                  // eslint-disable-next-line no-unused-vars
                  customRequest={({ _, onSuccess }) => {
                    setTimeout(() => {
                      onSuccess('ok');
                    }, 0);
                  }}
                  multiple
                >
                  <p className="ant-upload-drag-icon">
                    <UploadOutlined style={{ fontSize: 18 }} />
                  </p>
                  <p className="ant-upload-text">
                    <FormattedMessage id="Drag files here to upload" />
                    <br />
                    <FormattedMessage id="Max size of image is 10 MB" />
                  </p>
                </Dragger>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={6}>
              <Form.Item
                label={intl.messages['Start Date']}
                name="start_date"
                rules={[{ required: true }]}
                hasFeedback
              >
                <DatePicker
                  placeholder={intl.messages['Start Date']}
                  size="large"
                  style={{ width: '100%' }}
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label={intl.messages['Deadline']}
                name="deadline"
                rules={[{ required: true }]}
                hasFeedback
              >
                <DatePicker
                  placeholder={intl.messages['Deadline']}
                  size="large"
                  style={{ width: '100%' }}
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item label={intl.messages['Status']} name="is_active">
                <Select
                  placeholder={intl.messages['Select Status']}
                  size="large"
                >
                  <Option value>
                    <FormattedMessage id="Active" />
                  </Option>
                  <Option value={false}>
                    <FormattedMessage id="Inactive" />
                  </Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item label={intl.messages['SMS']} name="is_sms">
                <Select placeholder={intl.messages['Select SMS']} size="large">
                  <Option value={1}>
                    <FormattedMessage id="Active" />
                  </Option>
                  <Option value={0}>
                    <FormattedMessage id="Inactive" />
                  </Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <div
            className="ant-card-head"
            style={{ padding: 0, marginBottom: '15px' }}
          >
            <div className="ant-card-head-title">
              {intl.messages['Accountable']}
            </div>
          </div>
          <Row gutter={16}>
            <ShowForPermission permission={['can_assign_gm_roles_in_project']}>
              <Col span={16}>
                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item
                      label={intl.messages['GM Project Leader']}
                      name="gm_pl"
                      rules={[{ required: false }]}
                      hasFeedback
                    >
                      <Select
                        placeholder={intl.messages['GM Project Leader']}
                        size="large"
                      >
                        {gmProjectLeader &&
                          gmProjectLeader.length > 0 &&
                          gmProjectLeader.map(user => (
                            <Option value={user.id} key={user.id}>
                              {`${user.first_name} ${user.last_name}`}
                            </Option>
                          ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      label={intl.messages['GM Sales']}
                      name="gm_salj"
                      rules={[{ required: false }]}
                      hasFeedback
                    >
                      <Select
                        placeholder={intl.messages['GM Sales']}
                        size="large"
                      >
                        {gmSalij &&
                          gmSalij.length > 0 &&
                          gmSalij.map(user => (
                            <Option value={user.id} key={user.id}>
                              {`${user.first_name} ${user.last_name}`}
                            </Option>
                          ))}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
            </ShowForPermission>
            <Col span={8}>
              <Form.Item
                label={intl.messages['Customer Project Leader']}
                name="customer_pl"
                rules={[{ required: false }]}
                hasFeedback
              >
                <Select
                  placeholder={intl.messages['Customer Project Leader']}
                  size="large"
                >
                  {gmCustomerProjectLeader &&
                    gmCustomerProjectLeader.length > 0 &&
                    gmCustomerProjectLeader.map(user => (
                      <Option value={user.id} key={user.id}>
                        {`${user.first_name} ${user.last_name}`}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row className="mt-2" type="flex" justify="center" gutter="70">
            <Col>
              <Form.Item>
                <Button size="medium" onClick={() => history.goBack()}>
                  <FormattedMessage id="Cancel" />
                </Button>
              </Form.Item>
            </Col>
            <Form.Item>
              <Button size="medium" type="primary" htmlType="submit">
                <FormattedMessage
                  id={formType === PRIVATE_ROUTE.NEW ? 'Save' : 'Update'}
                />
              </Button>
            </Form.Item>
            <Col />
          </Row>
        </div>
      </Form>
    </>
  );
};

ProjectForm.propTypes = {
  projectInfo: PropTypes.shape({}),
};

export default ProjectForm;
