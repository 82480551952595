import { http } from 'services';
import { getHeader } from 'lib/helpers';
import { orgsUrl, userRegUrl, orgsUserUrl } from './endpoints';

export const Orgs = {
  index: () =>
    http.get(orgsUrl, {
      headers: getHeader(),
    }),
  create: data =>
    http.post(orgsUrl, data, {
      headers: getHeader(true),
    }),
  update: (data, id) =>
    http.put(`${orgsUrl}${id}/`, data, {
      headers: getHeader(true),
    }),
  register: data =>
    http.post(userRegUrl, data, {
      headers: getHeader(),
    }),
  getSingleById: id =>
    http.get(`${orgsUrl}${id}/`, {
      headers: getHeader(true),
    }),
  orgsState: () =>
    http.get(`${orgsUrl}stats/`, {
      headers: getHeader(true),
    }),
  users: id =>
    http.get(`${orgsUserUrl}${id}/`, {
      headers: getHeader(),
    }),
};
