import { Calendar } from 'antd';
import styled from 'styled-components';
import { shade } from 'polished';

const AntCalendar = styled(Calendar)`
  .ant-picker-calendar-header {
    .ant-radio-button-wrapper:last-child {
      display: none;
    }
  }

    li {
      margin-bottom: 8px;
      .draft {
        .ant-badge-status-success {
          background-color: ${props => shade(0.2, props.theme.grey)};
        }
      }
      .delayed {
        .ant-badge-status-success {
          background-color: ${props => shade(0.2, props.theme.orange)};
        }
      }
      .completed {
        .ant-badge-status-success {
          background-color: ${props => shade(0.2, props.theme.green)};
        }
      }
      .in-progress {
        .ant-badge-status-success {
          background-color: ${props => shade(0.2, props.theme.yellow)};
        }
      }
      .ant-badge-status-dot {
        width: 10px;
        height: 10px;
      }
 
      
    }
  }
`;

export default AntCalendar;
