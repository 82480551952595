import React, { useEffect } from 'react';
import { useParams, useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import {
  getAllOrderProjectsRequest,
  getAllMaintenanceProjectsRequest,
} from 'features/Projects/reducer';
// import { getItemFromUrl, OrgTypes } from 'lib/helpers/utility';
import { getItemFromUrl, OrgTypes } from 'components/utility/CustomeFunc';
import { PRIVATE_ROUTE } from 'router';
import OrgDetailsCountCard from './OrgDetailsCountCard';
import OrderMaintanenceList from './OrderMaintanenceList';

function getProejctList(list) {
  let projectList = [];
  if (list && list.results && list.results.length > 0) {
    projectList = list.results.map(item => {
      return { key: item.id, ...item };
    });
  }
  return projectList;
}
function getProjectCount(list) {
  let count = 0;
  if (list && list.results && list.results.length > 0) {
    count = list.count;
  }
  return count;
}
const OrderMaintanence = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  let orgType = '';
  const orgsType = getItemFromUrl(history, OrgTypes);
  if (orgsType === PRIVATE_ROUTE.SUPPLIERS) {
    orgType = 'supplier';
  } else {
    orgType = 'customer';
  }
  const orderProjectlist = useSelector(
    state => state.Projects.orderProjectlist,
  );
  const maintenanceProjectlist = useSelector(
    state => state.Projects.maintenanceProjectlist,
  );

  useEffect(() => {
    dispatch(
      getAllOrderProjectsRequest({
        limit: 10,
        offset: 0,
        type: 'order',
        status: '',
        orgType,
        orgId: id,
      }),
    );
    dispatch(
      getAllMaintenanceProjectsRequest({
        limit: 10,
        offset: 0,
        type: 'maintenance',
        status: '',
        orgType,
        orgId: id,
      }),
    );
  }, [dispatch]);
  const orderList = getProejctList(orderProjectlist);
  const orderCount = getProjectCount(orderProjectlist);
  // console.log(orderCount);
  const maintenancelist = getProejctList(maintenanceProjectlist);
  const maintenanceCount = getProjectCount(maintenanceProjectlist);
  // console.log(maintenanceCount);
  const changePaginationOrder = pageNo => {
    dispatch(
      getAllOrderProjectsRequest({
        limit: 10,
        offset: 10 * (pageNo - 1),
        type: 'order',
        status: '',
        orgType,
        orgId: id,
      }),
    );
  };
  const changePaginationMaintenance = pageNo => {
    dispatch(
      getAllMaintenanceProjectsRequest({
        limit: 10,
        offset: 10 * (pageNo - 1),
        type: 'maintenance',
        status: '',
        orgType,
        orgId: id,
      }),
    );
  };
  return (
    <>
      <OrgDetailsCountCard
        orderCount={orderCount}
        maintenanceCount={maintenanceCount}
      />
      <OrderMaintanenceList
        orderList={orderList}
        maintenancelist={maintenancelist}
        orderProjectlist={orderProjectlist}
        maintenanceProjectlist={maintenanceProjectlist}
        changePaginationOrder={changePaginationOrder}
        changePaginationMaintenance={changePaginationMaintenance}
      />
    </>
  );
};

export default OrderMaintanence;
