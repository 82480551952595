import React, { useEffect } from 'react';
import { Breadcrumb, Row, Col, Card } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory, useParams } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';

import {
  getItemFromUrl,
  ProjectType,
  FormType,
} from 'components/utility/CustomeFunc';
import { PRIVATE_ROUTE } from 'router';
import { Link } from 'react-router-dom';
import ProjectForm from './AddProject/ProjectForm';
import { getSingleProjectRequest } from '../reducer';

const ProjectAndSteps = () => {
  const history = useHistory();
  // const info = useSelector(state => state.OrgsDetails.orgDetails);
  const intl = useIntl();
  const { projectId } = useParams();
  const dispatch = useDispatch();
  const projectInfo = useSelector(state => state.Projects.info);
  const projectType = getItemFromUrl(history, ProjectType);
  const formType = getItemFromUrl(history, FormType);

  useEffect(() => {
    if (projectId) {
      dispatch(getSingleProjectRequest({ id: projectId }));
    }
  }, [dispatch]);

  return (
    <>
      <h2 className="db-page-title">
        {formType === PRIVATE_ROUTE.NEW && (
          <FormattedMessage id={`Create an ${projectType}`} />
        )}
        {formType === PRIVATE_ROUTE.EDIT && (
          <FormattedMessage id={`Update the ${projectType}`} />
        )}
        <Breadcrumb>
          <Breadcrumb.Item>
            {projectInfo?.organization ? (
              <Link
                to={`/dashboard/customers/${projectInfo?.organization?.id}/`}
              >
                {projectInfo?.organization?.name}
              </Link>
            ) : (
              projectInfo &&
              projectInfo.organization && (
                <Link
                  to={`/dashboard/customers/${projectInfo.organization.id}/`}
                >
                  {projectInfo.organization.name}
                </Link>
              )
            )}
          </Breadcrumb.Item>
          <Breadcrumb.Item>{projectType}</Breadcrumb.Item>
        </Breadcrumb>
      </h2>
      <Row gutter={16}>
        <Col span={24}>
          <Card title={intl.messages['Order Basics']}>
            <ProjectForm projectInfo={projectInfo} />
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default ProjectAndSteps;
