import React, { useEffect, useState } from 'react';
import { Badge, Card, Popover } from 'antd';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import AntCalendar from './Calendar.styles';
import { getCalendarStepsRequest } from './reducer';
import PopOverCard from './PopOverCard';

function Calender() {
  const list = useSelector(state => state.Calendar.calendar);
  const dispatch = useDispatch();
  const [stepInfo, setstepInfo] = useState(null);
  const initMonth = moment().month() + 1;
  const initYear = moment().year();
  const [calendarYear, setCalendarYear] = useState(initYear);
  const [calendarMonth, setCalendarMonth] = useState(initMonth);

  function getListData(value) {
    let listData = [];
    list.map(item => {
      if (
        value.month() + 1 === calendarMonth &&
        value.date() === moment(item.deadline).date()
      ) {
        listData.push({
          name: item.name,
          status: item.status,
          customer: item.owner,
          stepsId: item.id,
          projectId: item.project,
        });
        listData = [...listData];
      }
      return listData;
    });
    return listData || [];
  }

  function dateCellRender(value) {
    const listData = getListData(value);
    return (
      <ul className="steps_items">
        {listData.map(item => (
          <li key={item.stepsId}>
            <Popover content={<PopOverCard item={item} />}>
              <Badge
                className={item.status}
                status="success"
                text={item.name}
              />
            </Popover>
          </li>
        ))}
      </ul>
    );
  }

  function onPanelChange(value) {
    setCalendarYear(value.year());
    setCalendarMonth(value.month() + 1);
    dispatch(
      getCalendarStepsRequest({
        year: value.year(),
        month: value.month() + 1,
        setstepInfo,
      }),
    );
  }

  useEffect(() => {
    dispatch(
      getCalendarStepsRequest({
        year: calendarYear,
        month: calendarMonth,
        setstepInfo,
      }),
    );
  }, [dispatch]);

  return (
    <Card>
      {stepInfo && (
        <AntCalendar
          dateCellRender={dateCellRender}
          onPanelChange={onPanelChange}
        />
      )}
    </Card>
  );
}

export default Calender;
