import { http } from 'services';
import { getHeader } from 'lib/helpers';
import { calendarUrl } from './endpoints';

export const Calendar = {
  index: (year, month) =>
    http.get(`${calendarUrl}?year=${year}&month=${month}`, {
      headers: getHeader(),
    }),
};
