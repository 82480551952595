import { http } from 'services';
import { getHeader } from 'lib/helpers';
import { channelUrl, chatUrl } from './endpoints';

export const Message = {
  index: () =>
    http.get(channelUrl, {
      headers: getHeader(),
    }),
  channelCreate: data =>
    http.post(channelUrl, data, {
      headers: getHeader(),
    }),
  getChats: (id, page) =>
    http.get(`${channelUrl}get-all/?user=${id}&page=${page}`, {
      headers: getHeader(),
    }),
  getChatUsers: searchString =>
    http.get(`${chatUrl}get-users/?q=${searchString}`, {
      headers: getHeader(),
    }),
  sendMesg: data =>
    http.post(`${chatUrl}`, data, {
      headers: getHeader(),
    }),
  readAll: data =>
    http.post(`${channelUrl}read-all/`, data, {
      headers: getHeader(),
    }),
  unreadMesg: () =>
    http.get(`${chatUrl}get-unread-count/`, {
      headers: getHeader(),
    }),
};
