import React, { useEffect } from 'react';
import { Form, Button, Card, Row, Col, DatePicker, Select } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import getValidateMessages from 'lib/helpers/validators';
import { getObjectTrackingReq, getAllFieldListRequest } from './reducer';

const { Option } = Select;
const dateFormat = 'YYYY/MM/DD';

function ReportFilter() {
  const [form] = Form.useForm();
  const intl = useIntl();
  const dispatch = useDispatch();
  const results = useSelector(state => state.Reports.fieldlist);

  useEffect(() => {
    dispatch(getAllFieldListRequest({ pagination_off: true }));
  }, []);

  const onFinish = values => {
    values['from_date'] = moment(values['from_date']).format('YYYY-MM-DD');
    values['to_date'] = moment(values['to_date']).format('YYYY-MM-DD');
    dispatch(getObjectTrackingReq(values));
  };

  const fieldOptions = results?.map(result => (
    <Option key={result?.id} value={result?.id}>
      {result?.name}
    </Option>
  ));

  return (
    <Card
      style={{ marginTop: '24px', borderRadius: '10px' }}
      bodyStyle={{ padding: '24px' }}
    >
      <Form
        form={form}
        name="horizontal_login"
        layout="vertical"
        onFinish={onFinish}
        validateMessages={getValidateMessages(intl.messages)}
      >
        <Row gutter={16}>
          <Col md={6}>
            <Form.Item
              label={intl.messages['Field']}
              name="locationId"
              rules={[{ required: true }]}
            >
              <Select
                placeholder={intl.messages['Select a field']}
                size="large"
                showSearch
                allowClear
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
              >
                {fieldOptions}
              </Select>
            </Form.Item>
          </Col>
          <Col md={6}>
            <Form.Item
              label={intl.messages['Start Date']}
              name="from_date"
              rules={[{ required: true }]}
            >
              <DatePicker
                size="large"
                format={dateFormat}
                style={{ width: '100%' }}
              />
            </Form.Item>
          </Col>
          <Col md={6}>
            <Form.Item
              label={intl.messages['End Date']}
              name="to_date"
              rules={[{ required: true }]}
            >
              <DatePicker
                size="large"
                format={dateFormat}
                style={{ width: '100%' }}
              />
            </Form.Item>
          </Col>
          <Col md={6}>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                style={{ width: '100%', marginTop: '33px' }}
              >
                <FormattedMessage id="Submit" />
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Card>
  );
}

export default ReportFilter;
