import { http } from 'services';
import { getHeader } from 'lib/helpers';
import {
  fieldUrl,
  maintenanceUrl,
  alarmsUrl,
  alarmsObjectsUrl,
} from './endpoints';

export const Fields = {
  index: (limit, offset, name, organization, pagination_off) =>
    http.get(
      `${fieldUrl}?${
        pagination_off ? `pagination_off` : `limit=${limit}&offset=${offset}`
      }${name ? `&name=${name}` : ''}${
        organization ? `&organization=${organization}` : ''
      }
      `,
      {
        headers: getHeader(),
      },
    ),
  create: data =>
    http.post(fieldUrl, data, {
      headers: getHeader(),
    }),
  update: (id, data) =>
    http.put(`${fieldUrl}${id}/`, data, {
      headers: getHeader(),
    }),
  getDetailsById: id =>
    http.get(`${fieldUrl}${id}/`, {
      headers: getHeader(),
    }),
  createMaintenance: data =>
    http.post(maintenanceUrl, data, {
      headers: getHeader(),
    }),
  getMaintenance: (limit, offset, location) =>
    http.get(
      `${maintenanceUrl}?limit=${limit}&offset=${offset}&location_id=${location}`,
      {
        headers: getHeader(),
      },
    ),
  updateMaintenance: (id, data) =>
    http.put(`${maintenanceUrl}${id}/`, data, {
      headers: getHeader(),
    }),
  createAlarms: data =>
    http.post(alarmsUrl, data, {
      headers: getHeader(),
    }),
  getAlarms: (limit, offset, location) =>
    http.get(
      `${alarmsUrl}?limit=${limit}&offset=${offset}&location_id=${location}`,
      {
        headers: getHeader(),
      },
    ),
  updateAlarms: (id, data) =>
    http.put(`${alarmsUrl}${id}/`, data, {
      headers: getHeader(),
    }),
  getAlarmsObjects: () =>
    http.get(`${alarmsObjectsUrl}`, {
      headers: getHeader(),
    }),
  checkSaimosServer: id =>
    http.get(`${fieldUrl}${id}/saimos-server-status/`, {
      headers: getHeader(),
    }),
  fileDelete: (location_pk, file_pk) =>
    http.get(`${fieldUrl}attachment/delete/${location_pk}/${file_pk}/`, {
      headers: getHeader(),
    }),
};
