import { createSlice, createAction } from '@reduxjs/toolkit';

const initState = { idToken: null };

const authSlice = createSlice({
  name: 'auth',
  initialState: initState,
  reducers: {
    login(state, action) {
      state.idToken = action.payload;
    },
    logout(state) {
      state.idToken = null;
    },
  },
});

export const loginRequest = createAction('auth/login_request');
export const forgotPasswordRequest = createAction(
  'auth/forgot_password_request',
);
export const { login, logout } = authSlice.actions;

export default authSlice;
