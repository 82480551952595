import React, { useEffect, useState } from 'react';
// import moment from 'moment';
// import Table from 'components/uielements/table';
// import { Tag, Tooltip } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
// import { Pagination } from 'components/uielements';
import { useLocation } from 'react-router-dom';
// import { PRIVATE_ROUTE } from 'router';
// import { ClockCircleOutlined } from '@ant-design/icons';
// import { useIntl } from 'react-intl';
import { getAllProjectsRequest } from '../reducer';
import ProjectTable from './Table';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}
function ProjectList() {
  // const { qStatus, qType } = useParams();
  // const { messages } = useIntl();
  const query = useQuery();
  //  console.log(query.get('type'));
  const qType = query.get('type');
  const qStatus = query.get('status');
  // console.log(query.get('status'));
  const dispatch = useDispatch();
  const [offset, setoffset] = useState(0);
  const [type, setType] = useState('');
  const [status, setStatus] = useState('');
  const { results, count } = useSelector(state => state.Projects.list);
  useEffect(() => {
    if (qType || qStatus) {
      dispatch(
        getAllProjectsRequest({
          limit: 10,
          offset: 0,
          type: qType,
          status: qStatus,
        }),
      );
    } else {
      dispatch(
        getAllProjectsRequest({
          limit: 10,
          offset: 10 * offset,
          type,
          status,
        }),
      );
    }
  }, [dispatch, qType, qStatus]);
  const changePagination = pageNo => {
    console.log(pageNo);

    if (qType || qStatus) {
      dispatch(
        getAllProjectsRequest({
          limit: 10,
          offset: 0,
          type: qType,
          status: qStatus,
        }),
      );
    } else {
      dispatch(
        getAllProjectsRequest({
          limit: 10,
          offset: 10 * (pageNo - 1),
          type,
          status,
        }),
      );
    }
    setoffset(pageNo);
  };
  // let data = [];
  // if (results && results.length > 0) {
  //   data = results.map(item => {
  //     return { key: item.id, ...item };
  //   });
  // }
  // console.log(data);
  const getFilterData = (pagination, filters, sorter) => {
    console.log(sorter);
    const prType =
      filters.project_type && filters.project_type.length > 0
        ? filters.project_type[0]
        : '';
    const prStatus =
      filters.status && filters.status.length > 0 ? filters.status[0] : '';
    setType(prType);
    setStatus(prStatus);
    setoffset(0);
    dispatch(
      getAllProjectsRequest({
        limit: 10,
        offset: 0,
        type: prType,
        status: prStatus,
      }),
    );
  };
  // const columns = [
  //   {
  //     title: messages['Name'],
  //     dataIndex: 'name',
  //     width: 200,
  //     render: (name, record) => {
  //       return (
  //         <Link
  //           to={{
  //             pathname: `/dashboard/${PRIVATE_ROUTE.PROJECTS}/${record.project_type}/${record.id}/`,
  //             state: { projectType: record.project_type },
  //           }}
  //         >
  //           {record.name}
  //         </Link>
  //       );
  //     },
  //   },
  //   {
  //     title: messages['Customer'],
  //     dataIndex: 'customer',
  //     width: 200,
  //     render: (name, record) => {
  //       return (
  //         <Link
  //           to={{
  //             pathname: `/dashboard/${PRIVATE_ROUTE.PROJECTS}/${record.project_type}/${record.id}/`,
  //             state: { projectType: record.project_type },
  //           }}
  //         >
  //           {record && record.organization && record.organization.name}
  //         </Link>
  //       );
  //     },
  //   },
  //   {
  //     title: messages['Created By'],
  //     dataIndex: 'creator_role',
  //     width: 200,
  //     render: creator_role => {
  //       return <span>{creator_role}</span>;
  //     },
  //   },
  //   {
  //     title: messages['project_type'],
  //     dataIndex: 'project_type',
  //     width: 200,
  //     render: (name, record) => {
  //       const ProjectType =
  //         record.project_type === 'order' ? 'Order' : 'Maintenance';
  //       return (
  //         <Link
  //           to={{
  //             pathname: `/dashboard/${PRIVATE_ROUTE.PROJECTS}/${record.project_type}/${record.id}/`,
  //             state: { projectType: record.project_type },
  //           }}
  //         >
  //           {ProjectType}
  //         </Link>
  //       );
  //     },
  //     filterMultiple: false,
  //     filters: [
  //       {
  //         text: 'Maintenance',
  //         value: 'maintenance',
  //       },
  //       {
  //         text: 'Order',
  //         value: 'order',
  //       },
  //     ],
  //   },
  //   {
  //     title: messages['Status'],
  //     dataIndex: 'status',
  //     width: 200,
  //     render: statusV => {
  //       let color = 'default';
  //       if (statusV === 'completed') color = 'green';
  //       else if (statusV === 'in-progress') color = 'gold';
  //       else if (statusV === 'delayed') color = 'orange';
  //       return <Tag color={color}>{statusV.toUpperCase()}</Tag>;
  //     },
  //     filterMultiple: false,
  //     filters: [
  //       {
  //         text: 'Draft',
  //         value: 'draft',
  //       },
  //       {
  //         text: 'In-progress',
  //         value: 'in-progress',
  //       },
  //       {
  //         text: 'Completed',
  //         value: 'completed',
  //       },
  //       {
  //         text: 'Delayed',
  //         value: 'delayed',
  //       },
  //     ],
  //   },
  //   {
  //     title: messages['ActiveSTEP'],
  //     dataIndex: 'activeSTEP',
  //     width: 150,
  //     render: (name, record) => {
  //       return (
  //         <Link
  //           to={{
  //             pathname: `/dashboard/${PRIVATE_ROUTE.PROJECTS}/${record.project_type}/${record.id}/`,
  //             state: { projectType: record.project_type },
  //           }}
  //         >
  //           <Tooltip title={record.active_step && record.active_step.name}>
  //             {(record.active_step &&
  //               record.active_step.order &&
  //               `${record.active_step.order}/${record.steps.length}`) ||
  //               'N/A'}
  //           </Tooltip>
  //         </Link>
  //       );
  //     },
  //   },
  //   {
  //     title: messages['Deadline'],
  //     dataIndex: 'deadline',
  //     width: 200,
  //     render: (name, record) => {
  //       return (
  //         <Link
  //           to={{
  //             pathname: `/dashboard/${PRIVATE_ROUTE.PROJECTS}/${record.project_type}/${record.id}/`,
  //             state: { projectType: record.project_type },
  //           }}
  //         >
  //           {record.deadline ? record.deadline : 'N/A'}
  //         </Link>
  //       );
  //     },
  //   },
  //   {
  //     // title: 'Running Step',
  //     title: messages['Running Step'],
  //     dataIndex: 'running_step',
  //     width: 200,
  //     render: (name, record) => {
  //       return (
  //         <Link
  //           to={{
  //             pathname: `/dashboard/${PRIVATE_ROUTE.PROJECTS}/${record.id}/${
  //               PRIVATE_ROUTE.STEPS
  //             }/${record.active_step && record.active_step.id}`,
  //             state: { projectType: record.project_type },
  //           }}
  //         >
  //           {record.active_step ? record.active_step?.name : 'N/A'}
  //           {record.active_step && record.active_step.is_delayed && (
  //             <Tag color="red" style={{ marginLeft: 5 }}>
  //               Delayed
  //             </Tag>
  //           )}
  //         </Link>
  //       );
  //     },
  //   },
  // ];
  // console.log(offset);
  return (
    <div>
      <ProjectTable
        results={results}
        count={count}
        getFilterData={getFilterData}
        changePagination={changePagination}
      />
      {/* <Table
        columns={columns}
        dataSource={data}
        pagination={false}
        onChange={getFilterData}
        footer={() => (
          <div style={{ textAlign: 'center' }}>
            <Pagination
              defaultCurrent={offset}
              total={count}
              onChange={changePagination}
            />
          </div>
        )}
      /> */}
    </div>
  );
}

export default ProjectList;
