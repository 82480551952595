import React from 'react';
import { Form, Input, Button, Row, Col } from 'antd';
import { useIntl, FormattedMessage } from 'react-intl';
import getValidateMessages from 'lib/helpers/validators';
import { useHistory } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import { updatePasswordRequest } from './reducer';

function UserSettingsChangePassForm() {
  const { messages } = useIntl();
  const history = useHistory();
  const { id } = useSelector(state => state.CurrentUser.info);
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const onFinish = values => {
    dispatch(
      updatePasswordRequest({
        formData: values,
        id,
        successMsg: `${messages['Password']} ${messages['Updated successfully!']}`,
        resetFields: form.resetFields,
      }),
    );
  };

  return (
    <Form
      className="pl-2 pt-2 pb-2 pr-2"
      name="change_password"
      onFinish={onFinish}
      labelCol={{ span: 24 }}
      validateMessages={getValidateMessages(messages)}
      form={form}
    >
      <Form.Item
        label={messages['Old password']}
        name="current_password"
        rules={[
          {
            required: true,
          },
          {
            min: 8,
          },
        ]}
        hasFeedback
      >
        <Input.Password placeholder={messages['Old password']} size="large" />
      </Form.Item>
      <Form.Item
        label={messages['New password']}
        name="new_password"
        rules={[
          {
            required: true,
          },
          {
            min: 8,
          },
          ({ getFieldValue }) => ({
            validator(rule, value) {
              if (!value || getFieldValue('current_password') !== value) {
                return Promise.resolve();
              }
              return Promise.reject(
                messages['New Password cant be same as old password!'],
              );
            },
          }),
        ]}
        hasFeedback
      >
        <Input.Password placeholder={messages['New password']} size="large" />
      </Form.Item>
      <Form.Item
        label={messages['Repeat password']}
        name="confirm_password"
        hasFeedback
        rules={[
          {
            required: true,
          },
          {
            min: 8,
          },
          ({ getFieldValue }) => ({
            validator(rule, value) {
              if (!value || getFieldValue('new_password') === value) {
                return Promise.resolve();
              }
              return Promise.reject(
                messages['The two passwords that you entered do not match!'],
              );
            },
          }),
        ]}
      >
        <Input.Password
          placeholder={messages['Repeat password']}
          size="large"
        />
      </Form.Item>
      <Row className="mt-2" type="flex" justify="center" gutter="70">
        <Col>
          <Form.Item>
            <Button onClick={() => history.push('/')} size="large">
              <FormattedMessage id="Cancel" />
            </Button>
          </Form.Item>
        </Col>
        <Form.Item>
          <Button size="large" type="primary" htmlType="submit">
            <FormattedMessage id="Save" />
          </Button>
        </Form.Item>
        <Col />
      </Row>
    </Form>
  );
}

export default UserSettingsChangePassForm;
