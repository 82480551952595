import React, { useEffect } from 'react';
import { Form, Input, Button, Row, Col } from 'antd';
import { useIntl, FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import getValidateMessages from 'lib/helpers/validators';

function EsignFieldEditForm({
  handleModalClose,
  arrIndex,
  updateFormFieldLabel,
  updateFormFieldPlaceholder,
  formFields,
}) {
  const [form] = Form.useForm();
  const intl = useIntl();

  const onFinish = () => {
    handleModalClose();
  };

  const changeLabel = e => {
    updateFormFieldLabel(arrIndex, e);
  };

  const changePlaceholder = e => {
    updateFormFieldPlaceholder(arrIndex, e);
  };

  useEffect(() => {
    if (formFields.length) {
      form.setFieldsValue({
        label: formFields[arrIndex].label,
        placeholder: formFields[arrIndex].placeholder,
      });
    }
  }, [form, formFields, handleModalClose]);

  return (
    <Form
      name="esign_edit_field"
      onFinish={onFinish}
      labelCol={{ span: 24 }}
      form={form}
      validateMessages={getValidateMessages(intl.messages)}
    >
      <Row gutter={50}>
        <Col span={24}>
          <Form.Item
            label={intl.messages['Edit label']}
            name="label"
            hasFeedback
            onChange={e => changeLabel(e)}
          >
            <Input placeholder={intl.messages['Edit label']} size="large" />
          </Form.Item>
        </Col>
        {formFields[arrIndex].type === 'text' ? (
          <Col span={24}>
            <Form.Item
              label={intl.messages['Edit placeholder']}
              name="placeholder"
              hasFeedback
              onChange={e => changePlaceholder(e)}
            >
              <Input
                placeholder={intl.messages['Edit placeholder']}
                size="large"
              />
            </Form.Item>
          </Col>
        ) : null}
      </Row>

      <Row className="mt-2" type="flex" justify="center" gutter="70">
        <Col>
          <Form.Item>
            <Button onClick={handleModalClose} size="large">
              <FormattedMessage id="Cancel" />
            </Button>
          </Form.Item>
        </Col>
        <Form.Item>
          <Button size="large" type="primary" htmlType="submit">
            <FormattedMessage id="Done" />
          </Button>
        </Form.Item>
        <Col />
      </Row>
    </Form>
  );
}

EsignFieldEditForm.propTypes = {
  handleModalClose: PropTypes.func,
  arrIndex: PropTypes.number,
  updateFormFieldLabel: PropTypes.func,
  updateFormFieldPlaceholder: PropTypes.func,
  // eslint-disable-next-line
  formFields: PropTypes.array,
};

export default EsignFieldEditForm;
