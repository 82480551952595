const theme = {
  primary: '#3FB65F',
  secondary: '#454D66',
  orange: '#FF8311',
  bg: '#F0F2F5',
  shadow: '0px 8px 16px rgba(109, 109, 109, 0.08)',
  shadowLight: '0px 8px 14px rgba(29, 48, 33, 0.03)',
  shadowLong: '0px 6px 8px rgba(0, 0, 0, 0.03)',
  shadowRight: '6px 0px 8px rgba(0, 0, 0, 0.03)',
  border: '#E9E9E9',
  grey: '#6D7986',
  greyLight: '#BEC9D7',
  error: '#FB4E4E',
  warning: '#F6A609',
  success: '#3FB65F',
  green: '#3FB65F',
  yellow: '#FFA858',
};

export default theme;
