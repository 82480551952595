import { http } from 'services';
import { getHeader, getToken } from 'lib/helpers';
import { currentUserUrl, usersUrl } from './endpoints';

export const CurrentUser = {
  index: () =>
    http.get(`${currentUserUrl}${getToken()}/`, {
      headers: getHeader(),
    }),
  update: (id, data) =>
    http.put(`${usersUrl}${id}/`, data, {
      headers: getHeader(true),
    }),
};
