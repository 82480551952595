import styled from 'styled-components';
import { tint, shade } from 'polished';
import bgImage from './assets/login-bg.webp';

const UserAuthWrapper = styled.div`
  width: 100%;
  min-height: 100vh;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  background: url(${bgImage}) center / cover no-repeat;

  a {
    color: ${props => tint(0.5, props.theme.secondary)};
    font-weight: normal;
  }

  h3 {
    color: ${props => shade(0.5, props.theme.primary)};
  }
`;

export default UserAuthWrapper;
