import React, { useEffect } from 'react';
import { Row } from 'antd';
import { PlusCircleOutlined } from '@ant-design/icons';
import { OrgCard, AddOrgCard } from 'features/Organizations';
import { useDispatch, useSelector } from 'react-redux';
import { ShowForPermission } from 'components/utility';
import { getAllOrgsRequest } from '../reducer';

function SupplierList() {
  const dispatch = useDispatch();
  const list = useSelector(state => state.Orgs.organizations);
  useEffect(() => {
    dispatch(getAllOrgsRequest());
  }, [dispatch]);

  return (
    <Row gutter={[24, 24]}>
      <ShowForPermission
        permission={['can_add_new_customer', 'can_add_new_supplier']}
      >
        <div style={{ minWidth: '293px', margin: '12px' }}>
          <AddOrgCard
            title="Add supplier"
            url="suppliers/new"
            icon={<PlusCircleOutlined />}
          />
        </div>
      </ShowForPermission>
      {list
        .filter(t => t.org_type === 'supplier')
        .map(org => (
          <div
            key={org.id}
            style={{ minWidth: '293px', maxWidth: '293px', margin: '12px' }}
          >
            <OrgCard org={org} />
          </div>
        ))}
    </Row>
  );
}

export default SupplierList;
