import styled from 'styled-components';
import { tint } from 'polished';

const MessageWrapper = styled.div`
  margin: 20px 0 0;
  border: 1px solid ${props => tint(0.8, props.theme.secondary)};
  .message_top {
    background: ${props => tint(0.9, props.theme.secondary)};
    .ant-col-6 {
      padding: 25px 0 25px 30px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .message_title {
      font-weight: 500;
      font-size: 16px;
      line-height: 155%;
      color: #454d66;
    }
    .ant-btn {
      height: 3rem;
      .anticon {
        margin-right: 10px;
      }
    }
  }
`;

export const MessageSidebarWrapper = styled.div`
  border-right: 1px solid ${props => tint(0.8, props.theme.secondary)};
  .search_user {
    .ant-select-selector {
      height: 40px;
      background: transparent;
      border-radius: 0 !important;
      &:hover {
        border-color: ${props => tint(0.8, props.theme.secondary)};
      }
      .ant-select-selection-search-input {
        background: inherit;
      }
      .ant-select-selection-placeholder {
        line-height: 36px;
      }
    }
  }
  .ant-list-items {
    height: calc(100vh - 290px);
    overflow-x: hidden;
    overflow-y: scroll;
    .ant-list-item {
      cursor: pointer;
      background: #fff;
      border-bottom: 1px solid ${props => tint(0.8, props.theme.secondary)};
      padding: 30px 10px 30px 10px;
      .ant-avatar {
        height: 50px;
        width: 50px;
        border-radius: 50%;
        border: 1px solid ${props => tint(0.8, props.theme.secondary)};
      }
      .ant-list-item-meta-title {
        color: ${props => tint(0.2, props.theme.secondary)};
        font-weight: 500;
        font-size: 14px;
      }
      .ant-list-item-meta-description {
        color: ${props => tint(0.6, props.theme.secondary)};
      }
      .date {
        font-size: 12px;
        color: ${props => tint(0.5, props.theme.secondary)};
      }
    }
  }
`;

export const MessageViewWrapper = styled.div`
  .message_view_top {
    .ant-list-item {
      background: #fff;
      padding: 25px 30px;
      .ant-avatar {
        height: 50px;
        width: 50px;
        border-radius: 50%;
        border: 1px solid ${props => tint(0.8, props.theme.secondary)};
      }
      .ant-list-item-meta-title {
        color: ${props => tint(0.2, props.theme.secondary)};
        font-weight: 500;
        font-size: 16px;
      }
      .ant-list-item-meta-description {
        color: ${props => tint(0.6, props.theme.secondary)};
      }
      .role {
        font-size: 12px;
        color: #fff;
        display: inline-block;
        background: ${props => tint(0.2, props.theme.primary)};
        padding: 4px 15px;
        border-radius: 15px;
      }
    }
  }
  .message_details {
    padding: 0 40px;
  }
  .message_box {
    .ant-list-items {
      height: calc(100vh - 495px);
      display: flex;
      flex-direction: column;
      .ant-list-item {
        width: 100%;
        &:first-child {
          margin-top: auto;
        }
        &.reverse {
          .ant-list-item-meta {
            flex-direction: row-reverse;
          }
          .ant-list-item-meta-avatar {
            margin-right: 0;
            margin-left: 16px;
          }
          .ant-list-item-meta-title {
            text-align: right;
          }
          .ant-list-item-meta-description {
            background: ${props => tint(0.1, props.theme.secondary)};
            color: #fff;
          }
        }
        .ant-avatar {
          height: 50px;
          width: 50px;
          border-radius: 50%;
          border: 1px solid ${props => tint(0.8, props.theme.secondary)};
        }
        .ant-list-item-meta {
          align-items: flex-end;
        }
        .ant-list-item-meta-title {
          color: ${props => tint(0.2, props.theme.secondary)};
          font-weight: 500;
          font-size: 16px;
        }
        .ant-list-item-meta-description {
          color: ${props => tint(0.2, props.theme.secondary)};
          border: 1px solid ${props => tint(0.8, props.theme.secondary)};
          padding: 16px 24px;
          border-radius: 10px 10px 0px 0px;
          background: #fff;
        }
      }
    }
  }
  .message_compose {
    position: relative;
    margin: 20px 0 0;
    textarea.ant-input {
      background: transparent;
      border: 1px solid ${props => tint(0.8, props.theme.secondary)};
    }
    .ant-btn {
      position: absolute;
      right: 0;
      bottom: 0;
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      height: 36px;
      width: 75px;
      border-radius: 5px 0 0 0;
    }
  }
`;

export default MessageWrapper;
