import { takeLatest, put, call } from 'redux-saga/effects';
import { CurrentUser, Users } from 'api';
import { message } from 'antd';
import {
  getCurrentUserRequest,
  getCurrentUser,
  updateCurrentUserRequest,
  updatePasswordRequest,
  updateCurrentUser,
} from './reducer';

export function* getCurrentUserSaga() {
  const res = yield call(CurrentUser.index);

  if (res?.data) {
    yield put(getCurrentUser(res.data));
  }
}

export function* updateCurrentUserSaga({
  payload: { formData, id, successMsg },
}) {
  const res = yield call(CurrentUser.update, id, formData);
  if (res?.data) {
    message.success(successMsg);
    yield put(updateCurrentUser(res.data));
  }
}

export function* updatePasswordSaga({
  payload: { formData, id, successMsg, resetFields },
}) {
  const res = yield call(Users.updatePass, id, formData);
  if (res?.data) {
    message.success(successMsg);
    resetFields();
  }
}

export default function* watchCurrentUserActions() {
  yield takeLatest(getCurrentUserRequest.toString(), getCurrentUserSaga);
  yield takeLatest(updateCurrentUserRequest.toString(), updateCurrentUserSaga);
  yield takeLatest(updatePasswordRequest.toString(), updatePasswordSaga);
}
