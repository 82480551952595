import { getToken } from './utility';

const getHeader = (formData = false) => {
  const header = {
    Authorization: `Token ${getToken()}`,
    'Content-type': 'application/json',
  };

  if (formData) {
    header['Content-type'] = 'multipart/form-data';
  }

  return header;
};

export default getHeader;
