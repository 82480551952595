import { createSlice, createAction } from '@reduxjs/toolkit';

const initState = {
  list: [],
  stepList: [],
  timeline: {},
};

const ProjectSLice = createSlice({
  name: 'projects',
  initialState: initState,
  reducers: {
    getAllProjects(state, { payload }) {
      state.list = payload;
    },
    getAllOrderProjects(state, { payload }) {
      state.orderProjectlist = payload;
    },
    getAllMaintenanceProjects(state, { payload }) {
      state.maintenanceProjectlist = payload;
    },
    getSingleProject(state, { payload }) {
      state.info = payload;
    },
    getPrivateFile(state, { payload }) {
      state.privateFile = payload;
    },
    getAllGMProjectLeader(state, { payload }) {
      state.gmProjectLeader = payload;
    },
    getAllGMSalj(state, { payload }) {
      state.gmSalj = payload;
    },
    getAllGMCustomerProjectLeader(state, { payload }) {
      state.gmCustomerProjectLeader = payload;
    },
    getAllStepsOfProject(state, { payload }) {
      state.stepList = payload;
    },
    updateProject(state, { payload }) {
      state.info = {
        ...state.info,
        ...payload,
      };
    },
    getProjectTimeline(state, { payload }) {
      state.timeline = payload;
    },
  },
});

export const getAllProjectsRequest = createAction(
  'projects/get_all_projects_request',
);
export const getAllOrderProjectsRequest = createAction(
  'projects/get_all_order_projects_request',
);
export const getAllMaintenanceProjectsRequest = createAction(
  'projects/get_all_maintenance_projects_request',
);

export const addNewProjectRequest = createAction(
  'projects/add_new_project_request',
);

export const updateProjectRequest = createAction(
  'projects/update_project_request',
);

export const getSingleProjectRequest = createAction(
  'projects/get_single_project_request',
);

export const getPrivateFileRequest = createAction(
  'projects/get_private_file_request',
);

export const deleteProjectsRequest = createAction(
  'projects/delete_project_request',
);

export const deleteProjectFileRequest = createAction(
  'projects/delete_project_file_request',
);

export const getAllGMProjectLeaderRequest = createAction(
  'projects/get_gm_project_leader_request',
);

export const getAllGMSalijRequest = createAction(
  'projects/get_gm_salij_request',
);

export const getAllGMCustomerProjectLeaderRequest = createAction(
  'projects/get_gm_customer_project_leader_request',
);
export const completeProjectRequest = createAction(
  'projects/complete_project_request',
);
export const addRemarksProjectRequest = createAction(
  'projects/remarks_project_request',
);
export const statsProjectRequest = createAction(
  'projects/stats_project_request',
);
export const statsProjectPIRequest = createAction(
  'projects/stats_project_pi_request',
);
export const monthlyCountProjectRequest = createAction(
  'projects/monthly_count_project_request',
);
export const reOrderProjectStepRequest = createAction(
  'projects/re_order_step_project_request',
);
export const getOrderStepRequest = createAction('projects/order_step_request');
export const getProjectTimlineReq = createAction(
  'projects/getProjectTimlineReq',
);

export const {
  getSingleProject,
  getAllProjects,
  getAllOrderProjects,
  getAllMaintenanceProjects,
  getPrivateFile,
  getAllGMProjectLeader,
  getAllGMSalj,
  getAllGMCustomerProjectLeader,
  getAllStepsOfProject,
  updateProject,
  getProjectTimeline,
} = ProjectSLice.actions;

export default ProjectSLice;
