import styled from 'styled-components';
import { Modal } from 'antd';

export const UserModal = styled(Modal)`
  .ant-modal-header {
    border: none;
    border-radius: 0.1rem 0.1rem 0 0;

    .ant-modal-title {
      color: ${props => props.theme.primary};
      font-size: 1.8rem;
    }
  }

  .ant-modal-content {
    padding: 2rem 1.5rem;
  }
`;

export const UserListWrapper = styled.div`
  .ant-tabs {
    background-color: #fff;
    border-radius: 0.5rem;

    .ant-tabs-tab {
      padding: 2.5rem 3rem;
      font-weight: bold;
      font-size: 1.8rem;
      line-height: 155%;
      margin-bottom: 0;
    }

    .ant-tabs-tabpane {
      padding: 4rem;
    }
  }
`;
