import { takeLatest, put, call } from 'redux-saga/effects';
import { Calendar } from 'api';
// import { message } from 'antd';
import { getCalendarStepsRequest, getCalendarSteps } from './reducer';

export function* getCalendarStepsSaga({
  payload: { year, month, setstepInfo },
}) {
  const res = yield call(Calendar.index, year, month);
  if (res.data) {
    setstepInfo(res.data);
    yield put(getCalendarSteps(res.data));
  }
}

export default function* watchCalendarActions() {
  yield takeLatest(getCalendarStepsRequest.toString(), getCalendarStepsSaga);
}
