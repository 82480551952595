import { createSlice, createAction } from '@reduxjs/toolkit';

const initState = {
  unread_count: 0,
  list: [],
  unread_list: [],
};

const NotificationSLice = createSlice({
  name: 'notification',
  initialState: initState,
  reducers: {
    getUnreadCount(state, { payload }) {
      state.unread_count = payload;
    },
    getNotification(state, { payload }) {
      state.list = payload;
    },
    getUnreadNotification(state, { payload }) {
      state.unread_list = payload;
    },
  },
});

export const getUnreadCountReq = createAction(
  'notification/get_unread_count_req',
);

export const getNotificationReq = createAction(
  'notification/get_notification_req',
);

export const readNotificationReq = createAction(
  'notification/read_notification_req',
);

export const readAllNotificationReq = createAction(
  'notification/read_all_notification_req',
);

export const {
  getUnreadCount,
  getNotification,
  getUnreadNotification,
} = NotificationSLice.actions;

export default NotificationSLice;
