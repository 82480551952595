import React from 'react';
import { Row, Col, Card, Avatar } from 'antd';
import PropTypes from 'prop-types';

import { useIntl } from 'react-intl';
import { InfoCardWrapper } from '../OrgDetails.styles';
import orderImg from '../assets/order.svg';
import maintainImg from '../assets/mainteinance.svg';
// import locationImg from '../assets/location.svg';
import bgImage from '../assets/info-card-bg.svg';

const { Meta } = Card;
function OrgDetailsCountCard({ orderCount, maintenanceCount }) {
  // console.log(orderCount);
  const intl = useIntl();
  return (
    <InfoCardWrapper>
      <Row gutter={[24, 24]}>
        <Col span={5}>
          <Card
            style={{
              background: `#3FB65F url(${bgImage}) center/cover no-repeat`,
            }}
          >
            <Meta
              avatar={<Avatar src={orderImg} />}
              title={orderCount === 0 ? '0' : orderCount}
              description={intl.messages['Orders']}
            />
          </Card>
        </Col>
        <Col span={5}>
          <Card
            style={{
              background: `#29733C url(${bgImage}) center/cover no-repeat`,
            }}
          >
            <Meta
              avatar={<Avatar src={maintainImg} />}
              title={maintenanceCount === 0 ? '0' : maintenanceCount}
              description={intl.messages['Maintenance']}
            />
          </Card>
        </Col>
        {/* <Col span={5}>
          <Card
            style={{
              background: `#BE3696 url(${bgImage}) center/cover no-repeat`,
            }}
          >
            <Meta
              avatar={<Avatar src={locationImg} />}
              title="05"
              description={intl.messages['Locations']}
            />
          </Card>
        </Col> */}
      </Row>
    </InfoCardWrapper>
  );
}
OrgDetailsCountCard.propTypes = {
  maintenanceCount: PropTypes.number,
  orderCount: PropTypes.number,
};
export default OrgDetailsCountCard;
