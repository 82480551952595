import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { UserListWrapper } from './Users.styles';
import { getAllUsersRequest } from './reducer';
import UserTabble from './UserTable';

function UserList() {
  const dispatch = useDispatch();
  const users = useSelector(state => state.Users.list);
  const currentUserFromStore = useSelector(state => state.CurrentUser.info);
  const currentRole = currentUserFromStore.group.split('-')[0];

  useEffect(() => {
    dispatch(getAllUsersRequest({ query: currentRole }));
  }, [dispatch]);

  return (
    <UserListWrapper>
      <UserTabble data={users} />
    </UserListWrapper>
  );
}

export default UserList;
