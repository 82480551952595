import React from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';

const Header = styled.div`
  background-color: ${props => (props.bg ? props.bg : '#fff')};
  padding: 2rem 4rem;
  box-shadow: ${props => props.theme.shadowLight};
`;

const Title = styled.h3`
  color: ${props => props.theme.primary};
  margin-bottom: 0;
  font-weight: bold;
`;

const SectionHeader = ({ title = '' }) => {
  return (
    <Header>
      <Title>
        <FormattedMessage id={title} />
      </Title>
    </Header>
  );
};

SectionHeader.propTypes = {
  title: PropTypes.string.isRequired,
};

export default SectionHeader;
