import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import { LayoutContentWrapper } from 'components/utility/layoutWrapper.style';
import { Row, Col, Button } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { PRIVATE_ROUTE } from 'router';
import {
  EditOutlined,
  UsergroupAddOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import AddUser from 'features/AddUser';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { ShowForPermission } from 'components/utility';
import { OrgDetailsHeaderWrapper } from './OrgDetails.styles';
import OrgUserList from '../OrgUserList';
import OrderMaintanence from './OrderMaintanence';
import { getSingleOrganizationRequest, getAllOrgsUserRequest } from './reducer';

function OrgDetails({ orgType }) {
  const { id } = useParams();
  const intl = useIntl();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isUserListModalVisible, setIsUserListModalVisible] = useState(false);
  const pageEdit = PRIVATE_ROUTE.EDIT;
  const orgdetails = useSelector(state => state.OrgsDetails.orgDetails);
  const userList = useSelector(state => state.OrgsDetails.OrgUserList);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getSingleOrganizationRequest({ id }));
    dispatch(getAllOrgsUserRequest({ id }));
  }, [dispatch]);

  return (
    <LayoutContentWrapper>
      <Row type="flex" justify="space-between" align="middle">
        <Col span={12}>
          <OrgDetailsHeaderWrapper>
            <h2 className="db-page-title">{orgdetails && orgdetails.name}</h2>
            <p>
              {intl.messages['Org. no:']}
              {orgdetails && orgdetails.org_number}
              {`, | ${intl.messages['Phone']} : `}
              {orgdetails && orgdetails.phone}
              {`, | ${intl.messages['Address']} : `}
              {orgdetails && orgdetails.address}
            </p>
          </OrgDetailsHeaderWrapper>
        </Col>
        <Col span={12}>
          <Row type="flex" justify="end" align="middle" gutter={[15, 0]}>
            <ShowForPermission
              permission={['can_edit_customer', 'can_edit_supplier']}
            >
              <Col>
                <Link to={`${pageEdit}/`}>
                  <Button type="primary" ghost icon={<EditOutlined />}>
                    <span>
                      <FormattedMessage id="Edit" />
                    </span>
                  </Button>
                </Link>
              </Col>
            </ShowForPermission>

            <ShowForPermission
              permission={[
                'can_see_customer_user_list',
                'can_see_supplier_user_list',
              ]}
            >
              <Col>
                <Button
                  onClick={() => setIsUserListModalVisible(true)}
                  type="primary"
                  ghost
                  icon={<UsergroupAddOutlined />}
                >
                  <span>
                    <FormattedMessage id="Users" />
                  </span>
                </Button>
                <OrgUserList
                  query={parseInt(id, 10)}
                  isUserListModalVisible={isUserListModalVisible}
                  setIsUserListModalVisible={setIsUserListModalVisible}
                  setIsModalVisible={setIsModalVisible}
                  userList={userList}
                />
              </Col>
            </ShowForPermission>

            <ShowForPermission
              permission={[
                'can_add_new_customer_user',
                'can_add_new_supplier_user',
              ]}
            >
              <Col>
                <Button
                  icon={<PlusOutlined style={{ marginRight: 10 }} />}
                  type="primary"
                  ghost
                  onClick={() => setIsModalVisible(true)}
                  className="font-bold"
                >
                  <span>
                    <FormattedMessage id="Add New User" />
                  </span>
                </Button>
                <AddUser
                  extraFormData={{ organization: id }}
                  isModalVisible={isModalVisible}
                  setIsModalVisible={setIsModalVisible}
                  query={orgType}
                />
              </Col>
            </ShowForPermission>
          </Row>
        </Col>
      </Row>
      <OrderMaintanence />
    </LayoutContentWrapper>
  );
}

OrgDetails.propTypes = {
  orgType: PropTypes.string,
};

export default OrgDetails;
