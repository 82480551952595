import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Button, Card, Avatar } from 'antd';
import sliders from 'assets/icons/sliders.svg';
import { Link, useParams, useHistory } from 'react-router-dom';
import { PRIVATE_ROUTE } from 'router';
// import Scrollbars from 'react-custom-scrollbars';
import { ShowForPermission } from 'components/utility';
import { useSelector } from 'react-redux';
import { PermissionFormField } from 'components/utility/permissionFormField';
import { getItemFromUrl, ProjectType } from 'components/utility/CustomeFunc';
import { useIntl } from 'react-intl';
// import {
//  // getOrderStepRequest,
//   // getSingleProjectRequest,
//   // reOrderProjectStepRequest,
// } from 'features/Projects/reducer';
import { StepListWrapper } from '../Step.styles';
import Reorder from './Reorder';

function isAssignedSuppler(step, currentUser) {
  const { responsible_org } = step;
  const { organization } = currentUser;
  if (currentUser.group === 'supplier-admin') {
    if (responsible_org && responsible_org.org_type === organization.org_type) {
      if (responsible_org.id === organization.id) {
        return true;
      }
      return false;
    }
    return false;
  }
  return true;
}
function getColor(status) {
  // console.log(status);
  if (status === 'draft') return '#6D7986';
  if (status === 'in-progress') return '#FFA858';
  if (status === 'delayed') return '#FF8311';
  return '#3FB65F';
}
const { Meta } = Card;
const StepList = ({ info, steps, setSteps }) => {
  const { id } = useParams();
  const [visibleModal, setModal] = useState(false);
  // const [allOrderSteps, setSteps] = useState([]);
  // console.log(steps);

  // const dispatch = useDispatch();
  const intl = useIntl();
  const history = useHistory();
  const PType = getItemFromUrl(history, ProjectType);
  const currentUser = useSelector(state => state.CurrentUser.info);
  const userPermissions = useSelector(
    state => state.CurrentUser.info.permissions,
  );
  const isActiveFunc = permission => {
    return PermissionFormField(userPermissions, permission);
  };
  // useEffect(() => {
  //   dispatch(
  //     getOrderStepRequest({
  //       project_id: id,
  //       setSteps,
  //     }),
  //   );
  // }, [dispatch]);

  const openReorderModal = () => {
    setModal(true);
  };
  const handleCancel = () => {
    setModal(false);
  };
  return (
    <StepListWrapper>
      <div className="order_info_right">
        <div className="order_info_right_header">
          <div className="left">{intl.messages['Order Flow']}</div>
          <div className="right">
            <Reorder
              visible={visibleModal}
              handleCancel={handleCancel}
              list={steps}
              setSteps={setSteps}
            />
            <Button
              type="text"
              //  onClick={reOrderSteps}
              onClick={openReorderModal}
            >
              <img src={sliders} alt="slider icon" />
              {intl.messages['Re-order']}
            </Button>
          </div>
        </div>
        <div className="order_steps">
          {steps &&
            steps.length > 0 &&
            steps.map(item => (
              <Card
                key={item.id}
                style={{ background: getColor(item.step.status) }}
              >
                <Link
                  to={
                    isAssignedSuppler(item.step, currentUser) &&
                    isActiveFunc([
                      'can_see_all_step_detail',
                      'can_see_assigned_step_detail',
                    ])
                      ? `/dashboard/${PRIVATE_ROUTE.PROJECTS}/${PRIVATE_ROUTE.STEPS}/${item.step.id}`
                      : '#'
                  }
                  className={
                    !isAssignedSuppler(item.step, currentUser) ||
                    !isActiveFunc([
                      'can_see_all_step_detail',
                      'can_see_assigned_step_detail',
                    ])
                      ? 'disabled-link'
                      : ''
                  }
                >
                  <Meta
                    avatar={<Avatar size={50}>{`S${item.order}`}</Avatar>}
                    title={item.step.name}
                    description={`Status:  ${item.step.status}`}
                  />
                </Link>
              </Card>
            ))}
          {info && info.status !== 'completed' && (
            <div className="btn_container">
              {PType === PRIVATE_ROUTE.ORDER ? (
                <ShowForPermission
                  permission={['can_add_new_step_to_order_project']}
                >
                  <Link
                    to={`/dashboard/${PRIVATE_ROUTE.PROJECTS}/${PRIVATE_ROUTE.ORDER}/${id}/${PRIVATE_ROUTE.STEPS}/${PRIVATE_ROUTE.NEW}`}
                  >
                    <Button type="dashed">
                      {intl.messages['Add new STEP']}
                    </Button>
                  </Link>
                </ShowForPermission>
              ) : (
                <ShowForPermission
                  permission={['can_add_new_step_to_maintenance_project']}
                >
                  <Link
                    to={`/dashboard/${PRIVATE_ROUTE.PROJECTS}/${PRIVATE_ROUTE.ORDER}/${id}/${PRIVATE_ROUTE.STEPS}/${PRIVATE_ROUTE.NEW}`}
                  >
                    <Button type="dashed">
                      {intl.messages['Add new STEP']}
                    </Button>
                  </Link>
                </ShowForPermission>
              )}
            </div>
          )}
        </div>
        <div className="complete_steps">
          <h2>{intl.messages['Completed STEPS']}</h2>

          <div className="complete_step_items">
            {steps &&
              steps.length > 0 &&
              steps
                .filter(t => t.step.status === 'completed')
                .map(item => <span key={item.id}>{`S${item.order}`}</span>)}
          </div>
        </div>
      </div>
    </StepListWrapper>
  );
};
StepList.propTypes = {
  steps: PropTypes.arrayOf(PropTypes.any),
  info: PropTypes.objectOf(PropTypes.any),
  setSteps: PropTypes.func,
};
export default StepList;
