import { takeLatest, put, call } from 'redux-saga/effects';
import { message } from 'antd';
import { Projects } from 'api';
import { Files } from 'api/fileApi';
import {
  getAllProjects,
  getAllProjectsRequest,
  addNewProjectRequest,
  getSingleProjectRequest,
  getPrivateFileRequest,
  getSingleProject,
  getPrivateFile,
  getAllGMProjectLeader,
  getAllGMProjectLeaderRequest,
  getAllGMSalj,
  getAllGMCustomerProjectLeader,
  getAllStepsOfProject,
  getAllGMSalijRequest,
  getAllGMCustomerProjectLeaderRequest,
  getAllOrderProjects,
  getAllMaintenanceProjects,
  getAllOrderProjectsRequest,
  getAllMaintenanceProjectsRequest,
  updateProject,
  updateProjectRequest,
  deleteProjectFileRequest,
  completeProjectRequest,
  addRemarksProjectRequest,
  statsProjectRequest,
  monthlyCountProjectRequest,
  statsProjectPIRequest,
  reOrderProjectStepRequest,
  getOrderStepRequest,
  getProjectTimlineReq,
  getProjectTimeline,
} from './reducer';

export function* getSingleProjectSaga({ payload: { id } }) {
  const res = yield call(Projects.getSingleById, id);
  if (res?.data) {
    yield put(getSingleProject(res.data));
  }
}
export function* getPrivateFileSaga({ payload: { id } }) {
  const res = yield call(Files.index, id);

  if (res?.data) {
    yield put(getPrivateFile(res.data));
  }
}
export function* getAllProjectsSaga({
  payload: { limit, offset, type, status },
}) {
  const res = yield call(Projects.index, limit, offset, type, status);
  if (res.data) {
    yield put(getAllProjects(res.data));
  }
}
export function* getAllOrderProjectsSaga({
  payload: { limit, offset, type, status, orgId, orgType },
}) {
  const res = yield call(
    Projects.index,
    limit,
    offset,
    type,
    status,
    orgId,
    orgType,
  );
  if (res.data) {
    yield put(getAllOrderProjects(res.data));
  }
}
export function* getAllMaintenanceProjectsSaga({
  payload: { limit, offset, type, status, orgId, orgType },
}) {
  const res = yield call(
    Projects.index,
    limit,
    offset,
    type,
    status,
    orgId,
    orgType,
  );
  if (res.data) {
    yield put(getAllMaintenanceProjects(res.data));
  }
}

export function* addNewProjectSaga({
  payload: { data, resetForm, succMessage, history, path },
}) {
  const res = yield call(Projects.create, data);
  // console.log(res);

  if (res.data) {
    message.success(succMessage);
    history.push(path);
    resetForm();
  }
}
export function* updateProjectSaga({
  payload: { formData, id, succMessage, history },
}) {
  const res = yield call(Projects.update, id, formData);
  if (res?.data) {
    message.success(succMessage);
    history.goBack();
    yield put(updateProject(res.data));
  }
}
export function* deleteProjectSaga({ payload: { id, succMessage } }) {
  const res = yield call(Projects.delete, id);
  if (res?.data) {
    message.success(succMessage);
    yield call(getAllOrderProjectsSaga, {
      payload: { limit: 10, offset: 0, type: 'order' },
    });
    yield call(getAllMaintenanceProjectsSaga, {
      payload: { limit: 10, offset: 0, type: 'maintenance' },
    });
  }
}
export function* getAllGMProjectsLeaderSaga() {
  const res = yield call(Projects.getGMProjectLeader);
  if (res.data) {
    yield put(getAllGMProjectLeader(res.data));
  }
}

export function* getAllGMSalijSaga() {
  const res = yield call(Projects.getGMSalij);
  if (res.data) {
    yield put(getAllGMSalj(res.data));
  }
}

export function* getAllGMCustomerProjectsLeaderSaga({ payload: { id } }) {
  const res = yield call(Projects.getGMCustomerProjectLeader, id);
  if (res.data) {
    yield put(getAllGMCustomerProjectLeader(res.data));
  }
}

export function* deleteProjectFileSaga({
  payload: { project_pk, file_pk, succMessage },
}) {
  const res = yield call(Projects.fileDelete, project_pk, file_pk);
  if (res?.status === 204) {
    message.success(succMessage);
    yield call(getSingleProjectSaga, {
      payload: { id: project_pk },
    });
  }
}
export function* completeProjectSaga({ payload: { project_id, succMessage } }) {
  const res = yield call(Projects.complete, project_id);
  // console.log(res);
  if (res?.status === 200) {
    message.success(succMessage);
    yield call(getSingleProjectSaga, {
      payload: { id: project_id },
    });
  }
}
export function* addRemarksProjectSaga({
  payload: { formData, project_id, succMessage, resetForm, setEditRemarks },
}) {
  const res = yield call(Projects.remarks, project_id, formData);
  if (res?.data) {
    message.success(succMessage);
    resetForm();
    setEditRemarks(false);
    yield call(getSingleProjectSaga, {
      payload: { id: project_id },
    });
  }
}

export function* statsProjectSaga({
  payload: { setProjectStats, startDate, endDate },
}) {
  const res = yield call(Projects.statProjects, startDate, endDate);
  if (res.status === 200) {
    setProjectStats(res.data);
    //  setProjectCount(res.data);
  }
}
export function* statsProjectPISaga({
  payload: { setProjectCount, startDate, endDate },
}) {
  const res = yield call(Projects.statProjects, startDate, endDate);
  if (res.status === 200) {
    setProjectCount(res.data);
  }
}
export function* monthlyCountProjectSaga({
  payload: { setProjectStats, startDate, endDate },
}) {
  const res = yield call(Projects.monthlyCountProjects, startDate, endDate);
  if (res.status === 200) {
    setProjectStats(res.data);
  }
}

export function* stepOrderSaga({ payload: { setSteps, project_id } }) {
  const res = yield call(Projects.getOrderStep, project_id);
  if (res.status === 200) {
    setSteps(res.data);
    yield put(getAllStepsOfProject(res.data));
    //  setProjectCount(res.data);
  }
}
export function* reOrderProjectStepSaga({
  payload: { data, project_id, succMessage, setSteps },
}) {
  const res = yield call(Projects.stepReOrder, project_id, data);
  if (res?.data) {
    message.success(succMessage);
    yield call(stepOrderSaga, {
      payload: { setSteps, project_id },
    });
  }
}

export function* getProjectTimlineSaga({ payload }) {
  const res = yield call(Projects.getTimeline, payload);
  if (res?.data) {
    yield put(getProjectTimeline(res.data));
  }
}

export default function* watchProjectsActions() {
  yield takeLatest(getSingleProjectRequest.toString(), getSingleProjectSaga);
  yield takeLatest(getPrivateFileRequest.toString(), getPrivateFileSaga);
  yield takeLatest(getAllProjectsRequest.toString(), getAllProjectsSaga);
  yield takeLatest(
    getAllOrderProjectsRequest.toString(),
    getAllOrderProjectsSaga,
  );
  yield takeLatest(
    getAllMaintenanceProjectsRequest.toString(),
    getAllMaintenanceProjectsSaga,
  );
  yield takeLatest(addNewProjectRequest.toString(), addNewProjectSaga);
  yield takeLatest(updateProjectRequest.toString(), updateProjectSaga);
  yield takeLatest(
    getAllGMProjectLeaderRequest.toString(),
    getAllGMProjectsLeaderSaga,
  );
  yield takeLatest(getAllGMSalijRequest.toString(), getAllGMSalijSaga);
  yield takeLatest(
    getAllGMCustomerProjectLeaderRequest.toString(),
    getAllGMCustomerProjectsLeaderSaga,
  );
  yield takeLatest(deleteProjectFileRequest.toString(), deleteProjectFileSaga);
  yield takeLatest(completeProjectRequest.toString(), completeProjectSaga);
  yield takeLatest(addRemarksProjectRequest.toString(), addRemarksProjectSaga);
  yield takeLatest(statsProjectRequest.toString(), statsProjectSaga);
  yield takeLatest(statsProjectPIRequest.toString(), statsProjectPISaga);
  yield takeLatest(
    monthlyCountProjectRequest.toString(),
    monthlyCountProjectSaga,
  );
  yield takeLatest(
    reOrderProjectStepRequest.toString(),
    reOrderProjectStepSaga,
  );
  yield takeLatest(getOrderStepRequest.toString(), stepOrderSaga);
  yield takeLatest(getProjectTimlineReq.toString(), getProjectTimlineSaga);
}
