import React from 'react';
import { FormattedMessage } from 'react-intl';
import { UserModal } from 'features/Users';
import PropTypes from 'prop-types';
import EsignFieldEditForm from './EsignFieldEditForm';

function EsignFieldEdit({
  isModalVisible,
  setIsModalVisible,
  arrIndex,
  updateFormFieldLabel,
  updateFormFieldPlaceholder,
  formFields,
}) {
  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      <UserModal
        centered
        title={<FormattedMessage id="Edit form field" />}
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={null}
        width="91rem"
      >
        <EsignFieldEditForm
          arrIndex={arrIndex}
          updateFormFieldLabel={updateFormFieldLabel}
          updateFormFieldPlaceholder={updateFormFieldPlaceholder}
          handleModalClose={handleCancel}
          formFields={formFields}
        />
      </UserModal>
    </>
  );
}
EsignFieldEdit.propTypes = {
  isModalVisible: PropTypes.bool,
  setIsModalVisible: PropTypes.func,
  updateFormFieldLabel: PropTypes.func,
  updateFormFieldPlaceholder: PropTypes.func,
  arrIndex: PropTypes.number,
  // eslint-disable-next-line
  formFields: PropTypes.array,
};

export default EsignFieldEdit;
