/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { Form, Row, Col, Input, Button, Upload } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { FileImageOutlined, UserOutlined } from '@ant-design/icons';
import { useIntl, FormattedMessage } from 'react-intl';
import getValidateMessages, {
  getValidatedImageFile,
  getBase64,
} from 'lib/helpers/validators';
import { useHistory } from 'react-router';
import { UserAvatar, FormWrapper } from './UserSettingsStyles';
import { updateCurrentUserRequest } from './reducer';

function UserSettingsBasicForm() {
  const { messages } = useIntl();
  const intl = useIntl();
  const [form] = Form.useForm();
  const history = useHistory();
  const [imgUrl, setImageUrl] = useState('');
  const [logoUrl, setLogoUrl] = useState('');
  const { info } = useSelector(state => state.CurrentUser);
  const dispatch = useDispatch();

  const normFile = ({ fileList }) => {
    // only get the last item
    const recentImage = fileList[fileList.length - 1].originFileObj;
    if (getValidatedImageFile(recentImage, messages)) {
      getBase64(recentImage, imageUrl => {
        setImageUrl(imageUrl);
      });
      return recentImage;
    }
    return null;
  };
  const logoFile = ({ fileList }) => {
    // only get the last item
    const recentImage = fileList[fileList.length - 1].originFileObj;
    if (getValidatedImageFile(recentImage, messages)) {
      getBase64(recentImage, imageUrl => {
        setLogoUrl(imageUrl);
      });
      return recentImage;
    }
    return null;
  };

  useEffect(() => {
    const { first_name, last_name, phone, image, logo } = info;
    form.setFieldsValue({ first_name, last_name, phone });
    setImageUrl(image);
    setLogoUrl(logo);
  }, [info, form]);

  const onFinish = ({ first_name, last_name, phone, image, logo }) => {
    const data = {
      first_name,
      last_name,
      phone,
      ...(image && { image }),
      ...(logo && { logo }),
    };
    //  console.log(data);
    const formData = new FormData();
    Object.keys(data).forEach(key => formData.append(key, data[key]));

    dispatch(
      updateCurrentUserRequest({
        formData,
        successMsg: intl.messages['User updated successfully!'],
        id: info.id,
      }),
    );
  };

  return (
    <FormWrapper>
      <Form
        name="account_settings"
        onFinish={onFinish}
        labelCol={{ span: 24 }}
        form={form}
        validateMessages={getValidateMessages(messages)}
        style={{ padding: '4rem' }}
      >
        <Form.Item
          getValueFromEvent={normFile}
          label={messages['Display Picture']}
          name="image"
          valuePropName="filelist"
          className="dp-upload"
        >
          <Upload
            multiple={false}
            // eslint-disable-next-line no-unused-vars
            customRequest={({ _, onSuccess }) => {
              setTimeout(() => {
                onSuccess('ok');
              }, 0);
            }}
            showUploadList={false}
          >
            <UserAvatar src={imgUrl} size={123} icon={<UserOutlined />} />
          </Upload>
        </Form.Item>
        <Form.Item
          getValueFromEvent={logoFile}
          label={messages['Logo']}
          name="logo"
          valuePropName="filelist"
          className="dp-upload"
          extra={<FormattedMessage id="Max size of image is 10 MB" />}
        >
          <Upload
            multiple={false}
            // eslint-disable-next-line no-unused-vars
            customRequest={({ _, onSuccess }) => {
              setTimeout(() => {
                onSuccess('ok');
              }, 0);
            }}
            showUploadList={false}
          >
            <UserAvatar src={logoUrl} size={123} icon={<FileImageOutlined />} />
          </Upload>
        </Form.Item>

        <Form.Item
          label={messages['First name']}
          name="first_name"
          rules={[{ required: true }]}
          hasFeedback
        >
          <Input placeholder={messages['First Name']} size="large" />
        </Form.Item>
        <Form.Item
          label={messages['Last name']}
          name="last_name"
          rules={[{ required: true }]}
          hasFeedback
        >
          <Input placeholder={messages['Last Name']} size="large" />
        </Form.Item>
        <Form.Item label={messages['Phone']} name="phone">
          <Input type="number" placeholder={messages['Phone']} size="large" />
        </Form.Item>
        <Row className="mt-2" type="flex" justify="center" gutter="70">
          <Col>
            <Form.Item>
              <Button onClick={() => history.push('/')} size="large">
                <FormattedMessage id="Cancel" />
              </Button>
            </Form.Item>
          </Col>
          <Form.Item>
            <Button size="large" type="primary" htmlType="submit">
              <FormattedMessage id="Save" />
            </Button>
          </Form.Item>
          <Col />
        </Row>
      </Form>
    </FormWrapper>
  );
}

export default UserSettingsBasicForm;
