import React from 'react';
import Table from 'components/uielements/table';
import { Tag, Tooltip } from 'antd';
import { Pagination } from 'components/uielements';
import { Link } from 'react-router-dom';
import { PRIVATE_ROUTE } from 'router';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

function ProjectTable({
  results,
  count,
  changePagination,
  getFilterData,
  offset,
  listFor,
}) {
  console.log(listFor);
  const { messages } = useIntl();

  let data = [];
  if (results && results.length > 0) {
    data = results.map(item => {
      return { key: item.id, ...item };
    });
  }
  const columns = [
    {
      title: messages['Name'],
      dataIndex: 'name',
      //  width: 200,
      render: (name, record) => {
        return (
          <Link
            to={{
              pathname: `/dashboard/${PRIVATE_ROUTE.PROJECTS}/${record.id}/`,
              state: { projectType: record.project_type },
            }}
          >
            {record.name}
          </Link>
        );
      },
    },
    {
      title: messages['Customer'],
      dataIndex: 'customer',
      //  width: 150,
      render: (name, record) => {
        return (
          <Link
            to={{
              pathname: `/dashboard/${PRIVATE_ROUTE.PROJECTS}/${record.id}/`,
              state: { projectType: record.project_type },
            }}
          >
            {record && record.organization && record.organization.name}
          </Link>
        );
      },
    },
    {
      title: messages['Role'],
      dataIndex: 'creator_role',

      render: creator_role => {
        return <span>{creator_role}</span>;
      },
    },
    {
      title: messages['project_type'],
      dataIndex: 'project_type',
      //   width: 200,
      render: (name, record) => {
        const ProjectType =
          record.project_type === 'order' ? 'Order' : 'Maintenance';
        return (
          <Link
            to={{
              pathname: `/dashboard/${PRIVATE_ROUTE.PROJECTS}/${record.id}/`,
              state: { projectType: record.project_type },
            }}
          >
            {ProjectType}
          </Link>
        );
      },
      filterMultiple: false,
      filters: getFilterData
        ? [
            {
              text: 'Maintenance',
              value: 'maintenance',
            },
            {
              text: 'Order',
              value: 'order',
            },
          ]
        : undefined,
    },
    {
      title: messages['Status'],
      dataIndex: 'status',
      //  width: 200,
      render: statusV => {
        let color = 'default';
        if (statusV === 'completed') color = 'green';
        else if (statusV === 'in-progress') color = 'gold';
        else if (statusV === 'delayed') color = 'orange';
        return <Tag color={color}>{statusV.toUpperCase()}</Tag>;
      },
      filterMultiple: false,
      filters: getFilterData
        ? [
            {
              text: 'Draft',
              value: 'draft',
            },
            {
              text: 'In-progress',
              value: 'in-progress',
            },
            {
              text: 'Completed',
              value: 'completed',
            },
            {
              text: 'Delayed',
              value: 'delayed',
            },
          ]
        : undefined,
    },
    {
      title: messages['ActiveSTEP'],
      dataIndex: 'activeSTEP',
      //  width: 150,
      className: listFor === 'field' ? 'hide' : '',
      render: (name, record) => {
        return (
          <Link
            to={{
              pathname: `/dashboard/${PRIVATE_ROUTE.PROJECTS}/${record.id}/`,
              state: { projectType: record.project_type },
            }}
          >
            <Tooltip title={record.active_step && record.active_step.name}>
              {(record.active_step &&
                record.active_step.order &&
                `${record.active_step.order}/${record.steps.length}`) ||
                'N/A'}
            </Tooltip>
          </Link>
        );
      },
    },
    {
      title: messages['Deadline'],
      dataIndex: 'deadline',
      //  width: 200,

      render: (name, record) => {
        return (
          <Link
            to={{
              pathname: `/dashboard/${PRIVATE_ROUTE.PROJECTS}/${record.id}/`,
              state: { projectType: record.project_type },
            }}
          >
            {record.deadline ? record.deadline : 'N/A'}
          </Link>
        );
      },
    },
    {
      // title: 'Running Step',
      title: messages['Running Step'],
      dataIndex: 'running_step',
      className: listFor === 'field' ? 'hide' : '',
      render: (name, record) => {
        return (
          <Link
            to={{
              pathname: `/dashboard/${PRIVATE_ROUTE.PROJECTS}/${
                PRIVATE_ROUTE.STEPS
              }/${record.active_step && record.active_step.id}`,
              state: { projectType: record.project_type },
            }}
          >
            {record.active_step ? record.active_step?.name : 'N/A'}
            {record.active_step && record.active_step.is_delayed && (
              <Tag color="red" style={{ marginLeft: 5 }}>
                Delayed
              </Tag>
            )}
          </Link>
        );
      },
    },
  ];

  return (
    <div>
      <Table
        columns={columns}
        dataSource={data}
        pagination={false}
        onChange={getFilterData}
        footer={() => (
          <div style={{ textAlign: 'center' }}>
            <Pagination
              defaultCurrent={offset}
              total={count}
              onChange={changePagination}
            />
          </div>
        )}
      />
    </div>
  );
}
ProjectTable.propTypes = {
  results: PropTypes.arrayOf,
  count: PropTypes.number,
  changePagination: PropTypes.func,
  getFilterData: PropTypes.func,
  offset: PropTypes.number,
  listFor: PropTypes.string,
};
export default ProjectTable;
