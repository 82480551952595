/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Form, Input, Upload, Button, Row, Col } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { useIntl, FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import getValidateMessages, {
  getValidatedImageFile,
} from 'lib/helpers/validators';
// import { SelectCountryCode } from 'components/uielements';
import AddOrgFormWrapper from './AddOrgForm.styles';
import { addNewOrgRequest, addNewOrgAdminRequest } from '../reducer';

const { Dragger } = Upload;

const { TextArea } = Input;

function AddOrgForm({ orgType = 'customer' }) {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [formStep, setFormStep] = useState(true);
  const [orgInfo, setOrgInfo] = useState({});
  const intl = useIntl();
  const history = useHistory();

  const normFile = ({ fileList }) => {
    // only get the last item
    const recentImage = fileList[fileList.length - 1].originFileObj;
    if (getValidatedImageFile(recentImage)) {
      return recentImage;
    }
    return null;
  };

  const onFinishOrg = ({
    name,
    org_number,
    org_email,
    address,
    postcode,
    city,
    phone,
    image,
  }) => {
    const data = {
      name,
      org_number,
      org_email,
      address,
      postcode,
      city,
      phone,
      org_type: orgType,
      ...(image && { image }),
    };

    const formData = new FormData();
    Object.keys(data).forEach(key => formData.append(key, data[key]));

    dispatch(
      addNewOrgRequest({
        formData,
        resetForm: form.resetFields,
        setFormStep,
        setOrgInfo,
        succMessage: intl.messages['Organization Successfully Created!'],
      }),
    );
  };

  const onFinishAdmin = ({ first_name, last_name, email, phone }) => {
    const data = {
      first_name,
      last_name,
      email,
      phone,
      organization: orgInfo.id,
      group: orgType === 'customer' ? 'customer-admin' : 'supplier-admin',
    };
    dispatch(
      addNewOrgAdminRequest({
        formData: data,
        resetForm: form.resetFields,
        setFormStep,
        succMessage:
          orgType === 'customer'
            ? intl.messages['Customer admin details successfully updated!']
            : intl.messages['Supplier admin details successfully updated!'],
      }),
    );
    history.push(
      orgType === 'customer' ? '/dashboard/customers' : '/dashboard/suppliers',
    );
  };

  return (
    <AddOrgFormWrapper>
      {formStep ? (
        <div className="org-details">
          <div className="form-header">
            <h2>
              {orgType === 'customer' ? (
                <FormattedMessage id="Add new customer" />
              ) : (
                <FormattedMessage id="Add new supplier" />
              )}
            </h2>
          </div>
          <Form
            name="add_new_org"
            onFinish={onFinishOrg}
            labelCol={{ span: 24 }}
            form={form}
            validateMessages={getValidateMessages(intl.messages)}
            initialValues={{
              country_code: '46',
            }}
          >
            <div className="customer_section">
              <Row gutter={50}>
                <Col span={8}>
                  <Form.Item
                    label={intl.messages['Organization name']}
                    name="name"
                    rules={[{ required: true }]}
                    hasFeedback
                  >
                    <Input
                      placeholder={intl.messages['Organization name']}
                      size="large"
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    label={intl.messages['Organization no']}
                    name="org_number"
                    rules={[{ required: true }]}
                    hasFeedback
                  >
                    <Input
                      placeholder={`${intl.messages['Organization no']} (Ex: 5591322671)`}
                      size="large"
                      type="number"
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    label={intl.messages['Organization email']}
                    name="org_email"
                    rules={[{ required: true, type: 'email' }]}
                    hasFeedback
                  >
                    <Input
                      placeholder={intl.messages['Organization email']}
                      size="large"
                      type="email"
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Form.Item
                label={intl.messages['Address']}
                name="address"
                rules={[
                  {
                    required: true,
                  },
                ]}
                hasFeedback
              >
                <TextArea
                  placeholder={intl.messages['Address']}
                  size="large"
                  rows={4}
                />
              </Form.Item>

              <Row gutter={50}>
                <Col span={8}>
                  <Form.Item
                    label={intl.messages['Post code']}
                    name="postcode"
                    rules={[{ required: false }]}
                    hasFeedback
                  >
                    <Input
                      placeholder={intl.messages['Post code']}
                      size="large"
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    label={intl.messages['City']}
                    name="city"
                    rules={[{ required: false }]}
                    hasFeedback
                  >
                    <Input placeholder={intl.messages['City']} size="large" />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    label={intl.messages['Phone']}
                    name="phone"
                    rules={[{ required: true }]}
                    hasFeedback
                  >
                    <Input
                      // addonBefore={<SelectCountryCode name="country_code" />}
                      placeholder={intl.messages['Phone']}
                      size="large"
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Form.Item
                getValueFromEvent={normFile}
                valuePropName="filelist"
                label={intl.messages['Add a logo/icon']}
                name="image"
                rules={[
                  {
                    required: false,
                  },
                ]}
                hasFeedback
              >
                <Dragger
                  // eslint-disable-next-line no-unused-vars
                  customRequest={({ _, onSuccess }) => {
                    setTimeout(() => {
                      onSuccess('ok');
                    }, 0);
                  }}
                >
                  <p className="ant-upload-drag-icon">
                    <UploadOutlined />
                  </p>
                  <p className="ant-upload-text">
                    <FormattedMessage id="Drag files here to upload" />
                  </p>
                </Dragger>
              </Form.Item>

              <Row className="mt-2" type="flex" justify="center" gutter="70">
                <Col>
                  <Form.Item>
                    <Button onClick={() => history.goBack()} size="large">
                      <FormattedMessage id="Cancel" />
                    </Button>
                  </Form.Item>
                </Col>
                <Form.Item>
                  <Button size="large" type="primary" htmlType="submit">
                    <FormattedMessage id="Save" />
                  </Button>
                </Form.Item>
                <Col />
              </Row>
            </div>
          </Form>
        </div>
      ) : (
        <div className="admin-details">
          <div className="form-header">
            <h2>
              {orgType === 'customer' ? (
                <FormattedMessage id="Customer admin details" />
              ) : (
                <FormattedMessage id="Supplier admin details" />
              )}
            </h2>
          </div>
          <Form
            name="add_new_org"
            onFinish={onFinishAdmin}
            labelCol={{ span: 24 }}
            form={form}
            validateMessages={getValidateMessages(intl.messages)}
          >
            <div className="admin_section">
              <Row gutter={50}>
                <Col span={8}>
                  <Form.Item
                    rules={[{ required: true }]}
                    label={intl.messages['First name']}
                    name="first_name"
                  >
                    <Input
                      type="text"
                      placeholder={intl.messages['First name']}
                      size="large"
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    rules={[{ required: true }]}
                    label={intl.messages['Last name']}
                    name="last_name"
                  >
                    <Input
                      type="text"
                      placeholder={intl.messages['Last name']}
                      size="large"
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    rules={[{ required: true, type: 'email' }]}
                    label={intl.messages['Email']}
                    name="email"
                  >
                    <Input
                      type="email"
                      placeholder={intl.messages['Email']}
                      size="large"
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    rules={[{ required: true }]}
                    label={intl.messages['Phone']}
                    name="phone"
                  >
                    <Input
                      type="text"
                      placeholder={intl.messages['Phone']}
                      size="large"
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row className="mt-2" type="flex" justify="center" gutter="70">
                <Col>
                  <Form.Item>
                    <Button onClick={() => history.goBack()} size="large">
                      <FormattedMessage id="Cancel" />
                    </Button>
                  </Form.Item>
                </Col>
                <Form.Item>
                  <Button size="large" type="primary" htmlType="submit">
                    <FormattedMessage id="Save" />
                  </Button>
                </Form.Item>
                <Col />
              </Row>
            </div>
          </Form>
        </div>
      )}
    </AddOrgFormWrapper>
  );
}

AddOrgForm.propTypes = {
  orgType: PropTypes.string,
};

export default AddOrgForm;
