import { takeLatest, call, put } from 'redux-saga/effects';
import { message } from 'antd';
import { ProjectSteps } from 'api';
import {
  addNewStepRequest,
  getAllOrgByProjectID,
  getAllOrgByProjectIDRequest,
  getAllResponsibleByPIDAndOrgID,
  getAllResponsibleByPIDAndOrgIDRequest,
  getSingleStep,
  getSingleStepRequest,
  getEsignFormList,
  getEsignFormListRequest,
  updateStep,
  updateStepRequest,
  getStepESignList,
  getStepESignListRequest,
  startStepRequest,
  sendOTPRequest,
  verifyOTPRequest,
  fileUploadRequest,
  sendCommentStepRequest,
  getCommentListRequest,
  // getCommentList,
  approveCommentRequest,
  deleteStepRequest,
} from './reducer';

export function* getAllOrgByProjectIDSaga({ payload: { projectId } }) {
  const res = yield call(ProjectSteps.getOrgByProjectId, projectId);
  if (res?.data) {
    yield put(getAllOrgByProjectID(res.data));
  }
}
export function* getAllResponsibleByPIDAndOrgIDSaga({
  payload: { orgId, projectId },
}) {
  const res = yield call(ProjectSteps.getResponsibleById, orgId, projectId);
  if (res.data) {
    yield put(getAllResponsibleByPIDAndOrgID(res.data));
  }
}
export function* getESignFormByOrgIDSaga({ payload: { orgId } }) {
  const res = yield call(ProjectSteps.eSignList, orgId);
  if (res.data) {
    yield put(getEsignFormList(res.data));
  }
}

export function* addNewStepSaga({
  payload: { data, resetForm, succMessage, history },
}) {
  const res = yield call(ProjectSteps.create, data);
  console.log(res);

  if (res.data) {
    message.success(succMessage);
    history.push(`/dashboard/projects/steps/${res.data.id}`);
    resetForm();
    //  yield call(getAllStepsSaga);
  }
}

export function* getSingleStepSaga({ payload: { id } }) {
  const res = yield call(ProjectSteps.getSingleById, id);
  if (res?.data) {
    yield put(getSingleStep(res.data));
  }
}
export function* updateStepSaga({
  payload: { formData, id, succMessage, history },
}) {
  const res = yield call(ProjectSteps.update, id, formData);
  if (res?.data) {
    message.success(succMessage);
    yield put(updateStep(res.data));
    history.push(`/dashboard/projects/steps/${res.data.id}`);
  }
}
export function* getStepESignSaga({ payload: { slug } }) {
  const res = yield call(ProjectSteps.stepESignList, slug);
  if (res?.data) {
    yield put(getStepESignList(res.data));
  }
}

export function* startStepSaga({ payload: { step_id, succMessage } }) {
  const res = yield call(ProjectSteps.startStep, step_id);
  console.log(res);
  if (res.status === 200) {
    message.success(succMessage);
    yield call(getSingleStepSaga, { payload: { id: step_id } });
  }
}

export function* sendOTPSaga({ payload: { slug, succMessage } }) {
  const res = yield call(ProjectSteps.sendOTP, slug);
  console.log(res);
  if (res.status === 200) {
    message.success(succMessage);
  }
}

export function* verifyOTPSaga({
  payload: { setOnSubmit, otp, succMessage, data, slug },
}) {
  const res = yield call(ProjectSteps.verifyOTP, otp);
  if (res.status === 200 && res.data === true) {
    const res1 = yield call(ProjectSteps.updateForm, data, slug);
    if (res1.status === 200) {
      setOnSubmit(true);
      message.success(succMessage);
    }
  } else {
    message.error('This OTP has expired!! Please resend OTP!');
  }
}

export function* fileUploadSaga({
  payload: { formData, setFileUrl, setFileName },
}) {
  const res = yield call(ProjectSteps.uploadFileFormTemplate, formData);
  console.log(res);

  if (res.status === 201) {
    console.log(res.data.file);
    setFileUrl(res.data.file);
    setFileName(res.data.name);
    //  yield call(getAllStepsSaga);
  }
}
export function* getCommentListSaga({ payload: { stepId, setComments } }) {
  const res = yield call(ProjectSteps.getComments, stepId);
  if (res?.data) {
    setComments(res.data);
  }
}

export function* approveCommentSaga({
  payload: { formData, commentId, succMessage, setUpdateViewer, setComments },
}) {
  const res = yield call(ProjectSteps.reviewComment, commentId, formData);
  if (res.status === 200) {
    message.success(succMessage);
    setUpdateViewer('');
    yield call(getCommentListSaga, {
      payload: { stepId: res.data.step, setComments },
    });
  }
}

export function* sendCommentStepSaga({
  payload: {
    setSendComment,
    setCommnet,
    setViewer,
    //  setUpdateViewer,
    formData,
    succMessage,
    setComments,
  },
}) {
  const res = yield call(ProjectSteps.sendComment, formData);

  if (res.status === 200) {
    setSendComment(false);
    setCommnet('');
    setViewer('');
    // if (res.data.viewer === 'for-gm') {
    //   // yield call(approveCommentSaga, {
    //   //   payload: {
    //   //     formData: { viewer: res.data.viewer },
    //   //     commentId: res.data.id,
    //   //     succMessage,
    //   //     setUpdateViewer,
    //   //     setComments,
    //   //   },
    //   // });
    //   message.success(succMessage);
    // } else {
    message.success(succMessage);
    yield call(getCommentListSaga, {
      payload: { stepId: formData.step, setComments },
    });
    //  }
  }
}

export function* deleteStepSaga({
  payload: { step_id, succMessage, history, projectInfo },
}) {
  const res = yield call(ProjectSteps.delete, step_id);
  if (res.status === 204) {
    message.success(succMessage);
    history.push(
      `/dashboard/projects/${projectInfo.project_type}/${projectInfo.id}`,
    );
  }
}

export default function* watchStepsActions() {
  yield takeLatest(addNewStepRequest.toString(), addNewStepSaga);
  yield takeLatest(updateStepRequest.toString(), updateStepSaga);
  yield takeLatest(getSingleStepRequest.toString(), getSingleStepSaga);
  yield takeLatest(getEsignFormListRequest.toString(), getESignFormByOrgIDSaga);
  yield takeLatest(
    getAllOrgByProjectIDRequest.toString(),
    getAllOrgByProjectIDSaga,
  );
  yield takeLatest(
    getAllResponsibleByPIDAndOrgIDRequest.toString(),
    getAllResponsibleByPIDAndOrgIDSaga,
  );
  yield takeLatest(getStepESignListRequest.toString(), getStepESignSaga);
  yield takeLatest(startStepRequest.toString(), startStepSaga);
  yield takeLatest(sendOTPRequest.toString(), sendOTPSaga);
  yield takeLatest(verifyOTPRequest.toString(), verifyOTPSaga);
  yield takeLatest(fileUploadRequest.toString(), fileUploadSaga);
  yield takeLatest(sendCommentStepRequest.toString(), sendCommentStepSaga);
  yield takeLatest(getCommentListRequest.toString(), getCommentListSaga);
  yield takeLatest(approveCommentRequest.toString(), approveCommentSaga);
  yield takeLatest(deleteStepRequest.toString(), deleteStepSaga);
}
