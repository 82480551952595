import { takeLatest, put, call } from 'redux-saga/effects';
import { Orgs } from 'api';
// import { message } from 'antd';
import {
  getAllOrgsUser,
  getAllOrgsUserRequest,
  getSingleOrganization,
  getSingleOrganizationRequest,
} from './reducer';

export function* getAllOrgsUserSaga({ payload: { id } }) {
  const res = yield call(Orgs.users, id);
  if (res.data) {
    yield put(getAllOrgsUser(res.data));
  }
}

export function* getSingleOrganizationSaga({ payload: { id } }) {
  const res = yield call(Orgs.getSingleById, id);
  if (res?.data) {
    yield put(getSingleOrganization(res.data));
  }
}

export default function* watchOrgsActions() {
  yield takeLatest(getAllOrgsUserRequest.toString(), getAllOrgsUserSaga);
  yield takeLatest(
    getSingleOrganizationRequest.toString(),
    getSingleOrganizationSaga,
  );
}
