import styled from 'styled-components';

const InfillMeasureFieldsWrapper = styled.div`
  margin-bottom: 20px;
  width: 600px;
  h4 {
    font-weight: 500;
  }
  .ant-form-item {
    margin-bottom: 0;
    .ant-form-item-label {
      padding: 0;
    }
  }
`;

export default InfillMeasureFieldsWrapper;
