import { http } from 'services';
import { getHeader } from 'lib/helpers';
import { fileUrl } from './endpoints';

export const Files = {
  index: id =>
    http.get(`${fileUrl}${id}/`, {
      headers: getHeader(),
    }),
};
