import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Row, Col, Button } from 'antd';
import { FormattedMessage } from 'react-intl';
import AddUser from 'features/AddUser';
import { PlusOutlined } from '@ant-design/icons';
import UserList from './UserList';

function Users() {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const currentUserFromStore = useSelector(state => state.CurrentUser.info);
  const currentRole = currentUserFromStore.group.split('-')[0];

  return (
    <>
      <Row type="flex" justify="space-between" align="middle">
        <Col>
          <h2 className="font-bold">
            <FormattedMessage id="Users" />
          </h2>
        </Col>
        <Col>
          <Button
            icon={<PlusOutlined />}
            type="link"
            onClick={() => setIsModalVisible(true)}
            className="font-bold"
          >
            <span>
              <FormattedMessage id="Add New User" />
            </span>
          </Button>
          <AddUser
            isModalVisible={isModalVisible}
            setIsModalVisible={setIsModalVisible}
            query={currentRole}
          />
        </Col>
      </Row>
      <UserList />
    </>
  );
}

export default Users;
