import { http } from 'services';
import { getHeader } from 'lib/helpers';
import { projectsUrl, usersUrl, deleteFileUrl } from './endpoints';

export const Projects = {
  index: (limit, offset, type, status, orgId, orgType) =>
    http.get(
      `${projectsUrl}?org_type=${
        orgType || ''
      }&limit=${limit}&offset=${offset}&type=${type || ''}&status=${
        status || ''
      }&org=${orgId || ''}`,
      {
        headers: getHeader(),
      },
    ),
  create: data =>
    http.post(projectsUrl, data, {
      headers: getHeader(),
    }),
  update: (id, data) =>
    http.put(`${projectsUrl}${id}/`, data, {
      headers: getHeader(),
    }),
  delete: id =>
    http.delete(`${projectsUrl}${id}/`, {
      headers: getHeader(),
    }),
  getSingleById: id =>
    http.get(`${projectsUrl}${id}/`, {
      headers: getHeader(),
    }),
  getGMProjectLeader: () =>
    http.get(`${usersUrl}?role=gm-projektledere`, {
      headers: getHeader(),
    }),
  getGMSalij: () =>
    http.get(`${usersUrl}?role=gm-salj`, {
      headers: getHeader(),
    }),
  getGMCustomerProjectLeader: id =>
    http.get(`${usersUrl}?role=customer-projektledere&org=${id}`, {
      headers: getHeader(),
    }),
  fileDelete: (project_pk, file_pk) =>
    http.get(`${deleteFileUrl}/delete/${project_pk}/${file_pk}/`, {
      headers: getHeader(),
    }),
  complete: project_id =>
    http.get(`${projectsUrl}${project_id}/complete/`, {
      headers: getHeader(),
    }),
  remarks: (project_id, data) =>
    http.put(`${projectsUrl}${project_id}/remarks/`, data, {
      headers: getHeader(),
    }),
  statProjects: (startDate, endDate) =>
    http.get(
      `${projectsUrl}total_count_stat/${
        startDate !== undefined
          ? `?from_date=${startDate}&to_date=${endDate}`
          : ''
      }`,
      {
        headers: getHeader(),
      },
    ),

  monthlyCountProjects: (startDate, endDate) =>
    // ?from_date=${startDate}&to_date=${endDate}
    http.get(
      `${projectsUrl}monthly_count_stat/?from_date=${startDate}&to_date=${endDate}`,
      {
        headers: getHeader(),
      },
    ),
  stepReOrder: (project_id, data) =>
    http.post(`${projectsUrl}${project_id}/step_order_update/`, data, {
      headers: getHeader(),
    }),
  getOrderStep: project_id =>
    http.get(`${projectsUrl}${project_id}/step_order/`, {
      headers: getHeader(),
    }),
  getTimeline: project_id =>
    http.get(`${projectsUrl}${project_id}/timeline/`, {
      headers: getHeader(),
    }),
};
