import React, { useState } from 'react';
import { Button } from 'antd';
import Table from 'components/uielements/table';
import PropTypes from 'prop-types';
import { useIntl, FormattedMessage } from 'react-intl';
import { Pagination } from 'components/uielements';
import { EditOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { PRIVATE_ROUTE } from 'router';
import { ShowForPermission } from 'components/utility';
import NewField from './CreateNewField/NewField';
import { checkSaimosServerReq } from './reducer';

function FieldTable({ data, count, changePagination, offset, currentPage }) {
  const dispatch = useDispatch();
  const saimos_server = useSelector(state => state.Fields.saimos_server);

  const { messages } = useIntl();
  const [fieldInfo, setFieldInfo] = useState({});

  const [isModalVisible, setIsModalVisible] = useState(false);

  const editField = (visible, info) => {
    setFieldInfo(info);
    setIsModalVisible(visible);
  };
  const checkSaimosServer = id => {
    dispatch(
      checkSaimosServerReq({
        id,
        succMessage: messages['Status: Connected'],
        failedMessage: messages['Status: Failed'],
      }),
    );
  };

  const checkCoordinate = record => {
    return (
      record.coordinate_x1 &&
      record.coordinate_x2 &&
      record.coordinate_x3 &&
      record.coordinate_x4 &&
      record.coordinate_y1 &&
      record.coordinate_y2 &&
      record.coordinate_y3 &&
      record.coordinate_y4
    );
  };

  const columns = [
    {
      title: messages['FieldName'],
      dataIndex: 'name',
      width: 200,
      render: (name, record) => {
        return (
          <Link to={`/dashboard/${PRIVATE_ROUTE.FIELDS}/${record.id}`}>
            {record.name}
          </Link>
        );
      },
    },
    {
      title: messages['Address'],
      dataIndex: 'address',
      width: 200,
    },
    {
      title: messages['Organisation'],
      dataIndex: 'organization',
      width: 200,
      render: (organization, record) => {
        return <span>{`${record.organization_detail.name || ''} `}</span>;
      },
    },
    {
      title: messages['Action'],
      dataIndex: 'action',
      width: 200,
      render: (action, record) => {
        return (
          <>
            <ShowForPermission permission={['can_edit_location']}>
              <Button onClick={() => editField(true, record)}>
                <EditOutlined key="edit" />
              </Button>
            </ShowForPermission>
            &nbsp;
            {checkCoordinate(record) ? (
              <Button
                type="primary"
                size="small"
                onClick={() => checkSaimosServer(record.id)}
                loading={saimos_server[record.id]}
              >
                <FormattedMessage id="Check Saimos Server" />
              </Button>
            ) : (
              <Button type="primary" size="small" danger>
                <FormattedMessage id="Saimos server not configure yet" />
              </Button>
            )}
          </>
        );
      },
    },
  ];

  return (
    <div>
      <Table
        columns={columns}
        dataSource={data}
        pagination={false}
        rowKey={record => record.id}
        footer={() => (
          <div style={{ textAlign: 'center' }}>
            <Pagination
              total={count}
              onChange={changePagination}
              current={currentPage}
            />
          </div>
        )}
      />

      {isModalVisible && fieldInfo && (
        <NewField
          isModalVisible={isModalVisible}
          setIsModalVisible={setIsModalVisible}
          action="update"
          fieldInfo={fieldInfo}
          offset={offset}
        />
      )}
    </div>
  );
}
FieldTable.propTypes = {
  data: PropTypes.objectOf,
  count: PropTypes.number,
  changePagination: PropTypes.func,
  offset: PropTypes.number,
  currentPage: PropTypes.number,
};
export default FieldTable;
