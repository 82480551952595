import React, { memo } from 'react';
import { Card as AntCard, Avatar } from 'antd';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { tint, shade } from 'polished';
import { Link } from 'react-router-dom';
import { PRIVATE_ROUTE } from 'router';

const { Meta } = AntCard;

const Card = styled(AntCard)`
  box-shadow: ${props => props.theme.shadowLight};
  border: 1px solid ${props => tint(0.9, props.theme.secondary)};
  border-radius: 10px;
  min-height: 144px;

  .ant-card-body {
    padding: 30px 24px;
  }
  .ant-card-meta {
    display: flex;

    .ant-avatar {
      width: 8rem;
      height: 8rem;
      border-radius: 8px;
      padding: 8px;
      border: 1px solid #cfcfcf;
    }

    &-title {
      color: ${props => shade(0.2, props.theme.primary)};
      font-size: 18px;
    }

    &-description {
      color: ${props => tint(0.6, props.theme.secondary)};
      font-size: 16px;
    }
  }
`;

function OrgCard({
  org: {
    name = '',
    image = 'https://picsum.photos/50',
    address = '',
    id,
    org_type,
  },
}) {
  const page =
    org_type === 'customer' ? PRIVATE_ROUTE.CUSTOMERS : PRIVATE_ROUTE.SUPPLIERS;

  return (
    <Link to={`${page}/${id}/`}>
      <Card style={{ width: '340' }}>
        <Meta
          avatar={<Avatar src={image} />}
          title={name}
          description={address}
        />
      </Card>
    </Link>
  );
}

OrgCard.propTypes = {
  org: PropTypes.shape({
    image: PropTypes.string,
    name: PropTypes.string,
    address: PropTypes.string,
    id: PropTypes.number,
    org_type: PropTypes.string,
  }),
};

export default memo(OrgCard);
