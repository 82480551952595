import { UserModal } from 'features/Users';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import { Avatar, Button, Col, Row } from 'antd';
import ReactDragListView from 'react-drag-listview';
import { DragOutlined } from '@ant-design/icons';
import { useParams } from 'react-router';
import { useDispatch } from 'react-redux';
import { reOrderProjectStepRequest } from 'features/Projects/reducer';

function getColor(status) {
  // console.log(status);
  if (status === 'draft') return '#6D7986';
  if (status === 'in-progress') return '#FFA858';
  if (status === 'delayed') return '#FF8311';
  return '#3FB65F';
}
const elements = {
  l1: 'li',
  l2: 'div',
};
const CustomTag = ({ tag, children, ...props }) => {
  // console.log(elements[tag]);
  return React.createElement(elements[tag] || elements.l1, props, children);
};
CustomTag.propTypes = {
  tag: PropTypes.string,
  children: PropTypes.objectOf(PropTypes.any),
};
const elementDrag = {
  s1: 'span',
  s2: 'div',
};
const CustomDrag = ({ tag, children, ...props }) => {
  return React.createElement(
    elementDrag[tag] || elementDrag.s1,
    props,
    children,
  );
};
CustomDrag.propTypes = {
  tag: PropTypes.string,
  children: PropTypes.objectOf(PropTypes.any),
};

const Reorder = ({ visible, handleCancel, list, setSteps }) => {
  // console.log(list);
  const [dragData, setData] = useState(list);
  const [orderData, setOrderData] = useState([]);
  const { id } = useParams();
  const dispatch = useDispatch();
  const intl = useIntl();

  useEffect(() => {
    setData(list);
  }, [list]);

  useEffect(() => {
    const order = dragData && dragData.map(item => item.step.id);
    setOrderData(order);
  }, [dragData]);

  const dragProps = {
    onDragEnd(fromIndex, toIndex) {
      // console.log(fromIndex, toIndex);
      const data1 = [...dragData];
      // console.log(data1);
      const item = data1.splice(fromIndex, 1)[0];
      // console.log(item);
      data1.splice(toIndex, 0, item);
      setData(data1);
    },
    nodeSelector: 'li',
    handleSelector: 'span',
  };
  const sendOk = () => {
    // console.log(`ok: ${orderData}`);
    dispatch(
      reOrderProjectStepRequest({
        data: { order: orderData.reverse() },
        project_id: id,
        succMessage: intl.messages['Steps Re-Ordered successfully!'],
        setSteps,
      }),
    );
    handleCancel();
  };
  const cardStyle = status => {
    return {
      padding: '20px',
      background: getColor(status),
      marginBottom: '15px',
      border: '1px solid #ddd',
      borderRadius: '5px',
    };
  };

  return (
    <UserModal
      centered
      title={<FormattedMessage id="Re-order STEPs" />}
      visible={visible}
      onCancel={handleCancel}
      footer={null}
      width="61rem"
    >
      <div className="order_steps">
        <ReactDragListView {...dragProps}>
          <>
            {dragData &&
              dragData.map(item => (
                <CustomTag
                  tag={item.step.status === 'draft' ? 'l1' : 'l2'}
                  key={item.id}
                  style={cardStyle(item.step.status)}
                >
                  {/* <li > */}
                  <Row>
                    <Col span={12}>
                      <Row>
                        <Col span={6}>
                          <Avatar size={50}>{`S${item.order}`}</Avatar>
                        </Col>
                        <Col span={18}>
                          <div style={{ marginLeft: 10, color: '#fff' }}>
                            <p>{item.step.name}</p>
                            <p>{`Status:  ${item.step.status}`}</p>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                    <Col span={12} style={{ textAlign: 'right' }}>
                      <CustomDrag
                        tag={item.step.status === 'draft' ? 's1' : 's2'}
                        style={{ cursor: 'pointer' }}
                      >
                        {item.step.status === 'draft' && (
                          <DragOutlined
                            style={{
                              fontSize: 25,
                              padding: '10px',
                              color: '#fff',
                            }}
                          />
                        )}
                      </CustomDrag>
                    </Col>
                  </Row>
                  {/* </li> */}
                </CustomTag>
              ))}
          </>
        </ReactDragListView>
        <Row className="mt-2" type="flex" justify="center" gutter="70">
          <Col>
            <Button size="medium" onClick={handleCancel}>
              <FormattedMessage id="Cancel" />
            </Button>
          </Col>
          <Button size="medium" type="primary" onClick={sendOk}>
            <FormattedMessage id="Done" />
          </Button>
          <Col />
        </Row>
      </div>
    </UserModal>
  );
};

Reorder.propTypes = {
  visible: PropTypes.bool,
  handleCancel: PropTypes.func,
  list: PropTypes.arrayOf(PropTypes.any),
  setSteps: PropTypes.func,
  tag: PropTypes.string,
};
export default Reorder;
