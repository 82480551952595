import styled from 'styled-components';

export const OrgDetailsHeaderWrapper = styled.div`
  margin: 0 0 30px;
  .db-page-title {
    margin: 0 0 8px;
  }
  p {
    font-size: 16px;
    line-height: 155%;
    color: #585f75;
  }
`;

export const InfoCardWrapper = styled.div`
.ant-card {
    min-height: 130px;
    box-shadow: 0px 6px 11px rgba(29, 48, 33, 0.13);
    border-radius: 8px;
  
    .ant-card-body {
      padding: 16px 14px;
    }
    .ant-card-meta {
      display: flex;
      @media only screen and (max-width: 1490px){
        flex-direction: column;
        .ant-card-meta-detail{
          display: flex;
          .ant-card-meta-title{
            margin-right: 10px;
          }
        }
      }
      .ant-avatar {
        width: 75px;
        height: 75px;
      }
  
      &-title {
        color: #fff;
        font-weight: bold;
        font-size: 18px;
        line-height: 21px;
      }
  
      &-description {
        color: #fff;
        font-size: 16px;
        letter-spacing: 0.2px;
        line-height: 19px;
        font-weight: 500;
      }
}
`;

export const OrderSectionWrapper = styled.div`
  margin: 20px 0 80px;
  .order_card {
    background: #ffffff;
    box-shadow: 0px 8px 14px rgba(29, 48, 33, 0.03);
    border-radius: 5px;
    padding: 40px 30px;
    .order_card_header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .order_card_header_left {
        color: #454d66;
        font-weight: bold;
        font-size: 18px;
        line-height: 155%;
        img {
          padding: 5px;
          background: #3fb65f;
          border-radius: 50%;
          width: 31px;
          height: 31px;
          margin-right: 16px;
        }
      }
      .order_card_header_right {
        color: #454d66;
        font-weight: bold;
        font-size: 18px;
        line-height: 155%;
        .anticon {
          margin-right: 16px;
        }
      }
    }
    .order_item {
      padding: 24px;
      box-shadow: 0px 8px 14px rgba(29, 48, 33, 0.03);
      border-radius: 5px;
      background: #ffffff;
      border: 1px solid #3fb65f;
      margin: 30px 10px 0;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      .order_item_content {
        h3 {
          color: #585f75;
          font-weight: 500;
          font-size: 16px;
          line-height: 155%;
        }
        p {
          color: #6a7185;
          font-weight: normal;
          font-size: 14px;
          line-height: 155%;
        }
      }
      .order_view {
        display: inline-block;
        color: #3fb65f;
        border: 1px solid #3fb65f;
        box-sizing: border-box;
        border-radius: 5px;
        font-weight: 500;
        font-size: 16px;
        line-height: 155%;
        padding: 7px 25px;
      }
    }
  }
`;
