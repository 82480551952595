import { createSlice, createAction } from '@reduxjs/toolkit';

const initState = {
  customers: { list: [] },
  supplier: { list: [] },
  organizations: [],
};

const OrgsSlice = createSlice({
  name: 'orgs',
  initialState: initState,
  reducers: {
    getAllOrgs(state, { payload }) {
      state.organizations = payload;
    },
  },
});

export const getAllOrgsRequest = createAction('orgs/get_all_org_request');
export const addNewOrgRequest = createAction('orgs/add_new_org_request');
export const updateOrgRequest = createAction('orgs/update_org_request');
export const addNewOrgAdminRequest = createAction(
  'orgs/add_new_org_admin_request',
);
export const stateOrgRequest = createAction('orgs/state_org_request');

export const { getAllOrgs } = OrgsSlice.actions;

export default OrgsSlice;
