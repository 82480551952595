import { http } from 'services';
import { getHeader } from 'lib/helpers';
import { esignUrl } from './endpoints';

export const Esign = {
  index: () =>
    http.get(esignUrl, {
      headers: getHeader(),
    }),
  create: data =>
    http.post(esignUrl, data, {
      headers: getHeader(),
    }),
  update: (data, id) =>
    http.put(`${esignUrl}${id}/`, data, {
      headers: getHeader(),
    }),
  delete: id =>
    http.delete(`${esignUrl}${id}/`, {
      headers: getHeader(),
    }),
  getSingleById: id =>
    http.get(`${esignUrl}${id}/`, {
      headers: getHeader(),
    }),
};
