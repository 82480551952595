import { message } from 'antd';

export default function getValidatedImageFile(file, messages = {}) {
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
  if (!isJpgOrPng) {
    message.error(messages['You can only upload JPG/PNG file!']);
  }

  // Max filesize 5MB
  const isLt2M = file.size / 1024 / 1024 < 5;
  if (!isLt2M) {
    message.error(messages['Image must smaller than 5MB!']);
  }
  return isJpgOrPng && isLt2M;
}
