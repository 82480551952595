/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState } from 'react';
import { List, Avatar, Badge, AutoComplete } from 'antd';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import { MessageSidebarWrapper } from './Message.styles';
import { getChatUserListRequest, createChannelRequest } from './reducer';

function MessageSidebar({
  changeChannel,
  selectedUserId,
  channelList,
  setAllChannels,
  // page,
  setAllChats,
  setUserID,
}) {
  const intl = useIntl();
  const [options, setOptions] = useState([]);
  const dispatch = useDispatch();

  const activeChannel = {
    borderLeft: '5px solid #79CC8F',
    background: '#ECEDF0',
  };
  const onSearch = searchText => {
    setOptions([]);
    dispatch(
      getChatUserListRequest({
        searchString: searchText,
        setOptions,
      }),
    );
  };
  const onSelect = (selectText, option) => {
    console.log(option);
    setUserID(option.id);

    const data = {
      receiver: option.id,
    };
    const formData = new FormData();
    Object.keys(data).forEach(key => formData.append(key, data[key]));
    dispatch(
      createChannelRequest({
        data: formData,
        setAllChannels,
        setAllChats,
      }),
    );
    // setAllChats([]);
    // dispatch(getChatWithUserRequest({ id: option.id, page, setAllChats }));
    // changeChannel(selectedUserId, item.channel, 0)
  };
  return (
    <MessageSidebarWrapper className="msg_sidebar">
      <div className="search_user">
        <AutoComplete
          options={options}
          style={{ width: '100%' }}
          onSelect={onSelect}
          onSearch={onSearch}
          placeholder={intl.messages['Search user']}
          allowClear
        />
      </div>
      <List
        dataSource={channelList}
        onSelect={e => console.log(e)}
        renderItem={item => (
          // eslint-disable-next-line jsx-a11y/anchor-is-valid
          <a
            key={item.id}
            onClick={() =>
              changeChannel(item.userId, item.channel, item.unread_count)
            }
          >
            <List.Item
              style={selectedUserId === item.userId ? activeChannel : {}}
            >
              <List.Item.Meta
                avatar={<Avatar src={item.image} />}
                title={
                  // eslint-disable-next-line
                  <>
                    {item.name}
                    {item.unread_count > 0 && (
                      <Badge
                        count={item.unread_count}
                        style={{
                          backgroundColor: '#454D66',
                          marginLeft: 5,
                        }}
                      />
                    )}
                  </>
                }
                description={`${item.role}, ${item.org_name}`}
              />
              <div className="date">
                {moment(item.createdAt).format('MMM DD')}
              </div>
            </List.Item>
          </a>
        )}
      />
    </MessageSidebarWrapper>
  );
}

MessageSidebar.propTypes = {
  changeChannel: PropTypes.func,
  selectedUserId: PropTypes.string,
  channelList: PropTypes.arrayOf(PropTypes.any),
  setAllChannels: PropTypes.func,
  // page: PropTypes.number,
  setAllChats: PropTypes.func,
  setUserID: PropTypes.func,
};
export default MessageSidebar;
