import { createSlice, createAction } from '@reduxjs/toolkit';

const initState = { orgList: [], responsibleList: [], stepEsignList: [] };

const StepSLice = createSlice({
  name: 'steps',
  initialState: initState,
  reducers: {
    getAllOrgByProjectID(state, { payload }) {
      state.orgList = payload;
    },
    getAllResponsibleByPIDAndOrgID(state, { payload }) {
      state.responsibleList = payload;
    },
    getSingleStep(state, { payload }) {
      state.info = payload;
    },
    // getCommentList(state, { payload }) {
    //   state.commentList = payload;
    // },
    getEsignFormList(state, { payload }) {
      state.esignFormList = payload;
    },

    updateStep(state, { payload }) {
      state.info = {
        ...state.info,
        ...payload,
      };
    },
    getStepESignList(state, { payload }) {
      state.stepEsignList = payload;
    },
  },
});
export const getAllOrgByProjectIDRequest = createAction(
  'steps/get_all_orgs_by_projectId_request',
);
export const getAllResponsibleByPIDAndOrgIDRequest = createAction(
  'steps/get_all_responsible_by_pId_orgId_request',
);
export const getEsignFormListRequest = createAction(
  'steps/get_esign_form_list_by_orgId_request',
);
export const addNewStepRequest = createAction('steps/add_new_step_request');
export const getSingleStepRequest = createAction(
  'steps/get_single_step_request',
);
export const updateStepRequest = createAction('steps/update_step_request');

export const getStepESignListRequest = createAction('steps/step_esign_request');

export const startStepRequest = createAction('steps/start_step_request');

export const deleteStepRequest = createAction('steps/delete_step_request');

export const sendOTPRequest = createAction('steps/send_otp_request');

export const verifyOTPRequest = createAction('steps/verify_otp_request');

export const fileUploadRequest = createAction('steps/form_file_upload_request');

export const sendCommentStepRequest = createAction(
  'steps/send_comment_request',
);
export const getCommentListRequest = createAction(
  'steps/get_comment_list_request',
);
export const approveCommentRequest = createAction(
  'steps/approve_comment_request',
);

export const {
  getAllOrgByProjectID,
  getAllResponsibleByPIDAndOrgID,
  getSingleStep,
  // getCommentList,
  getEsignFormList,
  updateStep,
  getStepESignList,
} = StepSLice.actions;

export default StepSLice;
