import React from 'react';
import { FormattedMessage } from 'react-intl';
import { UserModal } from 'features/Users';
import PropTypes from 'prop-types';
import UpdateUserForm from './UpdateUserForm';

function UpdateUser({
  isModalVisible,
  extraFormData,
  setIsModalVisible,
  setUserInfo,
  query,
  userInfo,
}) {
  const handleCancel = () => {
    setIsModalVisible(false);
    setUserInfo({});
  };

  return (
    <>
      <UserModal
        centered
        title={<FormattedMessage id="Update User" />}
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={null}
        width="91rem"
      >
        {userInfo && (
          <UpdateUserForm
            handleModalClose={handleCancel}
            extraFormData={extraFormData}
            query={query}
            userInfo={userInfo}
          />
        )}
      </UserModal>
    </>
  );
}
UpdateUser.propTypes = {
  isModalVisible: PropTypes.bool,
  extraFormData: PropTypes.shape({
    id: PropTypes.number,
  }),
  setIsModalVisible: PropTypes.func,
  setUserInfo: PropTypes.func,
  query: PropTypes.string,
  userInfo: PropTypes.objectOf,
};

export default UpdateUser;
