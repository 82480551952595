/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import { Form, Input, Checkbox, Button, Row, Col, Upload } from 'antd';
import {
  DeleteOutlined,
  EditOutlined,
  UploadOutlined,
} from '@ant-design/icons';
import { useIntl, FormattedMessage } from 'react-intl';
import InfillMeasureFields from 'features/Esign/InfillMeasure/InfillMeasureFields';
import infillMeasureFormFields from 'features/Esign/InfillMeasure/formFields';
import EsignEditFormWrapper from './EsignEdit.styles';
import EsignFieldEdit from './EsignFieldEdit';
import { getEsignViewRequest, updateEsignRequest } from '../reducer';

const { Dragger } = Upload;
const { TextArea } = Input;

function EsignEdit() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const intl = useIntl();
  const [form] = Form.useForm();
  const orgId = useSelector(state => state.CurrentUser?.info.organization?.id);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalIndex, setIsModalindex] = useState(0);
  const history = useHistory();
  const [formName, setFormName] = useState('');
  const [formInfo, setFormInfo] = useState(null);
  // const [currentRole, setCurrentRole] = useState('');
  const [formFields, setFormFields] = useState(null);

  useEffect(() => {
    dispatch(getEsignViewRequest({ id, setFormInfo }));
  }, [dispatch]);

  useEffect(() => {
    if (formInfo) {
      const { name } = formInfo;
      form.setFieldsValue({
        form_name: name,
      });
      setFormName(name);
      setFormFields(formInfo.configuration);
    }
  }, [form, formInfo]);

  const eSignformFields = {
    name: '',
    configuration: [],
  };

  const checkBox = {
    type: 'checkbox',
    label: 'Edit checkbox label',
    placeholder: null,
    value: '',
  };
  const fileField = {
    type: 'file',
    label: 'Edit File label',
    placeholder: null,
    value: '',
  };
  const textBox = {
    type: 'text',
    label: 'Label name',
    placeholder: 'Enter your placeholder',
    value: '',
  };

  const addTextBox = () => {
    formFields.push(textBox);
    const newArr = [...formFields];
    setFormFields(newArr);
  };

  const addFileField = () => {
    formFields.push(fileField);
    const newArr = [...formFields];
    setFormFields(newArr);
  };

  const addCheckBox = () => {
    formFields.push(checkBox);
    const newArr = [...formFields];
    setFormFields(newArr);
  };

  const handleDelete = index => {
    const newArr = [...formFields];
    newArr.splice(index, 1);
    setFormFields(newArr);
  };

  const editFieldModal = i => {
    setIsModalindex(i);
    setIsModalVisible(true);
  };
  const updateFormFieldLabel = (i, e) => {
    formFields[i].label = e.target.value;
    const newArr = [...formFields];
    setFormFields(newArr);
  };
  const updateFormFieldPlaceholder = (i, e) => {
    formFields[i].placeholder = e.target.value;
    const newArr = [...formFields];
    setFormFields(newArr);
  };

  const updateFormName = e => {
    setFormName(e.target.value);
  };

  const updateFormTemplate = () => {
    eSignformFields.name = formName;
    eSignformFields.configuration = formFields;
    if (orgId) {
      eSignformFields['organization'] = orgId;
    }

    dispatch(
      updateEsignRequest({
        data: JSON.stringify(eSignformFields),
        succMessage: intl.messages['Form teamplate successfully updated!'],
        id,
      }),
    );
    history.push('/dashboard/e-sign-form');
  };
  const cancelTask = () => {
    history.push('/dashboard/e-sign-form');
  };

  const addInfillMeasure = () => {
    formFields.push({ measure: infillMeasureFormFields });
    const newArr = [...formFields];
    setFormFields(newArr);
  };

  const checkAddInfillMeasure =
    formFields?.find(item => item?.measure) || false;

  return (
    <EsignEditFormWrapper>
      <div className="form-header">
        <h2>
          <FormattedMessage id="Edit E-SIGN form" />
        </h2>
      </div>
      <div className="esign_form_wrapper">
        <Form
          name="add_new_e-sign_tepmplate"
          onFinish={updateFormTemplate}
          labelCol={{ span: 24 }}
          form={form}
        >
          <Row gutter={50}>
            <Col span={24}>
              <Form.Item
                label={intl.messages['E-SIGN form title']}
                name="form_name"
                rules={[
                  {
                    required: true,
                  },
                ]}
                hasFeedback
                onChange={e => updateFormName(e)}
              >
                <Input
                  placeholder={intl.messages['Enter form name']}
                  size="large"
                />
              </Form.Item>
            </Col>
          </Row>

          <div className="esign_form_inner">
            <h3>
              <FormattedMessage id="Form Elements" />
            </h3>

            <div className="esign_form_inner_fields">
              {formFields?.map((field, i) => {
                if (field.type === 'text') {
                  return (
                    <Row
                      // eslint-disable-next-line
                      key={i}
                      type="flex"
                      align="center"
                      gutter={[16]}
                    >
                      <Col span={21}>
                        <Form.Item
                          label={field.label}
                          name={`field_${i}`}
                          hasFeedback
                        >
                          <TextArea
                            placeholder={field.placeholder}
                            size="large"
                            rows={3}
                            disabled
                          />
                        </Form.Item>
                      </Col>
                      <Col className="field_action" span={3}>
                        <EditOutlined onClick={() => editFieldModal(i)} />
                        <DeleteOutlined onClick={() => handleDelete(i)} />
                      </Col>
                    </Row>
                  );
                }
                if (field.type === 'checkbox') {
                  return (
                    <Row
                      // eslint-disable-next-line
                      key={i}
                      type="flex"
                      align="center"
                      gutter={[16]}
                    >
                      <Col span={21}>
                        <Form.Item name={`field_${i}`} valuePropName="checked">
                          <Checkbox disabled>{field.label}</Checkbox>
                        </Form.Item>
                      </Col>
                      <Col className="field_action" span={3}>
                        <EditOutlined onClick={() => editFieldModal(i)} />
                        <DeleteOutlined onClick={() => handleDelete(i)} />
                      </Col>
                    </Row>
                  );
                }
                if (field.type === 'file') {
                  return (
                    <Row
                      // eslint-disable-next-line
                      key={i}
                      type="flex"
                      align="center"
                      gutter={[16]}
                    >
                      <Col span={21}>
                        <Form.Item
                          valuePropName="filelist"
                          label={field.label}
                          name={`field_${i}`}
                        >
                          <Dragger disabled>
                            <p className="ant-upload-drag-icon">
                              <UploadOutlined />
                            </p>
                            <p className="ant-upload-text">
                              <FormattedMessage id="Drag files here to upload" />
                            </p>
                          </Dragger>
                        </Form.Item>
                      </Col>
                      <Col className="field_action" span={3}>
                        <EditOutlined onClick={() => editFieldModal(i)} />
                        <DeleteOutlined onClick={() => handleDelete(i)} />
                      </Col>
                    </Row>
                  );
                }
                if (field?.measure) {
                  return (
                    <InfillMeasureFields
                      fields={field}
                      key={i}
                      handleDelete={handleDelete}
                      itemIndex={i}
                      hasDelete
                      editFieldModal={editFieldModal}
                    />
                  );
                }
                return null;
              })}

              <EsignFieldEdit
                isModalVisible={isModalVisible}
                setIsModalVisible={setIsModalVisible}
                arrIndex={modalIndex}
                updateFormFieldLabel={updateFormFieldLabel}
                updateFormFieldPlaceholder={updateFormFieldPlaceholder}
                formFields={formFields}
              />

              <Row type="flex" justify="space-between" gutter="20">
                <Col>
                  <Form.Item>
                    <Button
                      type="dashed"
                      onClick={() => addCheckBox()}
                      size="large"
                    >
                      <FormattedMessage id="Add Check Box" />
                    </Button>
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item>
                    <Button
                      type="dashed"
                      onClick={() => addTextBox()}
                      size="large"
                    >
                      <FormattedMessage id="Add Text Box" />
                    </Button>
                  </Form.Item>
                </Col>
                <Form.Item>
                  <Button
                    size="large"
                    type="dashed"
                    onClick={() => addFileField()}
                  >
                    <FormattedMessage id="Add File Field" />
                  </Button>
                </Form.Item>
                <Form.Item>
                  <Button
                    size="large"
                    type="dashed"
                    onClick={() => addInfillMeasure()}
                    disabled={checkAddInfillMeasure}
                  >
                    <FormattedMessage id="Add Infill Measure" />
                  </Button>
                </Form.Item>
                <Col />
              </Row>
            </div>
          </div>

          <Row className="mt-2" type="flex" justify="center" gutter="70">
            <Col>
              <Form.Item>
                <Button onClick={cancelTask} size="large">
                  <FormattedMessage id="Cancel" />
                </Button>
              </Form.Item>
            </Col>
            <Form.Item>
              <Button size="large" type="primary" htmlType="submit">
                <FormattedMessage id="Update" />
              </Button>
            </Form.Item>
            <Col />
          </Row>
        </Form>
      </div>
    </EsignEditFormWrapper>
  );
}

export default EsignEdit;
