import React, { memo } from 'react';
import { Card as AntCard, Avatar } from 'antd';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { tint, shade } from 'polished';
import gmGroupIcon from 'assets/icons/gm.svg';
import customerGroupIcon from 'assets/icons/customer.svg';
import supplierGroupIcon from 'assets/icons/supplier.svg';
import { PRIVATE_ROUTE } from 'router';
import EsignCardAction from './EsignCardAction';

const { Meta } = AntCard;

const Card = styled(AntCard)`
  box-shadow: ${props => props.theme.shadowLight};
  border: 1px solid ${props => tint(0.9, props.theme.secondary)};
  border-radius: 10px;
  min-height: 144px;

  .ant-avatar {
    width: 80px;
    height: 80px;
    margin: 40px auto;
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${props => tint(0.9, props.theme.secondary)};
    border-radius: 10px;
  }
  .ant-avatar > img {
    object-fit: contain;
    height: 47px;
  }
  .ant-card-body {
    padding: 24px;
    border-top: 1px solid ${props => tint(0.9, props.theme.secondary)};
    position: relative;
    .bottom_action {
      position: absolute;
      right: 39px;
      bottom: 24px;
      transform: rotate(90deg);
      transform-origin: bottom right;
      .anticon {
        font-size: 30px;
      }
    }
  }
  .ant-card-meta {
    display: flex;

    &-title {
      color: ${props => shade(0.1, props.theme.secondary)};
      font-size: 16px;
    }

    &-description {
      color: ${props => tint(0.4, props.theme.secondary)};
      font-size: 12px;
    }
  }
`;

function EsignCard({
  esign: {
    name = '',
    id,
    created_by: { first_name, last_name, group },
  },
}) {
  const intl = useIntl();
  let userGroupIcon = gmGroupIcon;
  const currentRole = group.split('-')[0];
  if (currentRole === 'customer') {
    userGroupIcon = customerGroupIcon;
  } else if (currentRole === 'supplier') {
    userGroupIcon = supplierGroupIcon;
  }
  const userName = `${intl.messages['Added by']} ${first_name} ${last_name}`;
  return (
    <Card
      style={{ width: '340' }}
      // eslint-disable-next-line
      cover={(
        <Link to={`${PRIVATE_ROUTE.E_SIGN_FORM}/${id}/`}>
          <Avatar shape="square" src={userGroupIcon} />
        </Link>
        // eslint-disable-next-line
      )}
    >
      <Meta title={name} description={userName} />
      <EsignCardAction id={id} />
    </Card>
  );
}

EsignCard.propTypes = {
  esign: PropTypes.shape({
    name: PropTypes.string,
    id: PropTypes.number,
    created_by: PropTypes.shape({
      first_name: PropTypes.string,
      last_name: PropTypes.string,
      group: PropTypes.string,
    }),
  }),
};

export default memo(EsignCard);
