import React, { useEffect } from 'react';
import { Row } from 'antd';
import { PlusCircleOutlined } from '@ant-design/icons';
import { OrgCard, AddOrgCard } from 'features/Organizations';
import { useDispatch, useSelector } from 'react-redux';
import { ShowForPermission } from 'components/utility';
import { getAllOrgsRequest } from '../reducer';

function CustomerList() {
  const dispatch = useDispatch();
  const list = useSelector(state => state.Orgs.organizations);
  useEffect(() => {
    dispatch(getAllOrgsRequest());
  }, [dispatch]);

  return (
    <Row gutter={[24, 24]}>
      <ShowForPermission
        permission={['can_add_new_customer', 'can_add_new_supplier']}
      >
        {/* <Col span={6}> */}
        <div style={{ minWidth: '293px', margin: '12px' }}>
          <AddOrgCard
            title="Add customer"
            url="customers/new"
            icon={<PlusCircleOutlined />}
          />
        </div>
        {/* </Col> */}
      </ShowForPermission>
      {list
        .filter(t => t.org_type === 'customer')
        .map(org => (
          // <Col key={org.id} span={6}>
          <div
            key={org.id}
            style={{ minWidth: '293px', maxWidth: '293px', margin: '12px' }}
          >
            <OrgCard org={org} />
          </div>
          // </Col>
        ))}
    </Row>
  );
}

export default CustomerList;
