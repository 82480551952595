import styled from 'styled-components';
import { tint } from 'polished';

const EsignEditFormWrapper = styled.div`
  .ant-form-item-control-input-content {
    .ant-input:not(textarea) {
      height: 48px;
      border-radius: 5px !important;
    }
  }

  .ant-upload.ant-upload-drag {
    border-radius: 5px;
    .ant-upload {
      padding: 46px 0;
    }
    .ant-upload-drag-icon {
      margin-bottom: 0;
    }
    .anticon-upload svg {
      font-size: 18px;
      color: #c7cad1;
    }
    .ant-upload-text {
      color: #c7cad1;
      font-size: 14px;
      line-height: 155%;
    }
  }

  .ant-form-item-label {
    label {
      font-weight: 500;
      font-size: 16px;
      line-height: 155%;
      color: #3e455c;
    }
  }

  .form-header {
    padding: 24px 40px;
    background: #fff;
    box-shadow: 0px 8px 16px rgba(109, 109, 109, 0.08);
    border-radius: 14px 14px 0px 0px;
    position: relative;
    z-index: 9;
    h2 {
      margin: 0;
      color: #3fb65f;
      font-weight: bold;
      font-size: 16px;
      line-height: 1.55;
      span {
        text-transform: capitalize;
      }
    }
  }
  .esign_form_wrapper {
    background: #fff;
    padding: 40px;
    border-radius: 0 0 14px 14px;
  }
  .esign_form_inner {
    border-radius: 5px;
    border: 1px solid ${props => tint(0.6, props.theme.orange)};
    h3 {
      padding: 16px;
      font-weight: bold;
      font-size: 14px;
      color: ${props => tint(0.1, props.theme.orange)};
      border-bottom: 1px solid ${props => tint(0.6, props.theme.orange)};
    }
    .esign_form_inner_fields {
      padding: 32px 24px 32px;
      .field_action {
        text-align: center;
      }
      .anticon-edit {
        font-size: 24px;
        margin-right: 10px;
        &:hover {
          color: ${props => tint(0.1, props.theme.orange)};
        }
      }
      .anticon-delete {
        font-size: 24px;
        &:hover {
          color: ${props => tint(0.1, props.theme.orange)};
        }
      }
    }
    .ant-btn-dashed {
      min-width: 200px;
      min-height: 48px;
      &:not(:hover) {
        color: ${props => tint(0.6, props.theme.secondary)};
      }
    }
  }
`;

export default EsignEditFormWrapper;
