import { takeLatest, put, call } from 'redux-saga/effects';
import { Esign } from 'api';
import { message } from 'antd';
import {
  getAllEsignRequest,
  addNewEsignRequest,
  getEsignViewRequest,
  updateEsignRequest,
  getAllEsign,
  deleteEsignRequest,
} from './reducer';

export function* getAllEsignSaga() {
  const res = yield call(Esign.index);
  if (res.data) {
    yield put(getAllEsign(res.data));
  }
}

export function* getEsignViewSaga({ payload: { id, setFormInfo } }) {
  const res = yield call(Esign.getSingleById, id);
  if (res.data) {
    setFormInfo(res.data);
    // setCurrentRole(res.data.created_by?.group);
  }
}

export function* addNewEsignSaga({ payload: { data, succMessage } }) {
  const res = yield call(Esign.create, data);

  if (res.data) {
    message.success(succMessage);
    yield call(getAllEsignSaga);
  }
}

export function* updateEsignSaga({ payload: { data, succMessage, id } }) {
  const res = yield call(Esign.update, data, id);

  if (res.data) {
    message.success(succMessage);
  }
}

export function* deleteEsignViewSaga({ payload: { id, succMessage } }) {
  const res = yield call(Esign.delete, id);

  if (res.status === 204) {
    message.success(succMessage);
    yield call(getAllEsignSaga);
  }
}

export default function* watchEsignActions() {
  yield takeLatest(getAllEsignRequest.toString(), getAllEsignSaga);
  yield takeLatest(getEsignViewRequest.toString(), getEsignViewSaga);
  yield takeLatest(addNewEsignRequest.toString(), addNewEsignSaga);
  yield takeLatest(updateEsignRequest.toString(), updateEsignSaga);
  yield takeLatest(deleteEsignRequest.toString(), deleteEsignViewSaga);
}
