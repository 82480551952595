import styled from 'styled-components';

export const StepListWrapper = styled.div`
  .order_info_left {
    background: #fff;
    padding: 32px;
    box-shadow: 0px 8px 14px rgba(29, 48, 33, 0.03);
    border-radius: 10px;
    .order_info_left_location,
    .order_info_left_attachments,
    .order_info_left_accountable,
    .order_info_left_desc {
      color: #7d8294;
      font-weight: normal;
      font-size: 16px;
      line-height: 155%;
      text-align: justify;
      margin: 0 0 32px;
      h3 {
        color: #3fb65f;
        font-weight: 500;
        font-size: 16px;
        line-height: 155%;
        text-align: left;
        margin: 0 0 8px;
      }
      p {
        margin: 0 0 24px;
        &:last-child {
          margin: 0;
        }
      }
    }
    .order_info_left_attachments {
      ul {
        padding: 0;
        margin: 0;
        li {
          display: inline-block;
          margin-right: 20px;
          &:last-child {
            margin-right: 0;
          }
          .anticon {
            margin-right: 5px;
          }
        }
      }
    }
    .order_info_left_accountable {
      margin: 0;
      ul {
        padding: 0;
        margin: 0;
        li {
          display: inline-block;
          margin-right: 10px;
          &:last-child {
            margin-right: 0;
          }
          img {
            background: #3fb65f;
            box-shadow: 0px 8px 16px rgba(109, 109, 109, 0.08);
            margin: 0px 8px;
            padding: 3px;
            display: inline-block;
            border-radius: 5px;
            height: 25px;
            width: 25px;
          }
        }
      }
    }
  }
  .order_info_right {
    background: #fff;
    font-weight: normal;
    font-size: 16px;
    line-height: 155%;
    box-shadow: 0px 8px 14px rgba(29, 48, 33, 0.03);
    border-radius: 10px;
    .disabled-link {
      cursor: auto;
    }
    .order_info_right_header {
      border-bottom: 1px solid #ecedf0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 16px 32px;
      .left {
        color: #454d66;
        font-weight: bold;
        font-size: 18px;
        line-height: 155%;
      }
      .right {
        color: #454d66;
        font-weight: 500;
        font-size: 16px;
        line-height: 155%;
        img {
          height: 16px;
          margin-right: 10px;
        }
      }
    }
    .order_steps {
      padding: 2em;
      min-height: 475px;
      .ant-card {
        background: #fff;
        border-radius: 0.8em;
        margin-bottom: 1.5em;
        .ant-card-meta {
          display: flex;
          align-items: center;
        }
        .ant-card-meta-avatar {
          font-size: 18px;
          text-align: center;
          padding: 0;
          line-height: 4em;
          margin-right: 1.5em;
          color: #fff;
          font-weight: 500;
        }
        .ant-card-meta-title {
          font-weight: 500;
          font-size: 17px;
          color: #fff;
        }
        .ant-card-meta-description {
          font-size: 16px;
          color: #fff;
        }
      }
      .btn_container {
        text-align: center;
        .ant-btn {
          height: 48px;
          min-width: 196px;
          font-size: 16px;
          color: #3fb65f;
        }
      }
    }
    .complete_steps {
      h2 {
        color: #3fb65f;
        background: #ecf8ef;
        padding: 0.8em 2em;
        font-size: 16px;
        font-weight: bold;
      }
      .complete_step_items {
        padding: 2em 2em 2.5em;
        span {
          display: inline-block;
          height: 64px;
          width: 64px;
          line-height: 64px;
          background: #65c57f;
          border-radius: 50%;
          font-size: 18px;
          font-weight: bold;
          text-align: center;
          color: #fff;
          margin-right: 24px;
          &:last-child {
            margin-right: 0;
          }
        }
      }
    }
  }
`;
