import styled from 'styled-components';

export const InfoCardWrapper = styled.div`
.ant-card {
    min-height: 118px;
    box-shadow: 0px 6px 11px rgba(29, 48, 33, 0.13);
    border-radius: 8px;
    background: #F0F2F5;
    .ant-card-body {
      padding: 16px 14px;
    }
    .ant-card-meta {
      display: flex;
  
      .ant-avatar {
        width: 50px;
        height: 50px;
        border-radius: 10%;
      }
  
      &-title {
        font-weight: bold;
        font-size: 18px;
        line-height: 30px;
      }
  
      &-description {
        font-size: 15px;
        letter-spacing: 0.2px;
        line-height: 19px;
        font-weight: 500;
      }
}
`;
