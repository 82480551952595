import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

function ShowForPermission({ permission, children }) {
  const userPermissions = useSelector(
    state => state.CurrentUser.info.permissions,
  );
  const couldShow =
    userPermissions.some(val => permission.includes(val)) ||
    permission.includes('all');
  return <>{couldShow ? children : null}</>;
}

ShowForPermission.propTypes = {
  permission: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired,
};

export default ShowForPermission;
