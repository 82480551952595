import { takeLatest, put, call } from 'redux-saga/effects';
import { Message } from 'api';
import {
  getChannelListRequest,
  getChatUserListRequest,
  createChannelRequest,
  getChatWithUserRequest,
  getChatListWithUser,
  sendChatRequest,
  channelReadAllRequest,
  getUnreadMessageCount,
  getUnreadMsgCountRequest,
} from './reducer';

export function* getChannelListSaga({ payload: { setAllChannels } }) {
  const res = yield call(Message.index);
  if (res.data) {
    setAllChannels(res.data);
  }
}
export function* createChannelSaga({
  payload: { data, setAllChannels, setAllChats },
}) {
  const res = yield call(Message.channelCreate, data);
  if (res.data) {
    setAllChats([]);
    yield call(getChannelListSaga, {
      payload: { setAllChannels },
    });
  }
}

export function* getChatUserListSaga({
  payload: { searchString, setOptions },
}) {
  const res = yield call(Message.getChatUsers, searchString);
  if (res.data) {
    if (res.data.length > 0) {
      const list = res.data.map(item => {
        return {
          value: `${item.first_name} ${item.last_name}`,
          id: item.id,
        };
      });
      setOptions(list);
    }
  }
}

export function* getChatWithUserSaga({ payload: { id, page, setAllChats } }) {
  const res = yield call(Message.getChats, id, page);
  if (res.data) {
    setAllChats(state => state.concat(res.data.content));
    yield put(getChatListWithUser(res.data));
  }
}

export function* sendChatSaga({
  payload: { formData, resetForm, setAllChats },
}) {
  const res = yield call(Message.sendMesg, formData);
  if (res?.status === 201) {
    resetForm();
    setAllChats(state => [res.data, ...state]);
  }
}
export function* channelReadAllSaga({ payload: { formData, setAllChannels } }) {
  const res = yield call(Message.readAll, formData);
  if (res?.status === 200) {
    yield call(getChannelListSaga, {
      payload: { setAllChannels },
    });
  }
}

export function* getUnreadMsgCountSaga() {
  const res = yield call(Message.unreadMesg);
  if (res?.data) {
    yield put(getUnreadMessageCount(res.data.count));
  }
}

export default function* watchMessageActions() {
  yield takeLatest(getChannelListRequest.toString(), getChannelListSaga);
  yield takeLatest(getChatUserListRequest.toString(), getChatUserListSaga);
  yield takeLatest(createChannelRequest.toString(), createChannelSaga);
  yield takeLatest(getChatWithUserRequest.toString(), getChatWithUserSaga);
  yield takeLatest(sendChatRequest.toString(), sendChatSaga);
  yield takeLatest(channelReadAllRequest.toString(), channelReadAllSaga);
  yield takeLatest(getUnreadMsgCountRequest.toString(), getUnreadMsgCountSaga);
}
