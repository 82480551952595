import { takeLatest, put, call } from 'redux-saga/effects';
import { Users } from 'api';
import { message } from 'antd';
import { getCurrentUser } from 'features/UserSettings';
import { login, loginRequest, forgotPasswordRequest } from './reducer';

export function* loginSaga({ payload }) {
  const res = yield call(Users.login, payload);

  if (res.data && res.data.token) {
    message.success('Successfully logged in!');
    yield put(login(res.data.token));
    delete res.data.token;
    yield put(getCurrentUser(res.data));
  }
}

export function* forgotPasswordSaga({ payload }) {
  const res = yield call(Users.forgotPass, payload);

  if (res.data) {
    message.success('Please check your email to change your password!');
  }
}

export default function* watchAuthActions() {
  yield takeLatest(loginRequest.toString(), loginSaga);
  yield takeLatest(forgotPasswordRequest.toString(), forgotPasswordSaga);
}
