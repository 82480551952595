import styled from 'styled-components';

export const Wrapper = styled.div`
  /* padding: 0; */
  background-color: #fff;
  box-shadow: ${props => props.theme.shadow};
  border: 1px solid ${props => props.theme.border};
  overflow: hidden;
  width: ${props => (props.width ? props.width : '100%')};
`;
