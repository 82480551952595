import { http } from 'services';
import { getHeader } from 'lib/helpers';
import { notificationUrl } from './endpoints';

export const Notification = {
  getUnreadCount: () =>
    http.get(`${notificationUrl}unread_count/`, {
      headers: getHeader(),
    }),
  getNotification: (is_read, offset, limit) =>
    http.get(
      `${notificationUrl}?${
        is_read ? `is_read=${Number(is_read)}&` : ``
      }limit=${limit}&offset=${offset}
      `,
      {
        headers: getHeader(),
      },
    ),
  readNotification: id =>
    http.post(`${notificationUrl}${id}/read_notification/`, id, {
      headers: getHeader(),
    }),
  readAllNotification: () =>
    http.post(
      `${notificationUrl}read_all_notifications/`,
      {},
      {
        headers: getHeader(),
      },
    ),
};
