import React from 'react';
import { Row, Col } from 'antd';
import { FormattedMessage } from 'react-intl';
import ReportFilter from './ReportFilter';
import HeatMap from './HeatMap';

const BarCharts = React.lazy(() => import('./BarChart'));

function Report() {
  return (
    <>
      <h2 className="font-bold">
        <FormattedMessage id="Generate Field Report" />
      </h2>
      <ReportFilter />
      <HeatMap />
      <Row gutter={16}>
        <Col span={14}>
          <React.Suspense fallback="">
            <BarCharts />
          </React.Suspense>
        </Col>
      </Row>
    </>
  );
}

export default Report;
