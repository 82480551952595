import { takeLatest, put, call } from 'redux-saga/effects';
import { Users, UserRoles } from 'api';
import { message } from 'antd';
import { getAllOrgsUserSaga } from 'features/OrgDetails/saga';
import { logout } from 'features/UserAuth';
import { removeCurrentUser } from 'features/UserSettings';
import {
  getAllUsersRequest,
  getAllUsers,
  addNewUserRequest,
  getAllUserRolesRequest,
  getAllUserRoles,
  activateUserRequest,
  resetPasswordRequest,
  getAllCountryCode,
  countryCodeRequest,
  deleteUserRequest,
  updateUserRequest,
} from './reducer';

export function* getAllUsersSaga({ payload }) {
  const res = yield call(Users.index, payload?.query);
  if (res.data) {
    yield put(getAllUsers(res.data));
  }
}

export function* getAllUserRolesSaga({ payload }) {
  const res = yield call(UserRoles.index, payload?.query);
  if (res.data) {
    yield put(getAllUserRoles(res.data));
  }
}

export function* addNewUserSaga({
  payload: { formData, resetForm, succMessage, handleModalClose, query, orgId },
}) {
  const res = yield call(Users.register, formData);

  if (res.data) {
    message.success(succMessage);
    resetForm();
    handleModalClose();
    yield call(getAllUsersSaga, { payload: { query } });
    yield call(getAllOrgsUserSaga, { payload: { id: orgId } });
  }
}

export function* activateUserSaga({
  payload: { formData, history, succMessage },
}) {
  const res = yield call(Users.acitvate, formData);

  if (res.data) {
    yield put(logout());
    yield put(removeCurrentUser());
    message.success(succMessage);
    history.push('/signin');
  }
}

export function* resetPasswordSaga({
  payload: { formData, history, succMessage },
}) {
  const res = yield call(Users.resetPass, formData);

  if (res.data) {
    message.success(succMessage);
    history.push('/signin');
  }
}
export function* countryCodeSaga() {
  const res = yield call(Users.countryCode);
  if (res?.data) {
    yield put(getAllCountryCode(res.data));
  }
}
export function* deleteUserSaga({ payload: { user_id, succMessage, query } }) {
  const res = yield call(Users.deleteUser, user_id);
  if (res?.status === 204) {
    message.success(succMessage);
    yield call(getAllUsersSaga, { payload: { query } });
  }
}
export function* updateUserSaga({
  payload: { formData, id, resetForm, succMessage, handleModalClose, query },
}) {
  const res = yield call(Users.updateUser, id, formData);
  if (res?.data) {
    message.success(succMessage);
    resetForm();
    handleModalClose();
    yield call(getAllUsersSaga, { payload: { query } });
  }
}

export default function* watchUsersActions() {
  yield takeLatest(getAllUsersRequest.toString(), getAllUsersSaga);
  yield takeLatest(addNewUserRequest.toString(), addNewUserSaga);
  yield takeLatest(getAllUserRolesRequest.toString(), getAllUserRolesSaga);
  yield takeLatest(activateUserRequest.toString(), activateUserSaga);
  yield takeLatest(resetPasswordRequest.toString(), resetPasswordSaga);
  yield takeLatest(countryCodeRequest.toString(), countryCodeSaga);
  yield takeLatest(deleteUserRequest.toString(), deleteUserSaga);
  yield takeLatest(updateUserRequest.toString(), updateUserSaga);
}
