import React, { memo } from 'react';
import { Card as AntCard, Avatar } from 'antd';
// import { MessageOutlined, EditOutlined } from '@ant-design/icons';
import userAvatar from 'assets/images/user-avatar.jpg';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { tint } from 'polished';
import { useIntl } from 'react-intl';
// import { Link } from 'react-router-dom';
// import { PRIVATE_ROUTE } from 'router';

const { Meta } = AntCard;

const Card = styled(AntCard)`
  box-shadow: ${props => props.theme.shadowLight};
  border: 1px solid ${props => tint(0.9, props.theme.secondary)};
  border-radius: 5px;

  .ant-card-meta {
    display: flex;
    align-items: center;

    .ant-avatar {
      width: 6rem;
      height: 6rem;
    }

    &-title {
      color: ${props => props.theme.secondary};
    }

    &-description {
      color: ${props => tint(0.3, props.theme.secondary)};
      font-size: 1.4rem;
    }
  }
`;

function OrgUserCard({
  user: {
    email = '',
    first_name = '',
    last_name = '',
    group = '',
    organization = {},
    image = '',
  },
}) {
  const adminGroupName = groupName => groupName.replace('-', ' ');
  const name = organization?.name;
  const { messages } = useIntl();

  return (
    // <Link to="/">
    <Card
      style={{ width: '340' }}
      // actions={[<EditOutlined key="edit" />, <MessageOutlined key="message" />]}
    >
      <Meta
        avatar={<Avatar src={image || userAvatar} />}
        title={`${first_name} ${last_name}`}
        description={`${messages['Customer Role']}: ${adminGroupName(group)}, ${
          messages['Organization Name']
        }: ${name}, ${messages['Email']}: ${email}`}
      />
    </Card>
    // </Link>
  );
}

OrgUserCard.propTypes = {
  user: PropTypes.shape({
    email: PropTypes.string,
    image: PropTypes.string,
    name: PropTypes.string,
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    organization: PropTypes.object,
    group: PropTypes.string,
  }),
};

export default memo(OrgUserCard);
