import React from 'react';
import { useHistory, Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Input, Button, Row, Col, Form } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import { loginRequest } from 'features/UserAuth/reducer';
import getValidateMessages from 'lib/helpers/validators';
import { PUBLIC_ROUTE } from 'router/routePaths';

function UserLoginForm() {
  const history = useHistory();
  const dispatch = useDispatch();
  const intl = useIntl();

  const onFinish = ({ email, password }) => {
    dispatch(loginRequest({ email, password }));
    history.push('/dashboard');
  };

  return (
    <Form
      name="loginForm"
      onFinish={onFinish}
      validateMessages={getValidateMessages(intl.messages, 'name')}
    >
      <Form.Item
        name="email"
        rules={[
          {
            required: true,
            type: 'email',
          },
        ]}
        hasFeedback
      >
        <Input size="large" placeholder={intl.messages['Username/Email']} />
      </Form.Item>

      <Form.Item
        name="password"
        rules={[
          {
            required: true,
          },
          {
            min: 8,
          },
        ]}
        hasFeedback
      >
        <Input.Password size="large" placeholder={intl.messages['Password']} />
      </Form.Item>

      <Row
        className="mt-2 mb-1"
        type="flex"
        justify="space-between"
        align="middle"
      >
        <Col>
          <Link to={PUBLIC_ROUTE.FORGET_PASSWORD}>
            <FormattedMessage id="Forgot password?" />
          </Link>
        </Col>
        <Col>
          <Button size="large" type="primary" htmlType="submit">
            <FormattedMessage id="Sign in" />
          </Button>
        </Col>
      </Row>
      <Form.Item />
    </Form>
  );
}

export default UserLoginForm;
