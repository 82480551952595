import React from 'react';
import PropTypes from 'prop-types';
import { Scrollbars } from 'react-custom-scrollbars';
import { Link } from 'react-router-dom';
// import { Pagination } from 'antd';
import { PRIVATE_ROUTE } from 'router';
import { Pagination } from 'components/uielements';
import { useIntl } from 'react-intl';

const ListView = ({ list, count, changePagination }) => {
  const intl = useIntl();
  return (
    <>
      <Scrollbars
        style={{ width: '100%', height: 800 }}
        autoHide
        autoHideTimeout={1000}
        autoHideDuration={200}
      >
        <div className="order_items">
          {list &&
            list.length > 0 &&
            list.map(item => (
              <div className="order_item" key={item.id}>
                <div className="order_item_content">
                  <h3>{item.name}</h3>
                  <p>
                    {intl.messages['Status:']}
                    {` ${item.status}`}
                  </p>
                </div>
                <Link
                  className="order_view"
                  to={{
                    pathname: `/dashboard/${PRIVATE_ROUTE.PROJECTS}/${item.id}/`,
                  }}
                >
                  {intl.messages['View More']}
                </Link>
              </div>
            ))}
        </div>
      </Scrollbars>
      <div style={{ textAlign: 'center', paddingTop: 10 }}>
        <Pagination
          defaultCurrent={1}
          total={count}
          onChange={changePagination}
        />
      </div>
    </>
  );
};
ListView.propTypes = {
  list: PropTypes.arrayOf(PropTypes.any),
  count: PropTypes.number,
  changePagination: PropTypes.func,
};
export default ListView;
