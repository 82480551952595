import React, { useEffect } from 'react';
import { Row, Col } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { ShowForPermission } from 'components/utility';
import { getAllEsignRequest } from './reducer';
import EsignCard from './EsignCard';
import EsignAddCard from './EsignAddCard';

function Esign() {
  const dispatch = useDispatch();
  const list = useSelector(state => state.Esign.list);
  useEffect(() => {
    dispatch(getAllEsignRequest());
  }, [dispatch]);

  return (
    <Row gutter={[24, 24]}>
      {list.map(esign => (
        <Col key={esign.id} span={6}>
          <EsignCard esign={esign} />
        </Col>
      ))}

      <ShowForPermission permission={['can_create_e_sign_form']}>
        <Col span={6}>
          <EsignAddCard
            title="Add new form"
            url="e-sign-form/new"
            icon={<PlusOutlined />}
          />
        </Col>
      </ShowForPermission>
    </Row>
  );
}

export default Esign;
