import React from 'react';
import { PRIVATE_ROUTE } from 'router';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { useIntl, FormattedMessage } from 'react-intl';
import { Popconfirm, message } from 'antd';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { deleteEsignRequest } from '../reducer';

const ActionContetnWrapper = styled.div`
  font-size: 18px;
  padding: 3px 10px;
  a {
    color: inherit;
  }
  div:hover {
    color: ${props => props.theme.primary};
  }
`;

function EsignCardActionContent({ id }) {
  const intl = useIntl();
  const dispatch = useDispatch();

  const esignDelete = () => {
    dispatch(
      deleteEsignRequest({
        id,
        succMessage: intl.messages['Form teamplate successfully Deleted!'],
      }),
    );
  };
  const esignDeleteCancel = () => {
    message.error(intl.messages['From template deletion canceled']);
  };

  return (
    <ActionContetnWrapper>
      <div style={{ marginBottom: '7px' }}>
        <Link to={`${PRIVATE_ROUTE.E_SIGN_FORM}/${id}/${PRIVATE_ROUTE.EDIT}`}>
          <FormattedMessage id="Edit" />
        </Link>
      </div>
      <div style={{ cursor: 'pointer' }}>
        <Popconfirm
          title={<FormattedMessage id="Are you sure?" />}
          onConfirm={esignDelete}
          onCancel={esignDeleteCancel}
          okText={<FormattedMessage id="Yes" />}
          cancelText={<FormattedMessage id="No" />}
        >
          <span>
            <FormattedMessage id="Delete" />
          </span>
        </Popconfirm>
      </div>
    </ActionContetnWrapper>
  );
}

EsignCardActionContent.propTypes = {
  id: PropTypes.number,
};

export default EsignCardActionContent;
