import { takeLatest, put, call } from 'redux-saga/effects';
import { Orgs } from 'api';
import { message } from 'antd';
import { getSingleOrganization } from 'features/OrgDetails/reducer';
import {
  getAllOrgsRequest,
  addNewOrgRequest,
  updateOrgRequest,
  addNewOrgAdminRequest,
  getAllOrgs,
  stateOrgRequest,
} from './reducer';

export function* getAllOrgsSaga() {
  const res = yield call(Orgs.index);
  if (res.data) {
    yield put(getAllOrgs(res.data));
  }
}

export function* addNewOrgsSaga({
  payload: { formData, setFormStep, setOrgInfo, resetForm, succMessage },
}) {
  const res = yield call(Orgs.create, formData);

  if (res.data) {
    message.success(succMessage);
    setOrgInfo(res.data);
    resetForm();
    setFormStep(false);
    yield call(getAllOrgsSaga);
  }
}

export function* updateOrgsSaga({
  payload: { formData, resetForm, succMessage, id },
}) {
  const res = yield call(Orgs.update, formData, id);

  if (res.data) {
    message.success(succMessage);
    resetForm();
    yield put(getSingleOrganization(res.data));
  }
}

export function* addNewOrgAdminSaga({
  payload: { formData, setFormStep, resetForm, succMessage },
}) {
  const res = yield call(Orgs.register, formData);

  if (res.data) {
    message.success(succMessage);
    setFormStep(false);
    resetForm();
    // yield call(getAllOrgsSaga);
  }
}

export function* stateOrgSaga({ payload: { setCustomerState } }) {
  const res = yield call(Orgs.orgsState);
  if (res.status === 200) {
    setCustomerState(res.data.customer_count);
  }
}

export default function* watchOrgsActions() {
  yield takeLatest(getAllOrgsRequest.toString(), getAllOrgsSaga);
  yield takeLatest(addNewOrgRequest.toString(), addNewOrgsSaga);
  yield takeLatest(updateOrgRequest.toString(), updateOrgsSaga);
  yield takeLatest(addNewOrgAdminRequest.toString(), addNewOrgAdminSaga);
  yield takeLatest(stateOrgRequest.toString(), stateOrgSaga);
}
