import React from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Row, Col } from 'antd';
import { FormattedMessage } from 'react-intl';

import grasLogo from 'assets/images/gras-logo.svg';
import { Wrapper } from 'components/uielements';
import UserAuthWrapper from './UserAuth.styles';
import UserLoginForm from './UserLoginForm';

export default function UserLogin() {
  const location = useLocation();
  const isLoggedIn = useSelector(state => state.Auth.idToken);

  const [redirectToReferrer, setRedirectToReferrer] = React.useState(false);
  React.useEffect(() => {
    if (isLoggedIn) {
      setRedirectToReferrer(true);
    }
  }, [isLoggedIn]);

  const { from } = location.state || { from: { pathname: '/dashboard' } };

  if (redirectToReferrer) {
    return <Redirect to={from} />;
  }

  return (
    <UserAuthWrapper>
      <Wrapper width="74rem">
        <Row className="mt-2" type="flex" justify="center" align="middle">
          <Col span={24}>
            <Row type="flex" justify="center" align="middle">
              <img src={grasLogo} alt="grasmiljio" />
            </Row>
          </Col>
          <Col span={24}>
            <h3 className="text-center font-bold mt-1 mb-1">
              <FormattedMessage id="Welcome back!" />
            </h3>
          </Col>
          <Col span={20} className="mt-1 mb-1">
            <UserLoginForm />
          </Col>
        </Row>
      </Wrapper>
    </UserAuthWrapper>
  );
}
