/* eslint-disable react/prop-types */
import React from 'react';
import { Form, InputNumber, Row, Col } from 'antd';
import PropTypes from 'prop-types';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { FormattedMessage } from 'react-intl';
import FootballField from './FootballField';
import InfillMeasureFieldsWrapper from './InfillMeasureFields.styles';

const GenerateField = ({ field, index }) => {
  return (
    <Form.Item name={`field_${index}`} hasFeedback>
      <InputNumber
        placeholder={field.placeholder}
        size="small"
        disabled
        style={{ width: '75px' }}
      />
    </Form.Item>
  );
};

const getMargin = index => {
  if (index === 0) {
    return '26px';
  }
  if (index === 1) {
    return '64px';
  }
  return '97px';
};

function InfillMeasureFields({
  fields,
  handleDelete,
  itemIndex,
  hasDelete,
  editFieldModal,
  width,
}) {
  const { measure } = fields;

  return (
    <InfillMeasureFieldsWrapper>
      <Row type="flex" align="center" gutter={[0]}>
        <Col span={22}>
          <h4>{fields.label}</h4>
          <p>
            <FormattedMessage id="S: Sand Infill height" />
            <br />
            <FormattedMessage id="S+R: Sand + Rubber Infill height" />
          </p>
        </Col>
        <Col className="field_action" span={2}>
          {hasDelete ? (
            <>
              <EditOutlined onClick={() => editFieldModal(itemIndex)} />
              <DeleteOutlined onClick={() => handleDelete(itemIndex)} />
            </>
          ) : null}
        </Col>
      </Row>
      <FootballField width={width} />
      <Row type="flex" align="center" gutter={[0]}>
        <Col>
          {measure?.keeper_1.map((item, index) => (
            <GenerateField field={item} key={index} index={index} />
          ))}
        </Col>
      </Row>
      <Row style={{ marginTop: '99px' }}>
        {measure?.defensive_1?.map((items, index) => (
          <Col span={8} key={index}>
            <Col span={24} style={{ marginLeft: getMargin(index) }}>
              {items?.map((item, i) => (
                <GenerateField field={item} key={i} index={i} />
              ))}
            </Col>
          </Col>
        ))}
      </Row>
      <Row style={{ marginTop: '180px' }}>
        {measure?.midfield_1?.map((items, index) => (
          <Col span={8} key={index}>
            <Col span={24} style={{ marginLeft: getMargin(index) }}>
              {items?.map((item, i) => (
                <GenerateField field={item} key={i} index={i} />
              ))}
            </Col>
          </Col>
        ))}
      </Row>
      <Row style={{ marginTop: '160px' }}>
        {measure?.midfield_2?.map((items, index) => (
          <Col span={8} key={index}>
            <Col span={24} style={{ marginLeft: getMargin(index) }}>
              {items?.map((item, i) => (
                <GenerateField field={item} key={i} index={i} />
              ))}
            </Col>
          </Col>
        ))}
      </Row>
      <Row style={{ marginTop: '171px' }}>
        {measure?.defensive_2?.map((items, index) => (
          <Col span={8} key={index}>
            <Col span={24} style={{ marginLeft: getMargin(index) }}>
              {items?.map((item, i) => (
                <GenerateField field={item} key={i} index={i} />
              ))}
            </Col>
          </Col>
        ))}
      </Row>
      <Row type="flex" align="center" style={{ marginTop: '105px' }}>
        <Col>
          {measure?.keeper_2?.map((item, index) => (
            <GenerateField field={item} key={index} index={index} />
          ))}
        </Col>
      </Row>
    </InfillMeasureFieldsWrapper>
  );
}
InfillMeasureFields.propTypes = {
  fields: PropTypes.objectOf(PropTypes.any),
  handleDelete: PropTypes.func,
  editFieldModal: PropTypes.func,
  itemIndex: PropTypes.number,
  hasDelete: PropTypes.bool,
  width: PropTypes.string,
};

export default InfillMeasureFields;
