import React, { useEffect } from 'react';
import { Form, Select } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { countryCodeRequest } from 'features/Users/reducer';

const { Option } = Select;

const SelectCountryCode = ({ name }) => {
  const dispatch = useDispatch();
  const countryCode = useSelector(state => state.Users.countryCode);

  useEffect(() => {
    dispatch(countryCodeRequest());
  }, [dispatch]);

  return (
    <Form.Item name={name} noStyle>
      <Select
        showSearch
        optionFilterProp="children"
        filterOption={(input, option) =>
          option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        style={{ width: 180 }}
        size="large"
      >
        {countryCode &&
          countryCode.map(item => (
            <Option key={item.id} value={item.code}>
              {`${item.country} +(${item.code})`}
            </Option>
          ))}
      </Select>
    </Form.Item>
  );
};

SelectCountryCode.propTypes = {
  name: PropTypes.string,
};

export default SelectCountryCode;
