import config from 'config';

export const apiUrl = `${config.BACKEND_ROOT_URL}/api/v1`;

export const usersUrl = `${apiUrl}/users/`;

export const loginUrl = `${usersUrl}login/`;

export const userRegUrl = `${usersUrl}register/`;

export const userRolesUrl = `${usersUrl}roles/`;

export const userActivateUrl = `${usersUrl}activate-user/`;

export const resetPasswordUrl = `${usersUrl}reset-password/`;

export const currentUserUrl = `${usersUrl}detail/`;

export const changePasswordUrl = `${usersUrl}change-password/`;

export const forgotPasswordUrl = `${usersUrl}forget-password/`;

export const orgsUrl = `${apiUrl}/organizations/`;

export const projectsUrl = `${apiUrl}/projects/`;

export const fileUrl = `${apiUrl}/fetch-private-file/`;

export const deleteFileUrl = `${apiUrl}/attachment`;

export const esignUrl = `${apiUrl}/form-templates/`;

export const stepsUrl = `${apiUrl}/steps/`;

export const eSignFormUrl = `${apiUrl}/form-templates-for-step/`;

export const stepFormUrl = `${apiUrl}/step-forms/`;

export const fileUploadUrl = `${apiUrl}/files/`;

export const stepsCommentUrl = `${apiUrl}/step-comments/`;

export const calendarUrl = `${stepsUrl}calender/`;

export const channelUrl = `${apiUrl}/channels/`;

export const chatUrl = `${apiUrl}/chats/`;

export const countryCodeUrl = `${apiUrl}/countries/`;

export const fieldUrl = `${apiUrl}/locations/`;

export const maintenanceUrl = `${apiUrl}/fields/maintenance-schedules/`;

export const alarmsUrl = `${apiUrl}/fields/alarms/`;

export const alarmsObjectsUrl = `${apiUrl}/fields/objects/`;

export const orgsUserUrl = `${usersUrl}organization/`;

export const todoUrl = `${apiUrl}/todo/`;

export const userSearchUrl = `${apiUrl}/user-search/`;

export const notificationUrl = `${apiUrl}/notifications/`;
