import { createSlice, createAction } from '@reduxjs/toolkit';

const initState = {
  list: [],
};

const EsignSlice = createSlice({
  name: 'eSign',
  initialState: initState,
  reducers: {
    getAllEsign(state, { payload }) {
      state.list = payload;
    },
  },
});

export const getAllEsignRequest = createAction('eSign/get_all_esign_request');
export const getEsignViewRequest = createAction('eSign/get_esign_view_request');
export const addNewEsignRequest = createAction('eSign/add_new_esign_request');
export const updateEsignRequest = createAction('eSign/update_esign_request');
export const deleteEsignRequest = createAction('eSign/delete_esign_request');

export const { getAllEsign } = EsignSlice.actions;

export default EsignSlice;
