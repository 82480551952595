import React, { useState, useEffect } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { List, Avatar, Form, Input, Button } from 'antd';
import getValidateMessages from 'lib/helpers/validators';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { MessageViewWrapper } from './Message.styles';
import {
  sendChatRequest,
  channelReadAllRequest,
  getUnreadMsgCountRequest,
} from './reducer';

const { TextArea } = Input;

function MessageView({
  chatList,
  selectedUserId,
  channelList,
  unreadCount,
  firstChannel,
  setAllChannels,
  handleScroll,
  scrollRef,
  setAllChats,
}) {
  const intl = useIntl();
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [lastItem, setLastItem] = useState(null);
  const currentUser = useSelector(state => state.CurrentUser.info);

  const selectedUser = channelList.find(e => e.userId === selectedUserId);
  function chatUser(item) {
    if (currentUser.id !== item.sender) return 'sender';
    if (currentUser.id !== item.receiver) return 'receiver';
    return '';
  }
  const messageList =
    chatList &&
    chatList.map((item, i) => {
      return {
        index: i + 1,
        name: `${`${item[`sender_profile`].first_name} `}${
          item[`sender_profile`].last_name
        }`,
        role: chatUser(item) === 'sender' ? 'me' : 'others',
        comment_txt: item.content,
        image:
          'https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png',
        ...item,
      };
    });

  const messagesEnd = () => {
    lastItem.scrollIntoView({ behavior: 'smooth' });
  };

  const onFinishMessage = values => {
    const data = {
      ...values,
      sender: currentUser.id,
      receiver: selectedUserId,
    };
    const formData = new FormData();
    Object.keys(data).forEach(key => formData.append(key, data[key]));

    dispatch(
      sendChatRequest({
        formData,
        resetForm: form.resetFields,
        setAllChats,
      }),
    );
    messagesEnd();
  };

  useEffect(() => {
    if (lastItem) {
      messagesEnd();
    }
  }, [lastItem]);
  const onPress = () => {
    if (unreadCount > 0) {
      dispatch(getUnreadMsgCountRequest());
      const data = {
        channel: firstChannel,
      };
      const formData = new FormData();
      Object.keys(data).forEach(key => formData.append(key, data[key]));
      dispatch(channelReadAllRequest({ formData, setAllChannels }));
    }
  };
  return (
    <MessageViewWrapper className="message_view">
      <div className="message_view_top">
        <List.Item>
          <List.Item.Meta
            avatar={<Avatar src={selectedUser && selectedUser.image} />}
            title={selectedUser && selectedUser.name}
            description={selectedUser && selectedUser.org_name}
          />
          <div className="role">{selectedUser && selectedUser.role}</div>
        </List.Item>
      </div>

      <div className="message_details">
        <div
          className="message_box"
          ref={scrollRef}
          onScroll={handleScroll}
          style={{
            overflowX: 'hidden',
            overflowY: 'scroll',
          }}
        >
          <List
            dataSource={messageList && messageList.reverse()}
            renderItem={item => (
              <List.Item
                className={item.role === 'me' && 'reverse'}
                key={item.id}
              >
                <List.Item.Meta
                  avatar={<Avatar src={item.image} />}
                  title={item.name}
                  description={item.comment_txt}
                />
                {item.index === 1 && <div ref={el => setLastItem(el)} />}
              </List.Item>
            )}
          />
        </div>
        <div className="message_compose">
          <Form
            name="message_composer"
            onFinish={onFinishMessage}
            labelCol={{ span: 24 }}
            form={form}
            validateMessages={getValidateMessages(intl.messages)}
          >
            <Form.Item name="content">
              <TextArea
                placeholder={intl.messages['Click here to Reply']}
                rows={4}
                onFocus={onPress}
              />
            </Form.Item>

            <Button size="small" type="primary" htmlType="submit">
              <FormattedMessage id="Send" />
            </Button>
          </Form>
        </div>
      </div>
    </MessageViewWrapper>
  );
}
MessageView.propTypes = {
  chatList: PropTypes.arrayOf(PropTypes.any),
  selectedUserId: PropTypes.string,
  channelList: PropTypes.arrayOf(PropTypes.any),
  unreadCount: PropTypes.number,
  firstChannel: PropTypes.string,
  setAllChannels: PropTypes.func,
  handleScroll: PropTypes.func,
  scrollRef: PropTypes.objectOf(PropTypes.any),
  setAllChats: PropTypes.func,
};
export default MessageView;
