import React from 'react';
import { FormattedMessage } from 'react-intl';
import { UserModal } from 'features/Users';
import PropTypes from 'prop-types';
import FieldForm from './FieldForm';

function NewField({
  isModalVisible,
  setIsModalVisible,
  action,
  fieldInfo,
  offset,
  pagination_off = true,
}) {
  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      <UserModal
        centered
        title={
          action === 'create' ? (
            <FormattedMessage id="Add New Field" />
          ) : (
            <FormattedMessage id="Update Field" />
          )
        }
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={null}
        width="91rem"
      >
        <FieldForm
          handleModalClose={handleCancel}
          action={action}
          fieldInfo={fieldInfo}
          offset={offset}
          pagination_off={pagination_off}
        />
      </UserModal>
    </>
  );
}
NewField.propTypes = {
  isModalVisible: PropTypes.bool,
  setIsModalVisible: PropTypes.func,
  action: PropTypes.string,
  fieldInfo: PropTypes.objectOf,
  offset: PropTypes.number,
  pagination_off: PropTypes.bool,
};

export default NewField;
