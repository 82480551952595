/* eslint-disable react/prop-types */
import React from 'react';
import {
  GoogleMap,
  withScriptjs,
  withGoogleMap,
  Marker,
} from 'react-google-maps';

function Map({ point, closeMarker, setMapPoint }) {
  if (!closeMarker)
    return (
      <GoogleMap
        onClick={e => {
          setMapPoint({ latitude: e.latLng.lat(), longitude: e.latLng.lng() });
        }}
        defaultZoom={16}
        defaultCenter={{ lat: point.latitude, lng: point.longitude }}
      >
        {/* {address ? ( */}
        <Marker
          draggable
          position={{ lat: point.latitude, lng: point.longitude }}
        />
        {/* ) : null} */}
      </GoogleMap>
    );
  return <div>...</div>;
}
const GoogleMapComponent = withScriptjs(withGoogleMap(Map));
export default GoogleMapComponent;
