import React, { useEffect } from 'react';
import { Form, Input, Button, Row, Col, Select } from 'antd';
import { useIntl, FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import getValidateMessages from 'lib/helpers/validators';
import { getAllUserRolesRequest } from 'features/Users';
import { updateUserRequest } from 'features/Users/reducer';

const { Option } = Select;

function UpdateUserForm({ handleModalClose, extraFormData, query, userInfo }) {
  const roles = useSelector(state => state.Users.roles);
  const currentUserFromStore = useSelector(state => state.CurrentUser.info);
  const currentOrgId = currentUserFromStore.organization
    ? currentUserFromStore.organization.id
    : '';

  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const intl = useIntl();

  useEffect(() => {
    dispatch(getAllUserRolesRequest({ query }));
  }, [dispatch, query]);

  useEffect(() => {
    const { first_name, last_name, email, phone, group } = userInfo;
    form.setFieldsValue({
      first_name,
      last_name,
      email,
      phone,
      group,
    });
  }, [userInfo]);

  const onFinish = values => {
    if (currentOrgId) {
      values['organization'] = currentOrgId;
    }
    delete values['email'];
    dispatch(
      updateUserRequest({
        formData: { ...values, ...extraFormData },
        id: userInfo.id,
        resetForm: form.resetFields,
        succMessage: intl.messages['User Successfully Updates!'],
        handleModalClose,
        query,
      }),
    );
  };
  const handleModalCloseFunc = () => {
    handleModalClose();
    form.resetFields();
  };

  return (
    <Form
      name="add_new_user"
      onFinish={onFinish}
      labelCol={{ span: 24 }}
      form={form}
      validateMessages={getValidateMessages(intl.messages)}
    >
      <Row gutter={50}>
        <Col span={12}>
          <Form.Item
            label={intl.messages['First name']}
            name="first_name"
            rules={[{ required: true }]}
            hasFeedback
          >
            <Input placeholder={intl.messages['First Name']} size="large" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label={intl.messages['Last name']}
            name="last_name"
            rules={[{ required: true }]}
            hasFeedback
          >
            <Input placeholder={intl.messages['Last Name']} size="large" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label={intl.messages['Email']}
            name="email"
            rules={[
              {
                required: true,
                type: 'email',
              },
            ]}
          >
            <Input placeholder={intl.messages['Email']} size="large" disabled />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="group"
            label={intl.messages['Role']}
            rules={[{ required: true }]}
            hasFeedback
          >
            <Select disabled size="large" style={{ width: '100%' }}>
              {roles.map(role => (
                <Option key={role.id} value={role.name}>
                  <FormattedMessage id={role.name} />
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label={intl.messages['Phone']} name="phone">
            <Input
              //  type="number"
              placeholder={intl.messages['Phone']}
              size="large"
            />
          </Form.Item>
        </Col>
      </Row>

      <Row className="mt-2" type="flex" justify="center" gutter="70">
        <Col>
          <Form.Item>
            <Button onClick={handleModalCloseFunc} size="large">
              <FormattedMessage id="Cancel" />
            </Button>
          </Form.Item>
        </Col>
        <Form.Item>
          <Button size="large" type="primary" htmlType="submit">
            <FormattedMessage id="Done" />
          </Button>
        </Form.Item>
        <Col />
      </Row>
    </Form>
  );
}

UpdateUserForm.propTypes = {
  handleModalClose: PropTypes.func,
  extraFormData: PropTypes.shape({
    id: PropTypes.number,
  }),
  query: PropTypes.string,
  userInfo: PropTypes.objectOf,
};

export default UpdateUserForm;
