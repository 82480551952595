import { createSlice, createAction } from '@reduxjs/toolkit';

const initState = { OrgUserList: [], orgDetails: {} };

const OrgDetailsSlice = createSlice({
  name: 'orgDetails',
  initialState: initState,
  reducers: {
    toggleUserModal() {},
    toggleEditUserModal() {},
    getAllOrgsUser(state, { payload }) {
      state.OrgUserList = payload;
    },
    getSingleOrganization(state, { payload }) {
      state.orgDetails = payload;
    },
  },
});

export const getSingleOrganizationRequest = createAction(
  'projects/get_single_organization_request',
);

export const getAllOrgsUserRequest = createAction(
  'orgs/get_all_org_user_request',
);

export const {
  toggleUserModal,
  toggleEditUserModal,
  getAllOrgsUser,
  getSingleOrganization,
} = OrgDetailsSlice.actions;

export default OrgDetailsSlice;
