import { takeLatest, put, call } from 'redux-saga/effects';
import { Fields } from 'api';
import { message } from 'antd';
import {
  getAllFieldsRequest,
  getAllFields,
  addNewFieldRequest,
  updateFieldRequest,
  updateFields,
  getFieldDetails,
  getFieldDetailsRequest,
  createMaintenanceRequest,
  getMaintenanceByLocationRequest,
  getAllMaintenance,
  updateMaintenanceRequest,
  getAlarmsObjectsRequest,
  getAllAlarmsObjects,
  createAlarmsRequest,
  getAllAlarms,
  getAlarmsByLocationRequest,
  updateAlarmsRequest,
  checkSaimosServerReq,
  getSaimosServer,
  deletefieldsFileRequest,
} from './reducer';

export function* getAllFieldsSaga({
  payload: {
    limit = '',
    offset = '',
    name = '',
    organization = '',
    pagination_off = false,
  },
}) {
  const res = yield call(
    Fields.index,
    limit,
    offset,
    name,
    organization,
    pagination_off,
  );
  if (res.status === 200) {
    yield put(getAllFields(res.data));
  }
}

export function* addNewFieldSaga({
  payload: { data, resetForm, succMessage, handleModalClose, pagination_off },
}) {
  const res = yield call(Fields.create, data);
  if (res.data) {
    message.success(succMessage);
    resetForm();
    handleModalClose();
    yield call(getAllFieldsSaga, {
      payload: { limit: 10, offset: 0, pagination_off },
    });
  }
}
export function* updateFieldSaga({
  payload: { data, id, succMessage, resetForm, handleModalClose, offset },
}) {
  const res = yield call(Fields.update, id, data);
  if (res?.data) {
    message.success(succMessage);
    resetForm();
    handleModalClose();
    yield put(updateFields(res.data));
    yield call(getAllFieldsSaga, {
      payload: { limit: 10, offset },
    });
  }
}
export function* getFieldDetailsSaga({ payload: { id } }) {
  const res = yield call(Fields.getDetailsById, id);
  if (res?.data) {
    yield put(getFieldDetails(res.data));
  }
}

export function* getMaintenanceByLocationSaga({
  payload: { limit, offset, location },
}) {
  const res = yield call(Fields.getMaintenance, limit, offset, location);
  if (res.status === 200) {
    yield put(getAllMaintenance(res.data));
  }
}

export function* createMaintenanceSaga({
  payload: { data, resetForm, succMessage, handleModalClose },
}) {
  const res = yield call(Fields.createMaintenance, data);
  if (res.data) {
    message.success(succMessage);
    resetForm();
    handleModalClose();
    yield call(getMaintenanceByLocationSaga, {
      payload: { limit: 5, offset: 0, location: data?.location },
    });
  }
}

export function* updateMaintenanceSaga({
  payload: {
    data,
    id,
    succMessage,
    resetForm,
    handleModalClose,
    tableCurrentPage,
  },
}) {
  const res = yield call(Fields.updateMaintenance, id, data);
  if (res?.data) {
    message.success(succMessage);
    resetForm();
    handleModalClose();
    yield call(getMaintenanceByLocationSaga, {
      payload: {
        limit: 5,
        offset: 5 * (tableCurrentPage - 1),
        location: data?.location,
      },
    });
  }
}

export function* getAlarmsObjectsSaga() {
  const res = yield call(Fields.getAlarmsObjects);
  if (res.status === 200) {
    yield put(getAllAlarmsObjects(res.data));
  }
}

export function* getAlarmsByLocationSaga({
  payload: { limit, offset, location },
}) {
  const res = yield call(Fields.getAlarms, limit, offset, location);
  if (res.status === 200) {
    yield put(getAllAlarms(res.data));
  }
}

export function* createAlarmsSaga({
  payload: { data, resetForm, succMessage, handleModalClose },
}) {
  const res = yield call(Fields.createAlarms, data);
  if (res.data) {
    message.success(succMessage);
    resetForm();
    handleModalClose();
    yield call(getAlarmsByLocationSaga, {
      payload: { limit: 5, offset: 0, location: data?.location },
    });
  }
}

export function* updateAlarmsSaga({
  payload: {
    data,
    id,
    succMessage,
    resetForm,
    handleModalClose,
    tableCurrentPage,
  },
}) {
  const res = yield call(Fields.updateAlarms, id, data);
  if (res?.data) {
    message.success(succMessage);
    resetForm();
    handleModalClose();
    yield call(getAlarmsByLocationSaga, {
      payload: {
        limit: 5,
        offset: 5 * (tableCurrentPage - 1),
        location: data?.location,
      },
    });
  }
}

export function* checkSaimosServerSaga({
  payload: { id, succMessage, failedMessage },
}) {
  yield put(getSaimosServer({ [id]: true }));
  const res = yield call(Fields.checkSaimosServer, id);
  if (res?.data) {
    if (res.data.saimos_server_status === true) {
      message.success(succMessage);
    } else {
      message.error(failedMessage);
    }
    yield put(getSaimosServer({}));
  }
}

export function* deletefieldsFileSaga({
  payload: { location_pk, file_pk, succMessage },
}) {
  const res = yield call(Fields.fileDelete, location_pk, file_pk);
  if (res?.status === 204) {
    message.success(succMessage);
    yield call(getFieldDetailsSaga, {
      payload: { id: location_pk },
    });
  }
}

export default function* watchFieldsActions() {
  yield takeLatest(getAllFieldsRequest.toString(), getAllFieldsSaga);
  yield takeLatest(addNewFieldRequest.toString(), addNewFieldSaga);
  yield takeLatest(updateFieldRequest.toString(), updateFieldSaga);
  yield takeLatest(getFieldDetailsRequest.toString(), getFieldDetailsSaga);
  yield takeLatest(createMaintenanceRequest.toString(), createMaintenanceSaga);
  yield takeLatest(
    getMaintenanceByLocationRequest.toString(),
    getMaintenanceByLocationSaga,
  );
  yield takeLatest(updateMaintenanceRequest.toString(), updateMaintenanceSaga);
  yield takeLatest(getAlarmsObjectsRequest.toString(), getAlarmsObjectsSaga);
  yield takeLatest(createAlarmsRequest.toString(), createAlarmsSaga);
  yield takeLatest(
    getAlarmsByLocationRequest.toString(),
    getAlarmsByLocationSaga,
  );
  yield takeLatest(updateAlarmsRequest.toString(), updateAlarmsSaga);
  yield takeLatest(checkSaimosServerReq.toString(), checkSaimosServerSaga);
  yield takeLatest(deletefieldsFileRequest.toString(), deletefieldsFileSaga);
}
