import React, { useEffect, useState } from 'react';
import { Form, Input, Button, Row, Col, Select, Upload } from 'antd';
import { useIntl, FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { UploadOutlined } from '@ant-design/icons';
import getValidateMessages from 'lib/helpers/validators';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';
import GoogleMapComponent from 'features/GoogleMap';
import { google_key } from 'lib/helpers/utility';
import { getAllOrgsRequest } from '../../Organizations/reducer';
import { addNewFieldRequest, updateFieldRequest } from '../reducer';
import { UploadFormWrapper } from './styles';

const { Option } = Select;
const { Dragger } = Upload;
// const { TextArea } = Input;

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}

const normFile = async ({ fileList }) => {
  const files = await Promise.all(
    fileList.map(async element => {
      let imageFile = '';
      imageFile = await getBase64(element.originFileObj);
      return { name: element.name, file: imageFile };
    }),
  );
  return files;
};

function FieldForm({
  handleModalClose,
  action,
  fieldInfo,
  offset,
  pagination_off,
}) {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const organizationList = useSelector(state => state.Orgs.organizations);
  const [address, setAddress] = useState('');
  const [closeMarker, setCloseMarker] = useState(false);
  const [point, setMapPoint] = useState({
    latitude: 59.32932349999999,
    longitude: 18.0685808,
  });
  const [loadMap, setLoadMap] = useState(true);
  const [prevFiles, setFiles] = useState([]);
  const intl = useIntl();

  useEffect(() => {
    dispatch(getAllOrgsRequest());
  }, [dispatch]);

  useEffect(() => {
    if (action === 'update') {
      setLoadMap(true);
      const {
        name,
        description,
        organization,
        coordinate_x1,
        coordinate_y1,
        coordinate_x2,
        coordinate_y2,
        coordinate_x3,
        coordinate_y3,
        coordinate_x4,
        coordinate_y4,
        saimos_database_host,
        saimos_database_name,
        saimos_database_password,
        saimos_database_port,
        saimos_database_user,
        attachments,
      } = fieldInfo;
      form.setFieldsValue({
        name,
        description,
        organization,
        location: fieldInfo?.address,
        coordinate_x1,
        coordinate_y1,
        coordinate_x2,
        coordinate_y2,
        coordinate_x3,
        coordinate_y3,
        coordinate_x4,
        coordinate_y4,
        saimos_database_host,
        saimos_database_name,
        saimos_database_password,
        saimos_database_port,
        saimos_database_user,
      });
      setMapPoint(fieldInfo.point);
      setAddress(fieldInfo.address);
      setFiles(attachments);
    }
  }, [fieldInfo]);

  const onFinish = async values => {
    const { attachments } = values;
    let files = [];
    if (attachments) {
      await Promise.resolve(
        attachments.then(value => {
          files = value;
        }),
      );
    }
    const data = {
      ...values,
      address,
      point,
      attachments: [...files, ...prevFiles],
    };

    if (action === 'create') {
      dispatch(
        addNewFieldRequest({
          data,
          resetForm: form.resetFields,
          succMessage: intl.messages['Field Successfully Created!'],
          handleModalClose,
          pagination_off,
        }),
      );
    }
    if (action === 'update') {
      dispatch(
        updateFieldRequest({
          data,
          id: fieldInfo.id,
          succMessage: intl.messages['Updated successfully!'],
          resetForm: form.resetFields,
          handleModalClose,
          offset,
        }),
      );
    }
  };

  const handleSelect = async value => {
    setLoadMap(true);
    const results = await geocodeByAddress(value);
    const latLang = await getLatLng(results[0]);
    setCloseMarker(true);
    setAddress(value);
    setMapPoint({
      latitude: latLang.lat,
      longitude: latLang.lng,
    });
    setCloseMarker(false);
  };

  return (
    <Form
      name="add_new_user"
      onFinish={onFinish}
      labelCol={{ span: 24 }}
      form={form}
      validateMessages={getValidateMessages(intl.messages)}
    >
      <Row gutter={50}>
        <Col span={12}>
          <Form.Item
            label={intl.messages['Name']}
            name="name"
            rules={[{ required: true }]}
            hasFeedback
          >
            <Input placeholder={intl.messages['Name']} size="large" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label={intl.messages['Location']}
            rules={[{ required: true }]}
            name="location"
          >
            <PlacesAutocomplete
              value={address}
              onChange={setAddress}
              onSelect={handleSelect}
            >
              {({
                getInputProps,
                suggestions,
                getSuggestionItemProps,
                loading,
              }) => (
                <div>
                  <Input
                    size="large"
                    {...getInputProps({
                      placeholder:
                        intl.messages[
                          'Search Location, longitude and latitude ...'
                        ],
                      className: 'location-search-input',
                    })}
                  />

                  <div className="autocomplete-dropdown-container">
                    {loading && <div>Loading...</div>}
                    {suggestions.map(suggestion => {
                      const style = suggestion.active
                        ? {
                            backgroundColor: '#fafafa',
                            cursor: 'pointer',
                          }
                        : {
                            backgroundColor: '#ffffff',
                            cursor: 'pointer',
                          };
                      return (
                        <div
                          {...getSuggestionItemProps(suggestion, {
                            style,
                          })}
                        >
                          <span>{suggestion.description}</span>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
            </PlacesAutocomplete>
          </Form.Item>
        </Col>

        <Col span={24}>
          <Form.Item>
            <div style={{ width: '100%', height: '35vh' }}>
              {loadMap && (
                <GoogleMapComponent
                  setMapPoint={setMapPoint}
                  point={point}
                  googleMapURL={`https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=${google_key}`}
                  loadingElement={<div style={{ height: '100%' }} />}
                  containerElement={<div style={{ height: '100%' }} />}
                  mapElement={<div style={{ height: '100%' }} />}
                  closeMarker={closeMarker}
                  address={address}
                />
              )}
            </div>
          </Form.Item>
        </Col>

        {/* <Col span={12}>
          <Form.Item
            label={intl.messages['Description']}
            name="description"
            hasFeedback
          >
            <TextArea
              placeholder={intl.messages['Description']}
              size="large"
              rows={4}
            />
          </Form.Item>
        </Col> */}
        <Col span={12}>
          <Form.Item
            name="organization"
            label={intl.messages['Organizations']}
            rules={[{ required: true }]}
            hasFeedback
          >
            <Select
              placeholder={intl.messages['Organizations']}
              size="large"
              style={{ width: '100%' }}
            >
              {organizationList.map(org => (
                <Option key={org.id} value={org.id}>
                  {org.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label={intl.messages['Description']} name="description">
            <Input.TextArea placeholder={intl.messages['Description']} />
          </Form.Item>
        </Col>
        <Col span={24}>
          <UploadFormWrapper>
            <Form.Item
              getValueFromEvent={normFile}
              valuePropName="filelist"
              label={intl.messages['Add a document']}
              name="attachments"
              rules={[
                {
                  required: false,
                },
              ]}
              hasFeedback
            >
              <Dragger
                // eslint-disable-next-line no-unused-vars
                customRequest={({ _, onSuccess }) => {
                  setTimeout(() => {
                    onSuccess('ok');
                  }, 0);
                }}
                multiple
              >
                <p className="ant-upload-drag-icon">
                  <UploadOutlined />
                </p>
                <p className="ant-upload-text">
                  <FormattedMessage id="Drag files here to upload" />
                </p>
              </Dragger>
            </Form.Item>
          </UploadFormWrapper>
        </Col>
      </Row>
      <Row gutter={10}>
        <Col span={6}>
          <Form.Item
            name="coordinate_x1"
            label={intl.messages['Coordinate X1']}
          >
            <Input
              placeholder={intl.messages['Coordinate X1']}
              size="large"
              style={{ width: '100%' }}
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item
            name="coordinate_y1"
            label={intl.messages['Coordinate Y1']}
          >
            <Input
              placeholder={intl.messages['Coordinate Y1']}
              size="large"
              style={{ width: '100%' }}
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item
            name="coordinate_x2"
            label={intl.messages['Coordinate X2']}
          >
            <Input
              placeholder={intl.messages['Coordinate X2']}
              size="large"
              style={{ width: '100%' }}
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item
            name="coordinate_y2"
            label={intl.messages['Coordinate Y2']}
          >
            <Input
              placeholder={intl.messages['Coordinate Y2']}
              size="large"
              style={{ width: '100%' }}
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item
            name="coordinate_x3"
            label={intl.messages['Coordinate X3']}
          >
            <Input
              placeholder={intl.messages['Coordinate X3']}
              size="large"
              style={{ width: '100%' }}
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item
            name="coordinate_y3"
            label={intl.messages['Coordinate Y3']}
          >
            <Input
              placeholder={intl.messages['Coordinate Y3']}
              size="large"
              style={{ width: '100%' }}
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item
            name="coordinate_x4"
            label={intl.messages['Coordinate X4']}
          >
            <Input
              placeholder={intl.messages['Coordinate X4']}
              size="large"
              style={{ width: '100%' }}
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item
            name="coordinate_y4"
            label={intl.messages['Coordinate Y4']}
          >
            <Input
              placeholder={intl.messages['Coordinate Y4']}
              size="large"
              style={{ width: '100%' }}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={10}>
        <Col span={6}>
          <Form.Item
            name="saimos_database_name"
            label={intl.messages['Saimos Database Name']}
          >
            <Input
              placeholder={intl.messages['Saimos Database Name']}
              size="large"
              style={{ width: '100%' }}
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item
            name="saimos_database_user"
            label={intl.messages['Saimos Database User']}
          >
            <Input
              placeholder={intl.messages['Saimos Database User']}
              size="large"
              style={{ width: '100%' }}
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item
            name="saimos_database_password"
            label={intl.messages['Saimos Database Password']}
          >
            <Input
              placeholder={intl.messages['Saimos Database Password']}
              size="large"
              style={{ width: '100%' }}
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item
            name="saimos_database_host"
            label={intl.messages['Saimos Database Host']}
          >
            <Input
              placeholder={intl.messages['Saimos Database Host']}
              size="large"
              style={{ width: '100%' }}
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item
            name="saimos_database_port"
            label={intl.messages['Saimos Database Port']}
          >
            <Input
              placeholder={intl.messages['Saimos Database Port']}
              size="large"
              style={{ width: '100%' }}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row className="mt-2" type="flex" justify="center" gutter="70">
        <Col>
          <Form.Item>
            <Button onClick={handleModalClose} size="large">
              <FormattedMessage id="Cancel" />
            </Button>
          </Form.Item>
        </Col>
        <Form.Item>
          <Button size="large" type="primary" htmlType="submit">
            <FormattedMessage id={action === 'create' ? 'Save' : 'Update'} />
          </Button>
        </Form.Item>
        <Col />
      </Row>
    </Form>
  );
}

FieldForm.propTypes = {
  handleModalClose: PropTypes.func,
  fieldInfo: PropTypes.objectOf,
  action: PropTypes.string,
  extraFormData: PropTypes.shape({
    id: PropTypes.number,
  }),
  offset: PropTypes.number,
  pagination_off: PropTypes.bool,
};

export default FieldForm;
