import React, { useState } from 'react';
import { AutoComplete } from 'antd';
import { useIntl, FormattedMessage } from 'react-intl';
import { UserModal } from 'features/Users';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { getChatUserListRequest, createChannelRequest } from './reducer';

function NewMessageModal({
  isModalVisible,
  setIsModalVisible,
  setAllChannels,
  setUserID,
  setAllChats,
}) {
  const intl = useIntl();
  const [options, setOptions] = useState([]);
  const dispatch = useDispatch();
  const onSearch = searchText => {
    dispatch(
      getChatUserListRequest({
        searchString: searchText,
        setOptions,
      }),
    );
  };
  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const onSelect = (selectText, option) => {
    setUserID(option.id);
    const data = {
      receiver: option.id,
    };
    const formData = new FormData();
    Object.keys(data).forEach(key => formData.append(key, data[key]));
    dispatch(
      createChannelRequest({
        data: formData,
        setAllChannels,
        setAllChats,
      }),
    );
    setIsModalVisible(false);
  };
  return (
    <UserModal
      centered
      title={<FormattedMessage id="New Message" />}
      visible={isModalVisible}
      onCancel={handleCancel}
      footer={null}
      width="61rem"
    >
      <AutoComplete
        options={options}
        style={{ width: '100%' }}
        onSelect={onSelect}
        onSearch={onSearch}
        placeholder={intl.messages['Search user']}
        allowClear
      />
    </UserModal>
  );
}

NewMessageModal.propTypes = {
  isModalVisible: PropTypes.bool,
  setIsModalVisible: PropTypes.func,
  setAllChannels: PropTypes.func,
  setUserID: PropTypes.func,
  setAllChats: PropTypes.func,
};

export default NewMessageModal;
